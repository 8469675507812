import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';


@Injectable({
  providedIn: 'root'
})
export class UsuarioLocalService {

  constructor() { }

  setUser(usuario: Usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  getLocalUser(): Promise<Usuario | null> {
    const usuarioJSON = localStorage.getItem('usuario');
    if (usuarioJSON) {
      const usuarioObj = JSON.parse(usuarioJSON) as Usuario;
      return Promise.resolve(usuarioObj as Usuario);

    } else {
      return Promise.resolve(null);
    }
  }

  eliminarUsuarioLocal(): Promise<boolean> {
    localStorage.removeItem('usuario');
    return Promise.resolve(true)

  }

  async updateUser(usuario: Usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario))
  }

}
