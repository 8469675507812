import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Municipio } from '../models/municipio';
import { MUNICIPIOS } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MunicipioService {

  constructor(private httpClient: HttpClient) { }

  async getMunicipio(codigoMunicipio?: number): Promise<Municipio[]> {
    try {
      const url = codigoMunicipio
        ? MUNICIPIOS.URL + codigoMunicipio
        : MUNICIPIOS.URL;

      const respuesta: Observable<{ data: Municipio | Municipio[] }> = this.httpClient.get<{ data: Municipio | Municipio[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      // Si la respuesta es un solo objeto, envuélvelo en un array
      const municipios: Municipio[] = Array.isArray(resultado.data) ? resultado.data : [resultado.data];
      return await this.eliminarMunicipiosNoInteresadasParaUsuario(municipios);
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  async eliminarMunicipiosNoInteresadasParaUsuario(municipios: Municipio[]): Promise<Municipio[]> {
    return municipios
      .filter(municipios => municipios.municipio !== 'Sede del gobierno')
  }
}
