<div class="foro" [ngClass]="{'foro-terminado': forumStatus === 'Impartida'}" id="{{forumTitle}}">
    <div class="foro-fila">
        <div class="foro-derecha">
            <div class="foro-derecha-terminado" *ngIf="forumStatus === 'Impartida'">
            </div>
            <h2>{{forumTitle}}</h2>
            <div class="forum-right-info" *ngIf="forumStatus !== 'Impartida'">
                <div class="foro-condiciones">
                    <div class="foro-condicion">
                        <h3>Modalidad</h3>
                        <p *ngIf="forumStatus !== 'Impartida'">{{forumModality}}</p>
                    </div>
                    <div class="foro-condicion-separador"></div>
                    <div class="foro-condicion">
                        <h3>Duración</h3>
                        <p *ngIf="forumStatus !== 'Impartida'">2 semanas</p>
                    </div>
                    <div class="foro-condicion-separador"></div>

                    <div class="foro-grupo">
                        <img src="../../../assets/images/icono_grupo.svg" alt="">
                        <p>Grupo de hasta 20 personas que formen parte de la administración pública.</p>
                    </div>
                </div>
            </div>

        </div>


        <div class="foro-centro">
            <div class="foro-centro-info" [ngClass]="{'finished': forumStatus === 'Impartida'}">
                <button *ngIf="forumStatus === 'Próximamente'" class="secondary-button"
                    [ngClass]="{'soon': forumStatus === 'Próximamente'}">{{forumStatus}}
                </button>
                <p *ngIf="forumStatus === 'Impartida'"><strong>Impartida el </strong>
                    {{forumDate}}</p>
                <p *ngIf="forumStatus !== 'Impartida'" style="color: var(--primary)"><strong>{{forumDate}}</strong>
                </p>
                <div class="forum-bottom-button" (click)="showMoreInfo()">
                    <p *ngIf="forumStatus !== 'Impartida'"><strong>Ver programa</strong><span><img
                                src="../../../assets/images/flecha_abajo.svg" alt=""></span></p>
                    <p *ngIf="forumStatus === 'Impartida'"><strong>Contenido impartido </strong><span><img
                                src="../../../assets/images/flecha_abajo.svg" alt=""></span></p>
                </div>

            </div>
        </div>
        <img src="../../../assets/images/foros/{{forumIMG}}" alt="">

    </div>


    <div class="forum-moreinfo">
        <div class="forum-moreinfo-description">
            <p class="moreinfo-subtitle">Descripción:</p>
            <p>{{forumDescription}}</p>
        </div>
        <div class="forum-moreinfo-program">
            <p class="moreinfo-subtitle">Programa:</p>
            <p>{{forumProgram}}</p>
        </div>
        <div class="forum-moreinfo-onlineTools">
            <p class="moreinfo-subtitle">Herramientas digitales:</p>
            <p>{{forumDigitalTools}}</p>
        </div>
        <div class="forum-moreinfo-target">
            <p class="moreinfo-subtitle">Dirigido a:</p>
            <p>{{forumTarget}}</p>
        </div>
        <div class="forum-moreinfo-speakers">
            <p class="moreinfo-subtitle">Ponentes:</p>
            <p>{{forumSpeaker}}</p>
        </div>
        <div class="forum-moreinfo-observations">
            <p class="moreinfo-subtitle">Observaciones:</p>
            <p>{{forumObservations}}</p>
        </div>
        <div class="moreinfo-buttons">
            <div class="moreinfo-buttons-download">

                <a class="downloadProgram"> <img src="../../../assets/images/pdf.svg" alt=""> Descargar
                    programa </a>
            </div>
            <div class="moreinfo-buttons-sign">
                <button class="primary-button" *ngIf="forumStatus !== 'Impartida'">Inscríbete aquí</button>
                <a class="closeShowMoreInfo" (click)="showMoreInfo()">Cerrar programa <img
                        src="../../../assets/images/flecha_arriba.svg" alt=""> </a>
            </div>
        </div>
    </div>
</div>