import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profesion } from '../models/profesion';
import { PROFESIONES } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfesionService {


  constructor(private httpClient: HttpClient) { }

  async getProfesion(codigoProfesion?: string): Promise<Profesion[]> {
    try {
      const url = codigoProfesion
        ? PROFESIONES.URL + codigoProfesion
        : PROFESIONES.URL;

      const respuesta: Observable<{ data: Profesion[] }> = this.httpClient.get<{ data: Profesion[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  getNombreProfesiones(profesiones: Profesion[]): string[] {
    return profesiones
      .filter(profesiones => profesiones.profesion !== 'Seleccione profesión')
      .map(profesiones => profesiones.profesion);
  }
}
