import { Injectable } from '@angular/core';
import { Isla } from '../models/isla';
import { ISLAS } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IslaService {

  constructor(private httpClient: HttpClient) { }


  async getIsla(codigoProvicia?: number): Promise<Isla[]> {
    try {
      const url = codigoProvicia
        ? ISLAS.URL_ISLA_CON_PROVINCIA + codigoProvicia
        : ISLAS.URL;

      const respuesta: Observable<{ data: Isla | Isla[] }> = this.httpClient.get<{ data: Isla | Isla[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      // Si la respuesta es un solo objeto, envuélvelo en un array
      const islas: Isla[] = Array.isArray(resultado.data) ? resultado.data : [resultado.data];
      console.log(islas)
      return islas
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  async eliminarIslasNoInteresadasParaUsuario(islas: Isla[]): Promise<Isla[]> {
    return islas
      .filter(islas => islas.isla !== 'Doble capitalidad')
  }
}
