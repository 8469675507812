import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AvailableDocumentsComponent } from './pages/available-documents/available-documents.component';
import { RegisterComponent } from './auth-login/register/register.component';
import { ProgramComponent } from './pages/program/program.component';
import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { RequestComponent } from './pages/request/request.component';
import { ContactComponent } from './pages/contact/contact.component';
import { EmailComponent } from './pages/correo/email/email.component';
import { AuthGuard } from './guards/auth.guard';
import { UserComponent } from './pages/user/user.component';
import { AotContactComponent } from './pages/aot-contact/aot-contact.component';
import { ForumsComponent } from './pages/forums/forums.component';
import { GrantsComponent } from './pages/grants/grants.component';
import { ValidarUsuarioComponent } from './pages/validar-usuario/validar-usuario.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { ConcienciacionCiudadanaComponent } from './pages/concienciacion-ciudadana/concienciacion-ciudadana.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NuevoEmailComponent } from './pages/correo/nuevo-email/nuevo-email.component';
import { ListaEmailsComponent } from './pages/correo/lista-emails/lista-emails.component';
import { CondicionesDeUsoComponent } from './pages/condiciones-de-uso/condiciones-de-uso.component';
import { PoliticaDeCookiesComponent } from './pages/politica-de-cookies/politica-de-cookies.component';
import { PoliticaDePrivacidadComponent } from './pages/politica-de-privacidad/politica-de-privacidad.component';
import { PoliticaDePrivacidadPublicaComponent } from './pages/politica-de-privacidad-publica/politica-de-privacidad-publica.component';
import { PoliticaDeCookiesPublicaComponent } from './pages/politica-de-cookies-publica/politica-de-cookies-publica.component';
import { CondicionesDeUsoPublicaComponent } from './pages/condiciones-de-uso-publica/condiciones-de-uso-publica.component';
import { CheckVersion } from './guards/checkversion.guard';
const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [CheckVersion] },
  { path: 'contacto', component: ContactComponent },
  { path: 'registro', component: RegisterComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'emails', component: ListaEmailsComponent, canActivate: [AuthGuard] },
  { path: 'email/:id', component: EmailComponent, canActivate: [AuthGuard] },
  { path: 'nuevo-email', component: NuevoEmailComponent, canActivate: [AuthGuard] },
  { path: 'presentacion', component: WelcomeComponent, canActivate: [AuthGuard] },
  { path: 'quienes-somos', component: WhoWeAreComponent, canActivate: [AuthGuard] },
  { path: 'programa', component: ProgramComponent, canActivate: [AuthGuard] },
  { path: 'documentos-disponibles', component: AvailableDocumentsComponent, canActivate: [AuthGuard] },
  { path: 'solicitud', component: RequestComponent, canActivate: [AuthGuard] },
  { path: 'usuario', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'contacto-aot', component: AotContactComponent, canActivate: [AuthGuard] },
  { path: 'foros', component: ForumsComponent, canActivate: [AuthGuard] },
  { path: 'subvenciones', component: GrantsComponent, canActivate: [AuthGuard] },
  { path: 'validar-usuario/:token', component: ValidarUsuarioComponent },
  { path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuard] },
  { path: 'concienciacion-ciudadana', component: ConcienciacionCiudadanaComponent, canActivate: [AuthGuard] },
  { path: 'condiciones-de-uso', component: CondicionesDeUsoComponent, canActivate: [AuthGuard] },
  { path: 'condiciones-de-uso-publica', component: CondicionesDeUsoPublicaComponent },
  { path: 'politica-de-cookies', component: PoliticaDeCookiesComponent, canActivate: [AuthGuard] },
  { path: 'politica-de-cookies-publica', component: PoliticaDeCookiesPublicaComponent },
  { path: 'politica-de-privacidad', component: PoliticaDePrivacidadComponent, canActivate: [AuthGuard] },
  { path: 'politica-de-privacidad-publica', component: PoliticaDePrivacidadPublicaComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
