import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComunicacionService {
  private resetSubject = new Subject<void>();

  reset$ = this.resetSubject.asObservable();

  enviarReset(): void {
    this.resetSubject.next();
  }
}
