<!--<app-banner-cookies></app-banner-cookies>-->

<div *ngIf="!isLoged">
  <app-header [isLoged]="isLoged" *ngIf="!isValidarUsuarioPage"></app-header>
  <div class="aot-router-outlet-public">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
<div class="aot-container" *ngIf="isLoged">
  <app-header [isLoged]="isLoged" [url]="currentHeader.url"></app-header>
  <div class="aot-main-content">
    <div class="aot-sidebar">
      <app-sidebar [url]="currentHeader.url"></app-sidebar>
    </div>
    <div class="aot-router-outlet" id="aot-router-outlet">
      <app-aot-content-title [title]="title" [background]="backgroundURL" [subtitle]="subtitle"
        [headerInfo]="currentHeader" [presentation]="isPresentationPage"></app-aot-content-title>

      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>