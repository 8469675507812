import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {

  private currentUrlSubject = new BehaviorSubject<string>('');

  constructor(private router: Router) {
    this.iniciarServicioDeNavegacion();
  }

  private iniciarServicioDeNavegacion(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd | any) => {
        const currentUrl = event.urlAfterRedirects.startsWith('/') ? event.urlAfterRedirects.substring(1) : event.urlAfterRedirects;
        this.currentUrlSubject.next(currentUrl);
      });
  }

  getUrlActual$(): Observable<string> {
    return this.currentUrlSubject.asObservable();

  }
}
