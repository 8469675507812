<div class="sidebar-filters">
    <div class="sidebar-filters-top">
        <div class="sidebar-filters-top-header">
            <p>{{title}}</p>
        </div>
        <div class="sidebar-filters-content">
            <app-select *ngIf="selectVisible" [text]=selectTitle [options]=selectValues></app-select>
            <app-select [text]=dateFromTitle [options]="['']"></app-select>
            <app-select [text]="''" [options]="['']"></app-select>
            <app-radio [radioValues]="['Pendientes de descarga']" *ngIf="checkboxVisible"></app-radio>

        </div>
    </div>
    <div class="sidebar-filters-bottom">
        <button class="secondary-button">Limpiar filtros</button>

        <button class="primary-button">Aplicar</button>
    </div>
</div>