import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.css']
})
export class StickyHeaderComponent {

  @Input() links: string[] = [];;


  scrollIntoView(target: string) {
    document.getElementById(target)!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    document.getElementById('who-menu')?.classList.add('top')


  }

}
