<div class="grants">
    <div class="default-menu" id="user-menu">
        <a class="default-menu-link grant-link selected" id="list-grant" (click)="selectedGrant($event.target!)">Lista
        </a>
        <a class="default-menu-link grant-link" id="calendar-grant"
            (click)="selectedGrant($event.target!)">Calendario</a>
    </div>
    <div class="grant-section current" id="list-grant-section">
        <div class="grant-section-filter">
            <div class="sidebar-filters">
                <div class="sidebar-filters-top">
                    <div class="sidebar-filters-top-header">
                        <p>Filtros</p>
                    </div>
                    <div class="sidebar-filters-content">
                        <app-input [type]="'Date'" [text]="'Desde'"></app-input>
                        <app-input [type]="'Date'" [text]="'Hasta'"></app-input>
                        <app-select [text]="'Ámbito'" [options]="['Ámbito 1', 'Ámbito 2', 'Ámbito 3']"></app-select>
                        <app-select [text]="'Órgano convocante'"
                            [options]="['Órgano 1', 'Órgano 2', 'Órgano 3']"></app-select>
                        <h3>Estado de la convocatoria</h3>
                        <app-checkbox [checkboxName]="'En plazo'" [checkboxValue]="'En plazo'"
                            [checkboxTextColor]="'available'"
                            (checkboxValueEmmiter)="filterGrants($event, 'Abierta')"></app-checkbox>
                        <app-checkbox [checkboxName]="'Fuera de plazo'" [checkboxValue]="'Fuera de plazo'"
                            [checkboxTextColor]="'finished'"
                            (checkboxValueEmmiter)="filterGrants($event, 'Finalizadas')"></app-checkbox>

                    </div>
                </div>
                <div class="sidebar-filters-bottom">
                    <button class="secondary-button">Limpiar filtros</button>

                    <button class="primary-button">Aplicar</button>
                </div>
            </div>
        </div>
        <div class="grant-section-list">
            <div class="grant-section-list-searchbar">
                <input type="search" placeholder="Buscar convocatoria">
            </div>
            <p>Convocatorias</p>
            <div class="grant-section-list-content">
                <div class="grant" id="grant-{{i+1}}" (click)="showHiddeGrantInfo($event.target!)"
                    *ngFor="let grant of filteredConvocatorias ; index as i">
                    <div class="grant-top" id="grant-{{i+1}}-top">
                        <p class="grant-top-date"><strong>{{i+1}}</strong></p>
                        <p class="grant-top-resume {{grant.status}}"><strong>{{grant.resume}}</strong></p>
                    </div>
                    <div class="grant-bottom hidde" id="grant-{{i+1}}-bottom">
                        <p><strong>Ámbito: {{grant.ambito}}</strong> </p>
                        <p><strong>Plazo de presentación: </strong> {{grant.plazo}}</p>
                        <p><strong>órgano convocante: {{grant}}</strong></p>
                        <p><strong>Tema, palabras relacionadas: {{grant.tema}}</strong> </p>
                        <p><strong>Obervaciones: {{grant.observaciones}}</strong></p>
                        <p class="grant-link"><img src="../../../assets/images/ojo.svg" alt=""><a class=""
                                href="{{grant.url}}" target="_blank">Ver convocatoria</a></p>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="grant-section" id="calendar-grant-section">
        <full-calendar [options]="calendarOptions" *ngIf="calendarVisible"></full-calendar>
    </div>
</div>