<div class="main-container">

    <section>
        <h2>INFORMACIÓN GENERAL</h2>
        <p>La presente plataforma de “Programa de Apoyo a las Oficinas Técnicas” (en adelante plataforma AOT) cuya
            titular
            es la
            mercantil Gestión y Planeamiento Territorial y Medioambiental S.A. (en adelante Gesplan) inscrita en el
            Registro
            Mercantil de Las Palmas Folio 172 Tomo 1231 Hoja GC 13426 Inscripción 2ª.con domicilio en Calle León y
            Castillo
            54
            bajo código postal 35003 Las Palmas de Gran Canaria con CIF A-38279972 (infor&#64;territoriocanario.org).
            representado
            por Manuel Agoney Piñero Ortiz (Consejero Delegado) con NIF 43829417L es titular de las siguientes
            direcciones
            URL:
            https://www.gesplan.es/ y https://www.territoriocanario.org/</p>
    </section>
    <section>
        <h2>OBJETO</h2>


        <p>Esta plataforma AOT tiene por objeto la resolución mediante informes de consultas de carácter técnico y/o
            jurídico
            relacionado con la casuística que se presentan de manera habitual en las Oficinas Técnicas de los distintos
            Ayuntamientos y Cabildos de la Comunidad Autónoma de Canarias. También la persona usuaria de la plataforma
            AOT
            podrá
            solicitar acciones formativas en materia urbanística a modo de ejemplo ofrecer asesoramiento para llevar a
            cabo
            procesos participativos entre otras.</p>
    </section>
    <section>
        <h2>CONDICIONES GENERALES</h2>
        <p>La persona usuaria de este sitio web deberá conocer y aceptar lo contenido en este Aviso Legal entendiéndose
            que
            el
            simple acceso así como el uso de esta plataforma supone la referida aceptación con carácter íntegro y sin
            ningún
            tipo de reservas del contenido descrito en este aviso legal. Se informa que este aviso legal tendrá una
            vigencia
            indefinida reservándonos el derecho a modificarlo cuando proceda. Del mismo modo Gesplan se reserva el
            derecho a
            modificar cambiar suprimir suspender y ampliar cualquier información alojada en la plataforma AOT sin
            necesidad
            de
            previo aviso.</p>
        <p>Está prohibido que los menores e incapaces contraten los servicios ofrecidos en este sitio web o faciliten
            sus
            datos
            personales sin la autorización de sus padres o representantes legales. La web AOT es totalmente gratuita y
            de
            acceso
            restringido ya que se debe contar con un usuario -email preferentemente el corporativo- y contraseña. La
            persona
            usuaria una vez registrada se compromete a no revelar la misma a terceras personas haciendo un uso diligente
            y
            confidencial de la misma comunicando a Gesplan S.A. cualquier incidencia que ocurra con respecto a su uso
            tales
            como
            robo extravío o acceso por terceras personas no autorizadas.</p>
    </section>

    <section>
        <h2>RESPONSABILIDAD DE LA PERSONA USUARIA</h2>
        <p>La persona usuaria garantizará la autenticidad y actualidad de todos aquellos datos que comunique mediante la
            plataforma AOT y será el único responsable de las manifestaciones falsas o inexactas que realice. Asimismo
            la
            persona usuaria se compromete expresamente a hacer un uso adecuado y lícito de la plataforma AOT y de los
            contenidos
            y servicios ofrecidos en esta plataforma y a no emplearlos para entre otros:</p>
        <ul>
            <li>Difundir contenidos delictivos violentos pornográficos racistas xenófobos ofensivos de apología del
                terrorismo o
                en general contrarios a la ley o al orden público.</li>
            <li>Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar estropear
                interrumpir
                o
                generar errores o daños en los documentos electrónicos datos o sistemas físicos y lógicos de la
                plataforma
                AOT o
                de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios
                mediante
                el consumo masivo de los recursos informáticos a través de los cuales la plataforma AOT presta sus
                servicios.
            </li>
            <li>Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los
                sistemas
                informáticos de PLATAFORMA AOT o de terceros y en su caso extraer información.</li>
            <li>Vulnerar los derechos de propiedad intelectual o industrial así como violar la confidencialidad de la
                información de PLATAFORMA AOT o de terceros.</li>
            <li>Suplantar la identidad de cualquier otro usuario.</li>
            <li>Reproducir copiar distribuir poner a disposición o de cualquier otra forma comunicar públicamente
                transformar o
                modificar los contenidos a menos que se cuente con la autorización del titular de los correspondientes
                derechos
                o ello resulte legalmente permitido.</li>
            <li>Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con
                fines
                de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.</li>
        </ul>
    </section>
    <section>
        <h2>EXCENCIÓN DE RESPONSABILIDADES</h2>
        <p>Gesplan no se hace responsable en caso de que se dieran las siguientes circunstancias: El uso que cada
            persona
            usuaria les dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en
            base a
            los mismos y que sean contrarias a lo expuesto en este Aviso Legal. Tampoco se hará responsable Gesplan de
            ataques
            cibernéticos virus programas maliciosos. Así como tampoco de los contenidos y servicios prestados por otras
            páginas
            Web a las que se pueda tener acceso de la presente. La invulnerabilidad del presente sitio web ni del
            software
            utilizado distribuido u obtenido del mismo. La falta de utilidad rendimiento o actualización de los
            contenidos
            albergados en el presente sitio web. Los daños ocasionados por la vulneración de los sistemas de seguridad.
            El
            mal
            funcionamiento del software descarga desde el link establecido al efecto para la visualización y escucha de
            determinado contenidos albergados en la presente página.</p>
    </section>
    <section>
        <h2>PROPIEDAD INTELECTUAL</h2>
        <p>En cuanto a la propiedad intelectual la persona usuaria de la plataforma AOT asume que todos los contenidos
            incluyendo el diseño gráfico código fuente logos textos gráficos ilustraciones fotografías contenidos de
            informes
            contenidos audiovisuales y demás elementos que aparecen en el sitio web son propiedad de Gesplan. Salvo que
            se
            indique lo contrario son titularidad exclusiva de Gesplan por lo que no se podrán reproducir distribuir o
            modificar
            a no ser que cuente con autorización o resulte legalmente permitido. Asimismo queda prohibido suprimir
            eludir
            y/o
            manipular el «copyright» así como los dispositivos técnicos de protección o cualesquiera mecanismos de
            información
            que pudieren contener los contenidos. La persona usuaria se compromete a respetar los derechos enunciados y
            a
            evitar
            cualquier actuación que pudiera perjudicarlos reservándose en todo caso la empresa el ejercicio de cuantos
            medios o
            acciones legales le correspondan en defensa de sus legítimos derechos de propiedad intelectual e industrial.
        </p>
    </section>
    <section>
        <h2>HIPERVÍNCULOS</h2>
        <p>En el presente sitio web puede haber publicidad de terceras personas y/o links que facilitan el enlace a
            páginas
            web
            de terceros. Gesplan en todos estos casos no es responsable de los servicios que estos terceros ofrezcan ni
            de
            las
            condiciones particulares o generales que requieran ni de los contenidos de las páginas web a los que se
            acceda
            por
            medio de los enlaces establecidos. En definitiva en ningún caso se podrá asumir responsabilidades por el
            contenido
            que pueda aparecer en páginas de terceros.</p>
    </section>
    <section>
        <h2>LEY APLICABLE Y JURISDICCIÓN</h2>
        <p>La plataforma AOT queda sometida a lo establecido por la legislación española. Resultando competentes para
            resolver
            las posibles controversias o conflictos que se deriven del uso de la presente página los Juzgados y
            Tribunales
            de
            Las Palmas de Gran Canaria renunciando expresamente la persona usuaria al ejercicio de las correspondientes
            acciones
            fuera del término indicado.</p>


    </section>
</div>