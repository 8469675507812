import { AfterContentInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.css']
})
export class TitlePageComponent implements AfterContentInit {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() showFilter: boolean = false;
  @Input() backgroundURL: string = '';

  backgroundList = {

    "documentos-disponibles": '../../../assets/images/banner_documentosdisponibles.png',
    "nueva-solicitud": '../../../assets/images/banner_documentosdisponibles.png',
    "dashboard": '../../../assets/images/footer.png'

  }

  async ngAfterContentInit(): Promise<void> {
    let header = document.getElementById('title-page-header') as HTMLDivElement;
    if (header) {
      header.style.backgroundImage = 'url(' + this.backgroundList[this.backgroundURL as keyof typeof this.backgroundList] + ')';
      console.log(this.backgroundURL)
    }
  }

}
