import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-cookies',
  templateUrl: './banner-cookies.component.html',
  styleUrl: './banner-cookies.component.css'
})
export class BannerCookiesComponent implements OnInit {

  cookiesUsuario: boolean = false;

  ngOnInit(): void {

    this.cookiesUsuario = localStorage.getItem('cookies') === 'true'
  }

  aceptarCookies(): void {
    localStorage.setItem('cookies', 'true');
    this.cookiesUsuario = true;
  }


}
