import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { AuthService } from 'src/app/services/auth.service';
import { passwordPattern } from 'src/app/tools/password.tool';
import { RespuestaApi } from 'src/app/models/respuesta-api';
import { ErrorData } from 'src/app/models/error-data';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  mostrarPassword: boolean = false;
  token: string | undefined;

  constructor(private readonly fb: FormBuilder, private route: ActivatedRoute, private authService: AuthService, private router: Router) {

  }
  ngOnInit(): void {
    this.resetPasswordForm = this.initForm();
    this.token = this.route.snapshot.queryParamMap.get('token') || undefined;


  }


  resetPasswordForm!: FormGroup;


  initForm(): FormGroup<any> {
    return this.fb.group({
      password: ['', [Validators.required, Validators.pattern(passwordPattern())]],
      password_confirm: ['', Validators.required],
    }, { validators: this.matchPasswords } as AbstractControlOptions);

  }

  matchPasswords(formGroup: FormGroup): { [key: string]: any } | null {
    const passwordControl = formGroup.get('password');
    const repeatPasswordControl = formGroup.get('password_confirm');

    if (passwordControl && repeatPasswordControl && passwordControl.value !== repeatPasswordControl.value) {
      return { passwordsNotMatch: true };
    }

    return null;
  }

  mostrarPasswordIntroducidoPorElUsuario(): void {
    this.mostrarPassword = !this.mostrarPassword;
    const password = document.getElementById('resetPassword') as HTMLInputElement;
    const repetirPassword = document.getElementById('repetirResetPassword') as HTMLInputElement;

    password.type = this.mostrarPassword ? 'text' : 'password';
    repetirPassword.type = this.mostrarPassword ? 'text' : 'password';
  }

  onSubmit(): void {
    if (!this.resetPasswordForm.valid) {
      return
    }
    if (this.token) {
      this.authService.resetPassword({
        password: this.resetPasswordForm.get('password')?.value,
        password_confirm: this.resetPasswordForm.get('password_confirm')?.value,
        token: this.token
      }).subscribe(async resp => {

        if (resp.status === 'success') {
          await mostrarMensajeDeAlerta('Éxito', 'Se ha modificado correctamente la contraseña', 'success', 'Aceptar', '', true, false);
          this.router.navigate(['']);
        }
        else if (resp.data?.password) {
          await mostrarMensajeDeAlerta('Error', 'Error en el formato de la contraseña, no puede contener ñ ni espacios en blanco', 'warning', 'Aceptar', '', true, false);
          this.router.navigate(['']);
        }
        else {
          await mostrarMensajeDeAlerta('Error', 'Póngase en contacto con un administrador', 'warning', 'Aceptar', '', true, false);
          this.router.navigate(['']);
        }
      })
    }

  }

}
