export var TEXTS = {

    //MENSAJES DE ALERTA O ERROR Y TIPOS
    MSNEXITOALERT: 'El procedimiento se ha ejecutado con exito',

    TITULOEXITO: 'Exito',
    TIPOEXITO: 'success',
    TIPOERROR: 'error',

    //MENSAJES COMUNES
    BUSCAR_FECHA: 'Buscar por fecha',
    AOT: 'AOT',
    GESTION: 'Gestión',
    INICIO_SESION: 'Iniciar sesión',

    //TEXTOS COMUNES
    CORREO: 'Correo',
    PASSWORD: 'Contraseña',
    ACCEDER: 'Entrar',
    FORGOT_PASSWORD: 'He olvidado mi contraseña',
    REGISTER: 'Registrar',
    REGISTER_ON: 'Regístrese',
    FILTRAR: 'Filtrar',
    REPITE_PASSWORD: 'Repetir contraseña',
    ACEPTAR_TERMINOS: 'Acepto los',
    TERMINOS: 'términos',
    INICIO: 'Inicio',
    RECUPERAR_PASSWORD: 'Recuperar  la contraseña',

    //TEXTOS EN EL FILTRO
    TODAS: 'Todas',
    PENDIENTES: 'Pendientes',
    EN_CURSO: 'En curso',
    ENVIADAS: 'Envidas',
    RECEPCIONADAS: 'Recepcionadas',
    ANULADAS: 'Anuladas',
    TERMINADAS: 'Terminadas',
    FILTRAR_SOLICITUDES: 'Filtrar solicitudes',
    FILTRAR_INFORMES: 'Filtrar informes',
    FILTRAR_DISPONIBLES: 'Filtrar disponibles',

    //LOCALIZACION

    PROVINCIAS: 'Provincias',
    ISLAS: 'Islas',
    MUNICIPIOS: 'Municipios',
    SELECCIONE_PROVINCIA: 'Seleccione provincia',
    SELECCIONE_ISLA: 'Seleccione isla',
    SELECCIONE_MUNICIPIO: 'Seleccione municipio',
    SELECCIONE_TEMATICA: 'Seleccione temática',
    SELECCIONE_RECURSO: 'Seleccione recurso',
    TEMATICAS: 'Temáticas',
    RECURSOS_GESPLAN: 'Recursos de Gesplan',

    //CABECERAS
    SOLICITUDES: 'Solicitudes',
    MENSAJERIA: 'Mensajeria',
    INFORMES: 'Informes',
    SUGERENCIAS: 'Sugerencias',
    DISPONIBLES: 'Documentos disponibles',
    PERFIL: 'Perfil de usuario',
    USUARIOS: 'Usuarios',


    //TABLAS
    TBLSOLICITUDES: 'solicitudes',
    //CAMPOS
    NUMERO: 'Nº',
    IR: 'IR',
    MUNICIPIO: 'Municipio',
    FECHA: 'Fecha de entrada',
    SOLICITANTE: 'Solicitante',
    TEMATICA: 'Temática',
    RECURSO: 'Recurso Gesplan',
    EDITAR: 'Editar',
    GESTIONAR: 'Gestionar',
    DETALLE: 'Detalle',
    ACEPTAR: 'Aceptar',
    ANULAR: 'Anular',
    SOLICITA: 'Solicita',
    DESCRIPCION: 'Descripción',

    //CORREO
    BANDEJA_ENTRADA: 'Bandeja de entrada',
    NUEVO_CORREO: 'Nuevo correo',
    CARPETAS: 'Carpetas',
    ENVIADOS: 'Enviados',
    PAPELERA: 'Papelera',
    BUSCAR: 'Buscar...',
    RECIBIDOS: 'Recibidos',
    //MENSAJERIA
    NUEVO_MENSAJE: 'Nuevo mensaje',
    //REGISTRO
    NOMBRE_COMPLETO: 'Nombre completo',
    CUENTA_ACTIVA: 'Ya tengo cuenta',

    //RECUPERAR CONTRASEÑA
    RECUPERAR: '¿Olvidaste tu contraseña? Aquí puede recuperar fácilmente una nueva contraseña.',
    SOLICITAR_PASSWORD: 'Solicite una nueva contraseña',

    //VENTANAS MODAL
    TITULO_RESPUESTA: 'Enviar respuesta',

    //PERFIL DEL USUARIO

    //MENSAJES DE LOGIN
    TITULOERROR: 'Se ha producido un error',
    ERRORLOGIN: 'Se ha producido un error, usuario o contraseña no validos',
    ERRORCAMPOEMAIL: '',
    ERRORCAMPOPASSWORD: '',



    //TEXTO EXPLICATIVO DE LOS TIPOS DE SOLICITUD EN UNA NUEVA SOLICITUD

    NUEVAS_SOLICITUDES: {
        Informes: 'Puedes solicitar la elaboración de informes de asesoramiento sobre cualquier duda o cuestión de la que necesites un estudio más exhaustivo.',
        Modelos: 'Puedes solicitarnos modelos de documentos relacionados con el urbanismo o el territorio que no aparezcan como "documento disponible". Puede ser un modelo de solicitud, de ordenanza municipal, de informe técnico o jurídico, de pliego, de convenio, guías, aclaraciones normativas u otros que puedan servir de apoyo a la oficina técnica municipal.',
        Otros: 'Puedes solicitar cualquier apoyo en otras cuestiones no contempladas en las opciones anteriores, dentro del objeto del programa de AOT.'
    }

};
