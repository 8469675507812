import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Categoria_disponible } from 'src/app/models/categoria_disponible';
import { Disponible } from 'src/app/models/disponible';
import { Solicitud } from 'src/app/models/solicitud';
import { AvailableDocumentsService } from 'src/app/services/available-documents.service';
import { RequestService } from 'src/app/services/request.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-available-documents',
  templateUrl: './available-documents.component.html',
  styleUrls: ['./available-documents.component.css']
})
export class AvailableDocumentsComponent implements OnInit {

  selectedModel = 'Todos los modelos';
  searchPattern = '';
  availableDocumentsRadioReset = false;
  solicitud: Solicitud = new Solicitud();

  // NECESITA SER REFACTORIZADO //

  documentList: Disponible[] = [];
  documentListFiltered: Disponible[] = [];

  categoriasDocumentosDisponibles: Categoria_disponible[] = [];

  constructor(private availableDocumentsService: AvailableDocumentsService, private router: Router, private solicitudServicio: RequestService) {

  }

  async ngOnInit(): Promise<void> {
    this.categoriasDocumentosDisponibles = [... await this.getCategoriasDocumentosDisponibles()]
    this.categoriasDocumentosDisponibles = [...this.categoriasDocumentosDisponibles, await this.addFiltrarPorUltimosPublicados()]
    await this.getAllAvailableDocuments();
  }


  async getCategoriasDocumentosDisponibles(): Promise<Categoria_disponible[]> {
    return this.availableDocumentsService.getCategoriasDocumentosDisponibles();
  }

  async addFiltrarPorUltimosPublicados(): Promise<Categoria_disponible> {
    return (new Categoria_disponible('Últimos documentos publicados', true, (new Date()), 99, '', '', -1, -1, -1))
  }

  // TODO -- SE FILTRAN LOS QUE TIENEN LA CATEGORIA A NULL //
  async getAllAvailableDocuments(): Promise<void> {
    let respuestaDisponibles = await this.availableDocumentsService.getAvailableDocuments();
    this.documentList = [...respuestaDisponibles.filter(disponible => {
      return (disponible.disponible_categoria !== null)
    })];
    this.documentListFiltered = [...this.documentList];
  }






  selectModel(id?: string): void {

    let documentModels = document.querySelectorAll('.documentModels') as NodeListOf<HTMLElement>;
    documentModels.forEach(model => {
      model.classList.remove('selected');
    });

    if (!id) {
      this.selectedModel = '';
      return
    };
    document.getElementById(`${id}`)?.classList.add('selected');
    this.selectedModel = id;


  }

  async selectDocument(e: Event): Promise<void> {
    let selectedFilterDocument: HTMLElement = e.target as HTMLElement;
    let documentType: string = selectedFilterDocument.id;
    console.log(documentType)

    this.availableDocumentsRadioReset = true;

    if (documentType == '') {
      this.documentListFiltered = [...this.documentList];
      this.selectModel('Todos los modelos')
      return;
    }

    if (documentType === 'Últimos documentos publicados') {
      this.documentListFiltered = [...[]];
      this.documentListFiltered = [...this.documentList.filter(document => document.nuevo === true)];
    }
    else {
      this.documentListFiltered = [...[]];
      this.documentListFiltered = [...this.documentList.filter(document => document.disponible_categoria.categoria === documentType)];
    }





    this.selectModel(documentType);

    if (await this.getScreenResolution() > 910) return;
    this.showAvailableDocumentsFilters();


  }


  radioFilterButtonValue(radioValue: string): void {
    if (radioValue === 'Todos los disponibles') {
      this.resetDocuments();
      return;
    }
    this.getLatestDocuments();
  }


  getLatestDocuments(): void {
    this.documentListFiltered = [...this.documentList.filter(document => document.estado === true)];
  }


  resetDocuments() {
    this.documentListFiltered = [...this.documentList];
    this.selectModel();
    this.availableDocumentsRadioReset = false;

  }



  searchDocument(searchPattern: string) {
    console.log(this.selectedModel)

    if (this.selectedModel === 'Todos los modelos') this.documentListFiltered = [...this.documentList.filter(document => document.disponible.toLowerCase().includes(searchPattern.toLowerCase()))];
    else
      this.documentListFiltered = [...this.documentList.filter(document => document.disponible.toLowerCase().includes(searchPattern.toLowerCase()) &&
        document.disponible_categoria.categoria === this.selectedModel)];
  }



  capitalizeFirst(text: string) {
    let textParsed = text.toLowerCase();
    return textParsed.charAt(0).toLocaleUpperCase() + textParsed.slice(1);
  }


  async showAvailableDocumentsFilters(): Promise<void> {
    let filtersList: HTMLDivElement = document.getElementById('available-documents-main-content-filters-list') as HTMLDivElement;
    filtersList.classList.toggle('show');

  }

  async getScreenResolution(): Promise<number> {
    return window.screen.width;
  }

  /** TODO - REFACTORIZAR */
  requestAvailableDocument(e: Event, disponible: Disponible): void {

    Swal.fire({
      title: 'Está a punto de solicitar el siguiente documento:',
      text: `${disponible.disponible}`,
      icon: 'info',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: 'Solicitar',
      denyButtonText: 'Cancelar'
    }).then(async result => {
      if (result.isConfirmed) {
        await this.crearSolicitudDocumentoDisponible(disponible)

      }
    })

  }


  async acceptRequest(): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, 800));

  }


  async crearSolicitudDocumentoDisponible(disponible: Disponible): Promise<void> {
    const status = await this.solicitudServicio.crearSolicitudAPartirDeUnDisponible(disponible);
    if (status) {
      await this.acceptRequest()
      Swal.fire({
        title: '¡Solicitud creada con éxito!',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      }).then(async result => {
        if (result.isConfirmed) {
          this.router.navigate(['/', 'usuario']);

        }
      })
    }
  }



}