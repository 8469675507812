<!--<div class="aot-select">
    <label for={{text}}>{{text}}</label>
    <select [(ngModel)]="currentValue" name="" id={{text}} (change)="selectValue(newValue.value)" #newValue
        [disabled]="isDisabled">
        <option value={{option}} *ngFor="let option of options"> {{option}}</option>
    </select>
</div>
-->
<div class="aot-select">
    <label for={{text}}>{{text}}</label>
    <select [(ngModel)]="currentValue" name="" id={{text}} (change)="selectValue(newValue.value)" #newValue #select
        [disabled]="isDisabled" (blur)="comprobarSiElCampoEstaCompletado()"
        [ngClass]="{ 'sinValor': !currentValue || currentValue.trim() === '' }">
        <option value='' disabled selected hidden>Selecciona una opción</option>
        <option value={{objeto[propiedadDelObjetoQueTieneElValor!]}} *ngFor="let objeto of objetos">
            {{objeto[propiedadDelObjetoQueTieneElNombre!]}}
        </option>
    </select>
    <div *ngIf="mostrarMensaje" style="color: rgb(169, 72, 47)">{{textoErrorNoCompletado}}</div>

</div>