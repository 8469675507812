import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { ContactoEmail } from 'src/app/models/contacto-email';
import { Correo } from 'src/app/models/correo';
import { CorreoAdjunto } from 'src/app/models/correo-adjunto';
import { NuevoCorreo } from 'src/app/models/nuevo-correo';
import { EmailService } from 'src/app/services/email.service';


@Component({
  selector: 'app-nuevo-email',
  templateUrl: './nuevo-email.component.html',
  styleUrls: ['./nuevo-email.component.css']
})



export class NuevoEmailComponent {
  public Editor = ClassicEditor;
  contactosEmail: ContactoEmail[] = [];
  contactosEmailBackup: ContactoEmail[] = [];
  contactosEmailVisibles: boolean = false;
  contactoSeleccionado: string = '';



  archivosAdjuntos: File[] = [];
  archivosAdjuntosReenviar: CorreoAdjunto[] = [];

  emailConversacion?: Correo;
  esEmailParaReenviar: boolean = false;

  constructor(private emailServicio: EmailService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {
  }

  emailForm!: FormGroup;

  /** TODO REFACTORIZAR */

  async ngOnInit(): Promise<void> {
    this.contactosEmail = [... await this.emailServicio.getContactosEmail()];
    this.contactosEmailBackup = [... this.contactosEmail];
    const emailID = this.route.snapshot.queryParams?.['emailID'];
    this.esEmailParaReenviar = this.route.snapshot.queryParams?.['reenviar'] === 'true';
    if (emailID) {
      this.emailConversacion = await this.emailServicio.getEmail(emailID)

      if (this.emailConversacion) {
        this.emailForm = this.inicializarFormularioResponderEmail();
        if (this.esEmailParaReenviar) {
          this.archivosAdjuntosReenviar = [...await this.emailServicio.getEmailAdjunto(emailID)];

        }

      }

    } else this.emailForm = this.inicializarFormularioNuevoEmail();
  }

  mostrarContactosEmail(): void {
    this.contactosEmailVisibles = true;
  }

  ocultarContactoEmail(): void {
    setTimeout(() => {
      this.contactosEmailVisibles = false;
    }, 200)

  }

  filtrarContactoEmail(event: KeyboardEvent): void {
    this.mostrarContactosEmail();
    const valorInput = (event.target as HTMLInputElement).value;
    this.contactosEmail = [... this.contactosEmailBackup.filter((contacto) => {
      return (`${contacto.nombre.toLowerCase()} ${contacto.apellidos.toLowerCase()}`).includes(valorInput.toLowerCase())
    })]
  }


  inicializarFormularioNuevoEmail(): FormGroup {

    return this.fb.group({
      receptor_id: ['', [Validators.required]],
      correo: ['', [Validators.required]],
      asunto: ['', [Validators.required]],
      correoAdjuntos: []
    })

  }

  inicializarFormularioResponderEmail(): FormGroup {
    return this.fb.group({
      receptor_id: [this.emailConversacion?.remitente.id, [Validators.required]],
      correo: [this.emailConversacion?.correo, [Validators.required]],
      asunto: [this.emailConversacion?.asunto, [Validators.required]],
      correoAdjuntos: []

    })
  }


  onFileSelected(event: any): void {
    const inputFile = event.target as HTMLInputElement;
    if (!inputFile) return;
    let file: File = inputFile.files![0];

    const validExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'zip'];

    if (file) {

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && !validExtensions.includes(fileExtension)) {
        mostrarMensajeDeAlerta('Error en el tipo de archivo', 'Solamente se permiten los siguientes tipos de archivo: doc, docx, xls, xlsx, jpg, jpeg, png, pdf, zip, txt', 'warning', 'Aceptar', '', true, false)
        inputFile.value = '';
      }
      else if (file.size / (1024 * 1024) > 25) {
        mostrarMensajeDeAlerta('Error', 'El archivo es demasiado grande, máximo 25Mb', 'warning', 'Aceptar', '', true, false)
        inputFile.value = '';
      }
      else {
        this.meterArchivoEnArrayDeAdjuntos(file)
      }
    }


  }


  meterArchivoEnArrayDeAdjuntos(file: File): void {
    if (!this.existeArchivoEnArrayDeAdjuntos(file)) {
      this.archivosAdjuntos = [...this.archivosAdjuntos, file];


    }
  }


  existeArchivoEnArrayDeAdjuntos(file: File): boolean {
    const arrayFiltrado = this.archivosAdjuntos.filter(adjunto => {
      return adjunto.name === file.name && adjunto.size === file.size && adjunto.type === file.type
    });

    if (arrayFiltrado.length === 0) return false;
    console.log('existe ya el archivo')
    return true;
  }


  eliminarArchivoEnArrayDeAdjuntos(file: File): void {
    this.archivosAdjuntos = [...this.archivosAdjuntos.filter((adjunto => { return adjunto.name !== file.name }))]

  }


  setEmailContactoID(contacto: ContactoEmail): void {
    this.contactoSeleccionado = `${contacto.nombre} ${contacto.apellidos}`
    this.emailForm.patchValue({
      receptor_id: contacto.id,
    })

  }

  async onSubmit(): Promise<void> {
    this.emailForm.patchValue({
      correoAdjuntos: [...this.archivosAdjuntos]
    });

    const respuestaEnvioEmail = await this.emailServicio.enviarEmail(this.emailForm.value as NuevoCorreo);
    if (!respuestaEnvioEmail) {
      await mostrarMensajeDeAlerta('Error', 'No ha sido posible enviar el correo, contacte con el administrador', 'warning', 'Aceptar', '', true, false)
    } else {
      this.archivosAdjuntos = [];
      await mostrarMensajeDeAlerta('Éxito', 'Su mensaje ha sido enviado correctamente', 'success', 'Aceptar', '', true, false)
    }

    this.emailForm.reset();
    this.router.navigate(['emails'])
  }
}
