import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-de-cookies-publica',
  templateUrl: './politica-de-cookies-publica.component.html',
  styleUrls: ['./politica-de-cookies-publica.component.css']
})
export class PoliticaDeCookiesPublicaComponent {

}
