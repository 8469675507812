<div class="emails">
    <div class="emails-sidebar">
        <app-sidebar-correo [botonAMostrar]="'nuevo_correo'"></app-sidebar-correo>
    </div>
    <div class="emails-list" *ngIf="!emailSeleccionado">
        <div class="emails-list-header" *ngIf="emails.length !== 0">
            <input type="search" placeholder="Buscar..." (keyup)="filtrarCorreos($event)">
        </div>

        <div class="emails-list-content">
            <div class="emails-list-content-selector">
                <!-- <input type="checkbox" name="" id="" class="emails-list-content-selector-checkbox"
                    (change)="seleccionarTodosLosCorreos()"><span class="emails-list-content-selector-delete"
                    (click)="eliminarEmail()">Borrar</span>-->
                <div class="emails-list-content-selector-pagination" *ngIf="this.emails.length > 50">
                    <span>1 - 50 de {{this.emails.length}}</span>
                    <img src="../../../assets/images/flecha_gris.svg" alt=""
                        class="emails-list-content-selector-pagination-prev">
                    <img src="../../../assets/images/flecha_negra.svg" alt=""
                        class="emails-list-content-selector-pagination-pos">
                </div>

            </div>
            <div class="emails-list-records">


                <strong style="text-align: center;" *ngIf="emails.length === 0">No existen correos en esta
                    bandeja</strong>

                <div href="" *ngFor="let email of emails">
                    <!--<input type="checkbox" name="" id="{{email.id}}"
                        class="emails-list-record-checkbox" #inputcheckbox (change)="seleccionarCorreo(email.id)">-->



                    <p class="emails-list-record-data" (click)="getEmail(email)">
                        <span class="emails-list-record-name"
                            *ngIf="bandejaSeleccionada !== 'enviados' && bandejaSeleccionada !== 'papelera' ">{{email.remitente.nombre}}</span>
                        <span class="emails-list-record-name"
                            *ngIf="bandejaSeleccionada === 'enviados' || bandejaSeleccionada === 'papelera'">{{email.receptor.nombre}}</span>
                        <span class="emails-list-record-subject"
                            [ngClass]="{'no-leido': !email.visto}">{{email.asunto}}</span>
                        <span class="emails-list-record-date">{{parsearFecha(email.fecha)}}</span>
                    </p>
                    <img *ngIf="bandejaSeleccionada !== 'papelera'" src="../../../../assets/images/papelera.svg" alt=""
                        class="emails-list-record-data-delete" (click)="eliminarEmail(email.id)">

                </div>
            </div>
        </div>
    </div>

    <div class="ver-email" *ngIf="emailSeleccionado">

        <div class="email-asunto">
            <h1>{{this.email.asunto}}</h1>
        </div>

        <div class="email-remitente">
            <img src="../../../../assets/images/usuario.svg" alt="">
            <div class="email-remitente-data">
                <p>{{this.email.remitente.nombre}} {{this.email.remitente.apellidos}}</p>
                <div class="email-receptor">
                    Para: <span>{{this.email.receptor.nombre}} {{this.email.receptor.apellidos}}</span>
                </div>
            </div>

        </div>



        <ckeditor [editor]="Editor" data="" #correo [disabled]="true" [config]="configEditor"
            [(ngModel)]="email.correo">
        </ckeditor>

        <div class="email-footer">
            <button class="secondary-button" [routerLink]="['/nuevo-email']"
                [queryParams]="{ emailID: email.id, reenviar: true }">Reenviar</button>
            <button class="primary-button" [routerLink]="['/nuevo-email']"
                [queryParams]="{ emailID: email.id, reenviar: false }">Responder</button>
        </div>

    </div>


</div>