import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MATERIAS_TRANSVERSALES_CREANDO_CONCIENCIA, MATERIAS_TRANSVERSALES_PARTICIPANDO_AOT } from 'src/app/helpers/materias_transversales';
import { ScrollpageService } from 'src/app/services/scrollpage.service';
import { removeClassForAllClasName, addClassToElement } from 'src/app/tools/html-elements.tool';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-concienciacion-ciudadana',
  templateUrl: './concienciacion-ciudadana.component.html',
  styleUrls: ['./concienciacion-ciudadana.component.css']
})
export class ConcienciacionCiudadanaComponent implements AfterViewInit, OnDestroy, OnInit {


  public materiasTransveraslesParticipandoAOT: any[] = [];
  public materiasTransveraslesCreandoConciencia: any[] = [];
  private scrollSubscription!: Subscription;
  versionPro: boolean = false;



  constructor(private scrollService: ScrollpageService) {
    this.materiasTransveraslesParticipandoAOT = [...MATERIAS_TRANSVERSALES_PARTICIPANDO_AOT];
    this.materiasTransveraslesCreandoConciencia = [...MATERIAS_TRANSVERSALES_CREANDO_CONCIENCIA];

  }
  ngOnInit(): void {
    this.versionPro = environment.version === 'pro';
  }

  ngAfterViewInit(): void {
    const globalDiv = document.querySelector('#aot-router-outlet');
    if (globalDiv) {
      this.scrollService.creacionFuncionListener(globalDiv);
      this.scrollSubscription = this.scrollService.getScrollObservable().subscribe(respuestaObservable => {
        if (respuestaObservable) {
          const e = document.getElementById('who-menu') as HTMLElement;

          e.style.marginTop = '0px';

        }
        else {
          const e = document.getElementById('who-menu') as HTMLElement;
          e.style.marginTop = '-163px';
        }
      })
    }
  }


  ngOnDestroy(): void {

    this.scrollService.eliminarFuncionListener();
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }


  openVideo(videoName: string): void {
    window.open(videoName, '_blank');
  }


  async materialTransversalSeleccionada(id?: string): Promise<void> {
    if (!id) return;

    await removeClassForAllClasName('who-menu-link', 'selected');

    await addClassToElement('selected', id);

    this.scrollEnLaPagina(`${id}-seccion`);
  }


  scrollEnLaPagina(target: string): void {
    this.scrollService.scrollToElement(target);
  }

}
