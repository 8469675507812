import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';

type IconType = SweetAlertIcon | undefined;


const mostrarMensajeDeAlerta = async (
    titulo: string = '',
    texto: string = '',
    icono: IconType = 'info',
    textoConfirmacion: string = 'Aceptar',
    textoCancelar: string = 'Cancelar',
    mostrarBotonConfirmacion: boolean = true,
    mostrarBotonCancelar: boolean = true,
    redireccion?: string): Promise<boolean> => {

    const respuestaDelUsuario = await Swal.fire({
        title: titulo,
        text: texto,
        icon: icono,
        showCloseButton: mostrarBotonConfirmacion,
        showDenyButton: mostrarBotonCancelar,
        confirmButtonText: textoConfirmacion,
        denyButtonText: textoCancelar
    })
    return respuestaDelUsuario.isConfirmed;
}


const mostrarMensajeDeCarga = async (
    titulo: string = '',
    texto: string = '',
    icono: IconType = 'info'): Promise<boolean> => {

    const mensajeConLoading = await Swal.fire({
        title: titulo,
        text: texto,
        icon: icono,
        showCloseButton: false,
        showDenyButton: false,
        didOpen: () => {
            Swal.showLoading();
        }
    })
    return mensajeConLoading.isConfirmed;
}


const cerrarMensaje = () => {
    return Swal.close()
}


export { mostrarMensajeDeAlerta, mostrarMensajeDeCarga, cerrarMensaje }

