<div class="forums">
    <div class="default-menu" id="user-menu">
        <a class="default-menu-link forum-link secondary-link" id="inprogress-forums"
            (click)="selectedForum($event.target!)" [ngClass]="{ 'disabled-link': versionPro}">Previstos
        </a>
        <a class="default-menu-link forum-link secondary-link" id="finished-forums"
            (click)="selectedForum($event.target!)" [ngClass]="{ 'disabled-link': versionPro}">Realizados</a>
        <a class="default-menu-link forum-link secondary-link selected" id="pildoras-forums"
            (click)="selectedForum($event.target!)">Píldoras formativas</a>
    </div>

    <div class="forum-section" id="inprogress-forums-section" [ngClass]="{ 'disabled-link': versionPro}">
        <h2>Previstos</h2>
        <hr>

        <!-- TODO -->
        <div class="forum-section-content">
            <div class="forum-section-content-column" id="inprogress-1">
                <app-forum [forumStatus]="'Próximamente'" [forumTitle]="'El Órgano Ambiental'"
                    [forumDate]="'28 septiembre 2023'" [forumIMG]="'foro1.png'" [forumModality]="'Online'"
                    [forumDuration]="'2 semanas'" (moreInfoEmmiter)="expandHeightColumn($event)"
                    [forumID]="'inprogress-1'"
                    [forumObservations]="'Se enviará un correo electrónico con el enlace de acceso a la formación cuando se aproxime la fecha de impartición.'"
                    [forumSpeaker]="'Guzmán Abreu Acosta. Técnico de GESPLAN, Santiago Hernández Torres. Técnico geógrafo del Servicio de Urbanismo del Ayuntamiento de Las Palmas de Gran Canaria, María Elena Castro Pérez. Técnica del servicio de apoyo al órgano ambiental - presidenta CEALP.'"
                    [forumTarget]="'Personal de la administración pública.'" [forumDigitalTools]="'Zoom Webinar'"
                    [forumProgram]="'El programa se estructurará en tres bloques: Bloque I: Marco teórico. Explicación referente al órgano ambiental. Bloque II: Casos prácticos. Exposición de dos casos prácticos relacionados con la conformación del órgano ambiental en Canarias, uno municipal y otro insular. Bloque III: Debate y exposición de posibles situaciones relacionadas con el tema de la formación por parte de todas las personas asistentes.'"
                    [forumDescription]="'Uno de los cambios fundamentales introducidos por la vigente Ley de Suelo de Canarias ha sido el reconocimiento de la competencia de cabildos y ayuntamientos para crear sus propios Órganos Ambientales. Con esta acción formativa se pretende aportar información útil dirigida a la parte política responsable y personal técnico en el proceso de toma de decisión sobre crear o no un órgano ambiental en el ámbito de su municipio. Para ello hemos diseñado un contenido eminentemente práctico sobre los aspectos fundamentales de la constitución, funcionamiento y competencias de un Órgano Ambiental en el ámbito municipal, con la intervención de profesionales que actualmente realizan labores en órganos ambientales que expondrán algunas de sus experiencias.'">
                </app-forum>
            </div>

            <div class="forum-section-content-column" id="inprogress-2">
                <app-forum [forumProgress]="25" [forumTitle]="'Participación pública en la práctica urbanística'"
                    [forumDate]="'¡Ya puedes inscribirte!'" [forumStatus]="'Inscripción abierta'"
                    [forumIMG]="'foro2.png'" [forumModality]="'Online'" [forumDuration]="'1 semana'"
                    (moreInfoEmmiter)="expandHeightColumn($event)" [forumID]="'inprogress-2'">
                </app-forum>
            </div>
        </div>
    </div>


    <div class="forum-section" id="finished-forums-section" [ngClass]="{ 'disabled-link': versionPro}">
        <h2>Realizados</h2>
        <hr>

        <!-- TODO -->
        <div class="forum-section-content">
            <div class="forum-section-content-column" id="finished-1">
                <app-forum [forumStatus]="'Impartida'"
                    [forumTitle]="'Uso de la plataforma de apoyo a oficinas técnicas'"
                    [forumDate]="'28 septiembre 2023'" [forumIMG]="'foro3.png'" [forumModality]="'Online'"
                    [forumDuration]="'2 semanas'" (moreInfoEmmiter)="expandHeightColumn($event)"
                    [forumID]="'finished-1'"
                    [forumObservations]="'Se enviará un correo electrónico con el enlace de acceso a la formación cuando se aproxime la fecha de impartición.'"
                    [forumSpeaker]="'Guzmán Abreu Acosta. Técnico de GESPLAN, Santiago Hernández Torres. Técnico geógrafo del Servicio de Urbanismo del Ayuntamiento de Las Palmas de Gran Canaria, María Elena Castro Pérez. Técnica del servicio de apoyo al órgano ambiental - presidenta CEALP.'"
                    [forumTarget]="'Personal de la administración pública.'" [forumDigitalTools]="'Zoom Webinar'"
                    [forumProgram]="'El programa se estructurará en tres bloques: Bloque I: Marco teórico. Explicación referente al órgano ambiental. Bloque II: Casos prácticos. Exposición de dos casos prácticos relacionados con la conformación del órgano ambiental en Canarias, uno municipal y otro insular. Bloque III: Debate y exposición de posibles situaciones relacionadas con el tema de la formación por parte de todas las personas asistentes.'"
                    [forumDescription]="'Uno de los cambios fundamentales introducidos por la vigente Ley de Suelo de Canarias ha sido el reconocimiento de la competencia de cabildos y ayuntamientos para crear sus propios Órganos Ambientales. Con esta acción formativa se pretende aportar información útil dirigida a la parte política responsable y personal técnico en el proceso de toma de decisión sobre crear o no un órgano ambiental en el ámbito de su municipio. Para ello hemos diseñado un contenido eminentemente práctico sobre los aspectos fundamentales de la constitución, funcionamiento y competencias de un Órgano Ambiental en el ámbito municipal, con la intervención de profesionales que actualmente realizan labores en órganos ambientales que expondrán algunas de sus experiencias.'">
                </app-forum>
            </div>
        </div>
    </div>


    <div class="forum-section current" id="pildoras-forums-section">
        <h2>GESTIÓN DE PROYECTOS EUROPEOS PARA EL PERSONAL TÉCNICO MUNICIPAL E INSULAR</h2>
        <div class="forum-section-intro">

            <p>
                El programa formativo se ha diseñado en respuesta a las necesidades detectadas en los municipios. Este
                programa comprende un paquete de píldoras formativas diseñadas para cubrir todo el ciclo de vida de un
                proyecto europeo, desde su conceptualización hasta su justificación final. Los participantes adquirirán
                conocimientos esenciales sobre la búsqueda de fondos, la elaboración de propuestas, la gestión de
                proyectos y su justificación, y los capacitará para desenvolverse con éxito en el ámbito de los
                proyectos financiados por la Unión Europea.
                <br>Se han dividido las píldoras en 4 bloques para así facilitar la visualización y el entendimiento de
                los
                mismos. En el siguiente <a class="aot-download-link" href={{presentacionPildoraVideoURL}}
                    target="_blank">enlace</a> puedes ver un
                vídeo presentación del
                proyecto.


            </p>

        </div>

        <!-- TODO -->
        <div class="forum-section-content">

            @for (pildora of pildorasFormativas; track pildora.bloque){
            <div [class.revertido]="pildora.bloque % 2 === 0" class="concienciacion-ciudadana-seccion">
                <img src={{pildora.imagen_bloque}} alt="">
                <div class="concienciacion-ciudadana-seccion-info">
                    <h1>Bloque {{pildora.bloque}}: {{pildora.nombre_del_bloque}}</h1>
                    <p>{{pildora.descripcion_bloque}}</p>
                    <table>
                        <tr>
                            <th>Índice</th>
                            <th>Contenido</th>
                        </tr>
                        @for (tema of pildora.temas; track tema; let index = $index){
                        <tr>
                            <td>{{index + 1}}: {{tema.nombre}}</td>
                            <td class="icons">
                                <img src="../../../assets/images/pdf.svg" alt="" (click)="openVideo(tema.urlPPT)">
                                <img src="../../../assets/images/video.PNG" alt="" (click)="openVideo(tema.urlVIDEO)">
                            </td>
                        </tr>
                        }
                    </table>
                </div>

            </div>
            }
        </div>
    </div>

</div>