import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EmailSignalService } from 'src/app/signals/email.signal.service';
import { removeClassForAllClasName, addClassToElement } from 'src/app/tools/html-elements.tool';

@Component({
  selector: 'app-sidebar-correo',
  templateUrl: './sidebar-correo.component.html',
  styleUrls: ['./sidebar-correo.component.css']
})
export class SidebarCorreoComponent {

  numeroEmailNoLeidos: number = 0;

  @Input() botonAMostrar: string = 'bandeja_entrada';

  constructor(private emailComunicacionSignal: EmailSignalService, private router: Router) {

    this.emailComunicacionSignal.emailNoLeidos$.subscribe(suscripcion => {
      this.numeroEmailNoLeidos = suscripcion
    })
  }



  seleccionarBandeja(bandeja: string) {
    this.emailComunicacionSignal.seleccionarBandeja(bandeja);
    const state = { bandejaSeleccionada: bandeja };
    this.cambiarIndicadorBandejaSeleccionada(bandeja);
    this.router.navigate(['/emails'], { state });
  }

  cambiarIndicadorBandejaSeleccionada(bandejaSeleccionada: string) {
    removeClassForAllClasName('email-filter-list-link', 'selected');
    addClassToElement('selected', bandejaSeleccionada);
  }






}
