import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { EMAIL_CONTACTO_AOT } from 'src/app/helpers/global';
import { CONTACTOS_AOT, CONTACTOS_AOT_LP } from 'src/app/helpers/contactos-aot';
import { ContactoAOT } from 'src/app/models/contacto-aot';

@Component({
  selector: 'app-aot-contact',
  templateUrl: './aot-contact.component.html',
  styleUrls: ['./aot-contact.component.css']
})
export class AotContactComponent implements OnInit {

  _codigoIsla = -1;
  emailDeContacto: string = '';

  CONTACTOS_AOT_RESPONSABLES: ContactoAOT[] = [];
  CONTACTOS_AOT_TECNICOS: ContactoAOT[] = [];
  CONTACTOS_AOT_RESPONSABLES_LP: ContactoAOT[] = [];
  CONTACTOS_AOT_TECNICOS_LP: ContactoAOT[] = [];


  constructor(private usuarioLocalServicio: UsuarioLocalService) {
  }
  async ngOnInit(): Promise<void> {
    const usuarioLocal: Usuario | null = await this.usuarioLocalServicio.getLocalUser();
    console.log('usuariolocal: ', usuarioLocal)
    if (usuarioLocal) {
      this._codigoIsla = usuarioLocal.municipio.isla.provincia.id
      this.emailDeContacto = this.getEmailDeContactoDeAOT(this.codigoIsla)
    }

    this.inicializarContactos();


  }

  inicializarContactos(): void {
    this.CONTACTOS_AOT_RESPONSABLES = CONTACTOS_AOT.RESPONSABLES;
    this.CONTACTOS_AOT_TECNICOS = CONTACTOS_AOT.TECNICOS;

    this.CONTACTOS_AOT_RESPONSABLES_LP = CONTACTOS_AOT_LP.RESPONSABLES;
    this.CONTACTOS_AOT_TECNICOS_LP = CONTACTOS_AOT_LP.TECNICOS;
  }

  getEmailDeContactoDeAOT(codIsla: number): string {
    return codIsla === 1 ? EMAIL_CONTACTO_AOT.TF : EMAIL_CONTACTO_AOT.LP
  }

  get codigoIsla(): number {
    return this._codigoIsla;
  }

  set codigoIsla(value: number) {
    this._codigoIsla = value;
  }




}
