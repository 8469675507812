import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-forum',
  templateUrl: './user-forum.component.html',
  styleUrls: ['./user-forum.component.css']
})
export class UserForumComponent {

  @Input() forumName: string = '';
  @Input() forumNumber: string = '';
  @Input() forumDate: string = '';
  @Input() forumStatus: string = '';
  @Input() forumIMG: string = '';
  @Input() forumModality: string = '';
  @Input() forumDuration: string = '';
  @Input() forumDescription: string = '';
  @Input() forumProgram: string = '';
  @Input() forumDigitalTools: string = '';
  @Input() forumTarget: string = '';
  @Input() forumSpeaker: string = '';
  @Input() forumObservation: string = '';


  openChat(id: string): void {
    document.getElementById(`${id}-chat`)?.classList.toggle('show');
  }

}
