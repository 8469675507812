import { environment } from "src/environments/environment";

export const Global = {
    url: environment.apiUrl,
    resetPassword: environment.resetPasswordUrl,

    //ESTÁTICAS
    ARCHIVO_PLANEAMIENTO: environment.archivoPlaneamientoUrl,
    VIDEOS_PILDORAS_FORMATIVAS: environment.assets + 'videos/',
    VIDEOS_MATERIAS_TRANSVERSALES: environment.assets + 'videos/materias_transversales/',
    PRESENTACIONES_PILDORAS_FORMATIVAS: environment.assets + 'presentaciones/',
    PDF_MATERIAS_TRANSVERSALES: environment.assets + 'pdf/materias_transversales/',
    IMAGENES_PILDORAS_FORMATIVAS: environment.assets + 'images/pildoras/',
    IMAGENES_MATERIAS_TRANSVERSALES: environment.assets + 'images/materias_transversales/',

    //APIS
    URL_DISPONIBLES: 'disponibles?include_associations=DisponibleCategorias&sort=Disponibles.fecha&direction=desc',
    URL_DISPONIBLES_CATEGORIAS: 'disponible-categorias?sort=DisponibleCategorias.orden',
    URL_SOLICITUD: 'solicitudes',
    URL_SOLICITUDES_ANUALES: '/estadisticas/anualizadas',
    URL_EXPEDIENTES: 'expedientes',
    URL_CORREOS: 'correos',
    URL_CORREOS_EXTERNO: 'utils/contacto',

    //AUTENTICACION
    USER_LOGGED: 'user_logged',
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: "refresh_token",


    //LOCALIZACIONES
    PROVINCE: 'provincias',


    //USUARIO
    FICHERO_ADJUNTO: environment.ficheroAdjunto
};


/** CAMBIAR CONTRASEÑA USUARIO LOGEADO */
export const CAMBIAR_PASSWORD = {
    URL: Global.url + 'auth/change_password'
}

/*VALIDAR USUARIO*/

export const VALIDAR_USUARIO = {
    URL: Global.url + 'auth/validate_account',
}


/*CARGOS*/

export const CARGOS = {
    URL: Global.url + 'cargos/?sort=Cargos.orden,Cargos.cargo',
}


/*CORPORACIONES*/

export const CORPORACIONES = {
    URL: Global.url + 'corporaciones/?sort=Corporaciones.corporacion',
}


/*ISLAS*/

export const ISLAS = {
    URL: Global.url + 'islas/?sort=Islas.isla',
    URL_ISLA_CON_PROVINCIA: Global.url + 'islas/?sort=Islas.isla&provincia_id='
}

/*ISLAS*/

export const PROVINCIAS = {
    URL: Global.url + 'provincias/?sort=Provincias.provincia'
}


/*MUNICIPIOS*/

export const MUNICIPIOS = {
    URL: Global.url + 'municipios/?sort=Municipios.municipio'
}

/*PROFESIONES*/

export const PROFESIONES = {
    URL: Global.url + 'profesiones/?sort=Profesiones.orden,Profesiones.profesion'
}

/*TEMATICAS*/

export const TEMATICAS = {
    URL: Global.url + 'tematicas/'
}

/*SECCIONES*/

export const SECCIONES = {
    URL: Global.url + 'secciones/?sort=Secciones.orden,Secciones.seccion'
}


/*ESTADOS*/

export const ESTADOS = {
    URL: Global.url + 'estados/'
}


/** REGISTRO */

export const REGISTRO = {
    URL_REGISTRO: Global.url + 'auth/register?',
    URL_ACTUALIZACION: Global.url + 'usuarios/',
    URL_BORRAR: Global.url + 'usuarios/borrarme?usuario_id=',
}


/**EMAIL */
export const EMAIL = {
    URL_EMAILS: Global.url + 'correos/',
    ORDENAR_EMAILS: '?sort=Correos.id&include_associations=Remitentes, Receptores&direction=DESC',
    URL_ACTUALIZACION: Global.url + 'usuarios/',
    URL_BORRAR: Global.url + 'usuarios/borrarme?usuario_id=',
    ADJUNTOS_EMAILS: '/correo-adjuntos',
    URL_CONTACTO: Global.url + 'usuarios/contactos/?include_associations=Cargos',
    CORREO_EXTERNO: Global.url + 'utils/contacto'
}


/** USUARIO */
export const USUARIO = {
    URL_INFO_USUARIO: Global.url + 'usuarios/',
    INFO_USUARIO_ASOCIACIONES: '?include_associations=Municipios, Municipios.Islas, Municipios.Islas.Provincias, Cargos, Secciones, Profesiones'
}


/** EMAILS DE CONTACTO AOT*/

export const EMAIL_CONTACTO_AOT = {
    LP: 'aotgesplanlp@territoriocanario.org',
    TF: 'aotgesplantf@territoriocanario.org'
}