<div *ngIf="!presentation" class="aot-content-title"
    [ngStyle]="{'background-image': 'url(' + background + '.png)', 'align-items':headerInfo.align}">
    <h1>{{headerInfo.title}}</h1>
</div>


<div *ngIf="presentation" class="aot-content-title presentation"
    [ngStyle]="{'background-image': 'url(' + background + '.png)', 'align-items':headerInfo.align}">
    <div class="requestPerYear" id="requestPerYear" (mouseenter)="showReportsData()" (mouseleave)="hiddeReportsData()">
        <h2>Solicitudes por año</h2>

        <div class="requestPerYearData">
            <span class="data">
                <span id="year1">{{year1}}</span>
                <span id="year2">{{year2}}</span>
                <span id="year3">{{year3}}</span>
                <span id="year4">{{year4}}</span>
            </span>
            <span class="data-separator"> : </span>
            <span class="data">{{solicitudesActuales}}</span>
        </div>
        <div class="hiddenData" id="hiddenData">
            <p *ngFor="let solicitudAnual of solicitudesAnuales.slice(0, -1)">
                <span class="hiddenDataYear">{{solicitudAnual.anio}}</span>
                <span class="hiddenDataSeparator"> : </span>
                <span class="hiddenDataRecount">{{solicitudAnual.recuento}}</span>
            </p>

        </div>
    </div>

    <div class="totalReports">
        <h2>Solicitudes totales</h2>
        <span>{{totalReports}}</span>
    </div>



</div>