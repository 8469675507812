<div class="aot-input-radio" *ngFor="let objeto of objetos; index as i">
    <label for="{{radioName}}"
        [ngStyle]="{'color': objeto[propiedadDelObjetoQueTieneElColor!]}">{{objeto[propiedadDelObjetoQueTieneElNombre!]}}</label>
    <input id="radio-{{radioName}}-{{objeto[propiedadDelObjetoQueTieneElValor!]}}" name="{{radioName}}"
        (change)="radioValueChanged(objeto[propiedadDelObjetoQueTieneElValor!])" #newValue type="radio">
</div>

<!--
<div class="aot-input-radio" *ngFor="let radioValue of radioValues">
    <input id="{{radioValue}}" name="{{radioName}}" (input)="radioValueChanged(radioValue)" type="radio">
    <label for="{{radioName}}">{{radioValue}}</label>
</div>-->