import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavegacionService } from 'src/app/services/navegacion.service';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Input() url: string = '';

  userName: string = '';
  versionPro: boolean = false;



  constructor(private router: Router, private usuarioLocalService: UsuarioLocalService, private navegacionServicio: NavegacionService) { }


  async ngOnInit(): Promise<void> {
    const usuario = await this.usuarioLocalService.getLocalUser();
    if (usuario) this.userName = usuario.nombre;

    this.versionPro = environment.version === 'pro';

  }

  ngAfterViewInit(): void {
    this.navegacionServicio.getUrlActual$().subscribe((url) => {
      this.actualizarLinkDondeSeEncuentraElUsuario(url)
    });
  }

  actualizarLinkDondeSeEncuentraElUsuario(url: string): void {


    let links = document.querySelectorAll('.main-link');

    links.forEach(link => {
      link.classList.remove('selected');

      if (`${link.id}` === `main-link-${url}`) {
        link.classList.add('selected')
      } else {
        if (url === 'usuario') this.actualizarIconoDeUsuario();
      }
    });

  }

  actualizarIconoDeUsuario(): void {
    let iconoUsuario = document.querySelector('#icono-usuario') as HTMLElement;
    iconoUsuario.classList.add('selected');
  }



  async removeClassFromHTMLElement(element: HTMLElement, className: string): Promise<void> {
    element.classList.remove(className);
  }


  async hiddeSideBarMenu(): Promise<void> {
    let sidebar: HTMLElement = document.getElementById('sidebar') as HTMLElement;
    this.removeClassFromHTMLElement(sidebar, 'open')
  }











}
