const formatDateToYYYYMMDD = (date: Date | string): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    if (isNaN(date.getTime())) {
        return 'Fecha inválida';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear())

    return `${year}/${month}/${day}`;
};

const formatDateToDDMMYYYY = (date: Date | string): string => {
    if (typeof date === 'string') {
        date = new Date(date);
    }

    if (isNaN(date.getTime())) {
        return 'Fecha inválida';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear())

    return `${day}/${month}/${year}`;
};

const verificarRangoDeFechas = (fechaInicio: string, fechaFin: string, fechaSolicitud: string): boolean => {
    const dateInicio = new Date(fechaInicio);
    const dateFin = new Date(fechaFin);
    const dateSolicitud = new Date(fechaSolicitud);

    // Ajusta las fechas para que solo se comparen por día (sin horas, minutos, etc.)
    dateInicio.setHours(0, 0, 0, 0);
    dateFin.setHours(23, 59, 59, 999);
    dateSolicitud.setHours(0, 0, 0, 0);

    return dateSolicitud >= dateInicio && dateSolicitud <= dateFin;
};


export { formatDateToYYYYMMDD, verificarRangoDeFechas, formatDateToDDMMYYYY }