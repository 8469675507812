import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent<T> implements AfterViewInit, OnInit {
  ngOnInit(): void {
  }

  @Input() placeholder: string | number = '';
  @Input() text: string = '';
  @Input() type: string = '';
  @Output() fileValueEmitter = new EventEmitter<File>();
  @Output() inputTextValueEmitter = new EventEmitter<string>();

  @ViewChild('inputText') inputText!: ElementRef;

  @Input() blur: boolean = false;
  @Input() textoErrorNoCompletado: string = '';
  @Input() valorPorDefecto: string = '';

  mostrarMensaje: boolean = false;

  ngAfterViewInit(): void {
  }


  onFileSelected(event: any) {

    const inputFile = event.target as HTMLInputElement;
    if (!inputFile) return;
    let file: File = inputFile.files![0];

    const validExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'zip'];

    if (file) {

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && !validExtensions.includes(fileExtension)) {
        mostrarMensajeDeAlerta('Error en el tipo de archivo', 'Solamente se permiten los siguientes tipos de archivo: doc, docx, xls, xlsx, jpg, jpeg, png, pdf, zip, txt', 'warning', 'Aceptar', '', true, false)
        inputFile.value = '';
      }
      else if (file.size / (1024 * 1024) > 25) {
        mostrarMensajeDeAlerta('Error', 'El archivo es demasiado grande, máximo 25Mb', 'warning', 'Aceptar', '', true, false)
        inputFile.value = '';
      }
      else {
        console.log('entre sin archivo', file)
        this.fileValueEmitter.emit(file);

      }
    }

  }


  inputTextValue(event: any) {
    this.comprobarSiElCampoEstaCompletado();
    this.inputTextValueEmitter.emit(event.target.value)
  }

  resetValorDelInput() {
    if (this.inputText && this.inputText.nativeElement) {
      this.inputText.nativeElement.value = '';
    }

  }



  comprobarSiElCampoEstaCompletado(): void {
    if (!this.blur) return;

    if (this.type === 'email') {
      const comprobarEmail = this.verificarEmail();
      if (!comprobarEmail && this.inputText.nativeElement.value !== '') {
        this.marcarComoNoRelleno();
        this.modificarTextoDeError('El formato de email es incorrecto');
        return;
      }
      if (!comprobarEmail) {
        this.marcarComoNoRelleno();
        this.modificarTextoDeError('Este campo es obligatorio');

        return;
      }
      this.marcarComoRelleno();
      return
    }

    if (this.inputText.nativeElement.value !== '') {
      this.marcarComoRelleno()
    }

    else {
      this.marcarComoNoRelleno();
    }
  }




  marcarComoNoRelleno(): void {
    this.inputText.nativeElement.classList.add('rojo');
    this.inputText.nativeElement.classList.remove('verde');
    this.mostrarMensaje = true;


  }

  marcarComoRelleno(): void {
    this.inputText.nativeElement.classList.remove('rojo');
    this.inputText.nativeElement.classList.add('verde');
    this.mostrarMensaje = false;


  }

  verificarEmail(): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(this.inputText.nativeElement.value);
  }

  modificarTextoDeError(nuevoTextoDeError: string): void {
    this.textoErrorNoCompletado = nuevoTextoDeError;
  }


}
