import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { fromEvent } from "rxjs";

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements AfterViewInit, OnDestroy {





  /**CÓDIGO NECESITA SER REFACTORIZADO -- POR AHORA FUNCIONA */
  scroll = 0;
  programObjectCurrentYPosition = 0;
  programObjectPreviousYPosition = 0;
  programObJectStartYPosition = 0;
  mainScrollSection: HTMLElement | undefined;
  programObject: HTMLElement | undefined;


  ngAfterViewInit(): void {
    this.programObjectCurrentYPosition = document.getElementById('objeto-del-programa')!.getBoundingClientRect().top;
    this.programObJectStartYPosition = document.getElementById('objeto-del-programa')!.getBoundingClientRect().top;
    this.mainScrollSection = document.getElementById('aot-router-outlet')!;

    this.mainScrollSection.addEventListener('scroll', () => {
      this.programObjectCurrentYPosition = document.getElementById('objeto-del-programa')!.getBoundingClientRect().top;
      this.scrollEvent(this.programObJectStartYPosition, this.programObjectCurrentYPosition);
    })
  }


  ngOnDestroy(): void {
    this.mainScrollSection?.removeEventListener('scroll', () => {

    })
  }


  scrollEvent(startPosition: number, currentPosition: number) {

    this.whereIAM();
    if ((this.programObJectStartYPosition / 2) - (this.programObjectCurrentYPosition - this.programObJectStartYPosition) / 2 < 10 || this.programObJectStartYPosition === this.programObjectCurrentYPosition) {
      document.getElementById('who-menu')?.classList.remove('top');
    } else {
      document.getElementById('who-menu')?.classList.add('top');
    }
  }



  whereIAM() {

    if (document.getElementById('equipo-multidisciplinar')!.getBoundingClientRect().top < 400) {
      document.getElementById('who-multiple')!.classList.add('selected')
      document.getElementById('who-lines')!.classList.remove('selected')
      document.getElementById('who-program')!.classList.remove('selected')
    }

    else if (document.getElementById('lineas-de-actuacion')!.getBoundingClientRect().top < 300) {
      document.getElementById('who-multiple')!.classList.remove('selected')
      document.getElementById('who-lines')!.classList.add('selected')
      document.getElementById('who-program')!.classList.remove('selected')
    }

    else {
      document.getElementById('who-multiple')!.classList.remove('selected')
      document.getElementById('who-lines')!.classList.remove('selected')
      document.getElementById('who-program')!.classList.add('selected')
    }



  }

  scrollIntoView(target: string) {
    document.getElementById(target)!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    this.programObjectCurrentYPosition = document.getElementById('objeto-del-programa')!.getBoundingClientRect().top;
    this.programObjectPreviousYPosition = this.programObjectCurrentYPosition;


    document.getElementById('who-menu')?.classList.add('top')


  }



}
