import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Solicitud } from 'src/app/models/solicitud';
import { Tematica } from 'src/app/models/tematica';
import { TematicaService } from 'src/app/services/tematica.service';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { Router } from '@angular/router';
import { RespuestaSolicitudApi } from 'src/app/models/respuesta.api';
import { RequestService } from 'src/app/services/request.service';
import { SelectComponent } from 'src/app/shared/select/select.component';
import { InputComponent } from 'src/app/shared/input/input.component';
import { TextAreaComponent } from 'src/app/shared/text-area/text-area.component';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  solicitud: Solicitud = new Solicitud();

  tematicas: Tematica[] = [];
  nombresTematicas: string[] = [];
  informacionAdicionalTematica: string = '';

  archivoSeleccionado: File | null = null;

  @ViewChild('tipoDeSolicitud') tipoDeSolicitudViewChild!: SelectComponent<Tematica>;
  @ViewChild('solicita') solicitaViewChild!: InputComponent<Text>;
  @ViewChild('descripcionSolicitud') descripcionSolicitudViewChild!: TextAreaComponent;



  constructor(private solicitudServicio: RequestService, private tematicasServicio: TematicaService, private router: Router) {

  }
  async ngOnInit(): Promise<void> {
    await this.getTematicas();
    this.marcarInputsComoNoRellenos();
  }


  async getTematicas(): Promise<void> {
    this.tematicas = [...await this.tematicasServicio.getTematicasUsuarioSinPrivilegios()];
  }

  marcarInputsComoNoRellenos(): void {
    this.tipoDeSolicitudViewChild.marcarComoNoRelleno();
    this.solicitaViewChild.marcarComoNoRelleno();
    this.descripcionSolicitudViewChild.marcarComoNoRelleno();
  }


  async getTipoDeSolicitud(tematicaID: number | string): Promise<void> {
    const tematicaElegidaPorElUsuario: Tematica[] = await this.tematicasServicio.getTematica(
      (typeof tematicaID === 'string') ? parseInt(tematicaID) : tematicaID
    );

    this.solicitud.tematica = tematicaElegidaPorElUsuario[0];
    this.tipoDeSolicitudViewChild.marcarComoRelleno();
    this.setInformacionAdicionalTematica();
  }

  setInformacionAdicionalTematica(): void {
    this.informacionAdicionalTematica = this.tematicasServicio.getInformacionAdicionalTematica(this.solicitud.tematica.tematica)
  }

  setSolicitaSolicitud(solicitaSolicitud: string): void {
    this.solicitud.solicita = solicitaSolicitud;
  }

  setDescripcionSolicitud(descripcionSolicitud: string): void {
    this.solicitud.descripcion = descripcionSolicitud;

  }

  setCatastroSolicitud(catastroSolicitud: string): void {
    this.solicitud.referencia_catastral = catastroSolicitud;

  }

  getArchivoAdjuntoSolicitud(archivoAdjuntoSolicitud: File): void {
    if (!archivoAdjuntoSolicitud) return;
    this.solicitud.documentacion = archivoAdjuntoSolicitud.name;
    this.archivoSeleccionado = archivoAdjuntoSolicitud as File;


  }


  comprobarSiLosCamposEstanRellenos(): boolean {
    if (this.solicitud.descripcion === '') return false;
    if (!this.solicitud.tematica) return false;
    if (this.solicitud.tematica.id === -1) return false;
    if (this.solicitud.solicita === '') return false;
    return true;
  }

  /**TODO */

  async confirmarSolicitud(): Promise<void> {
    const confirmacionUsuario = await mostrarMensajeDeAlerta(`Nueva solicitud de tipo: ${this.solicitud.tematica.tematica}`, `Solicita: ${this.solicitud.solicita}`, 'info', 'Aceptar', 'Cancelar');
    if (!confirmacionUsuario) return;
    this.crearNuevaSolicitud();

  }

  async crearNuevaSolicitud(): Promise<void> {
    const respuesta: RespuestaSolicitudApi | null = await this.solicitudServicio.crearSolicitud(this.solicitud, this.archivoSeleccionado ? this.archivoSeleccionado : undefined);
    if (!respuesta || respuesta.status === 'error') {
      if (respuesta?.code === 422) await mostrarMensajeDeAlerta(`Error al crear la solicitud`, respuesta?.data.referencia_catastral['ovc'], 'warning', 'Aceptar', 'Cancelar', true, false);
      else await mostrarMensajeDeAlerta(`Error al crear la solicitud`, 'Contacte con el servicio técnico', 'warning', 'Aceptar', 'Cancelar', true, false);
      return;
    }
    else {
      await mostrarMensajeDeAlerta(`Solicitud creada con éxito`, '', 'success', 'Aceptar', 'Cancelar', true, false);
      this.router.navigate(['usuario']);


    }

    /*

    if (!this.archivoSeleccionado) {
      await mostrarMensajeDeAlerta(`Solicitud creada con éxito`, '', 'success', 'Aceptar', 'Cancelar', true, false);
      this.router.navigate(['usuario']);
    } else if (this.archivoSeleccionado) {
      let resp = await this.solicitudServicio.subirAdjuntoAUnaSolicitud(this.archivoSeleccionado, respuesta.data.id);
      if (resp) {
        await mostrarMensajeDeAlerta(`Solicitud creada con éxito`, '', 'success', 'Aceptar', 'Cancelar', true, false);

        this.router.navigate(['usuario']);
      }

    }*/


  }




}
