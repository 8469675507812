import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TEXTS } from 'src/app/helpers/texts';
import { AuthService } from 'src/app/services/auth.service';
import { NavegacionService } from 'src/app/services/navegacion.service';
import { openModal, closeModal } from 'src/app/tools/modal.tool';
import { parseURL } from 'src/app/tools/url.tool';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() isLoged: boolean = false;
  @Input() url: string = '';
  versionPro: boolean = false;


  public TEXTS: typeof TEXTS = TEXTS;


  constructor(private authService: AuthService, private router: Router, private navegacionServicio: NavegacionService) {

    this.router.events.subscribe(async (event) => {
      const url = await parseURL(this.url);
    });
  }



  ngOnInit(): void {
    this.versionPro = environment.version === 'pro';



  }

  ngAfterViewInit(): void {
    this.navegacionServicio.getUrlActual$().subscribe((url) => {
      this.actualizarLinkDondeSeEncuentraElUsuario(url)
    });
  }



  actualizarLinkDondeSeEncuentraElUsuario(url: string): void {

    let links = document.querySelectorAll('.main-link');
    links.forEach(link => {
      link.classList.remove('selected');
      if (`${link.id}` === `main-link-${url}`) {
        link.classList.add('selected')
      }
    });

  }

  openLoginModal(): void {
    openModal('loginModal');
  }




  logOut(): void {
    this.isLoged = false;
    this.authService.logOut();
  }

  closeLoginModal(): void {
    closeModal('loginModal');
  }



  async getAllHTMLElementsByClassName(className: string): Promise<NodeListOf<HTMLElement>> {
    return document.querySelectorAll(`.${className}`);
  }


  async removeClassFromHTMLElement(element: HTMLElement, className: string): Promise<void> {
    element.classList.remove(className);
  }

  async addClassFromHTMLElement(element: HTMLElement, classname: string): Promise<void> {
    element.classList.add(classname);

  }

  async toggleClassFromHTMLElement(element: HTMLElement, classname: string): Promise<void> {
    element.classList.toggle(classname);

  }

  async toggleSideBarMenu(): Promise<void> {
    let sidebar: HTMLElement = document.getElementById('sidebar') as HTMLElement;
    this.toggleClassFromHTMLElement(sidebar, 'open')
  }

  async hiddeSideBarMenu(): Promise<void> {
    let sidebar: HTMLElement = document.getElementById('sidebar') as HTMLElement;
    this.removeClassFromHTMLElement(sidebar, 'open')
  }

}
