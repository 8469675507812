import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, firstValueFrom, Observable } from 'rxjs';
import { Disponible } from '../models/disponible';
import { Global } from '../helpers/global';
import { Categoria_disponible } from '../models/categoria_disponible';
import { UsuarioLocalService } from './usuario-local.service';
import { RespuestaApi } from '../models/respuesta-api';

@Injectable({
  providedIn: 'root'
})
export class AvailableDocumentsService {

  constructor(private httpClient: HttpClient, private localUserService: UsuarioLocalService) { }

  public async getAvailableDocuments(): Promise<Disponible[]> {
    try {
      const url = Global.url + Global.URL_DISPONIBLES;

      const respuesta: Observable<{ data: Disponible[] }> = this.httpClient.get<{ data: Disponible[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  public async getCategoriasDocumentosDisponibles(): Promise<Categoria_disponible[]> {
    try {

      const url = Global.url + Global.URL_DISPONIBLES_CATEGORIAS;
      const usuario = await this.localUserService.getLocalUser();
      if (!usuario) {
        console.log('Error al intentar traer el listado de disponibles, no existe el usuario')
        //return Promise.reject('Error al intentar traer el listado de disponibles, no existe el usuario');

      }
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${usuario}`,
      });



      const respuesta: Observable<{ data: Categoria_disponible[] }> = this.httpClient.get<{ data: Categoria_disponible[] }>(url);
      const resultado = await lastValueFrom(respuesta) as RespuestaApi;
      if (resultado.status === 'success') {

        return this.filtrarDocumentosDisponibles(resultado.data as Categoria_disponible[])

      } else {
        console.log('Error al recibir los documentos disponibles');
        return []
      }
    }
    catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  filtrarDocumentosDisponibles(documentosDisponibles: Categoria_disponible[]): Categoria_disponible[] {
    return documentosDisponibles.filter(documentoDisponible => { return documentoDisponible.categoria !== 'Foros de aprendizaje' })
  }
}
