import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ComunicacionService } from 'src/app/servicios/comunicacion.service';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent<T> implements OnChanges, OnInit {


  @Input() text: string = '';
  @Input() options: string[] = [];
  @Input() isDisabled: boolean = false;
  @Input() currentValue: string = '';
  @Output() selectValueEmitter = new EventEmitter<string>();
  @Input() objetos: T[] = [];
  @Input() propiedadDelObjetoQueTieneElValor: keyof T | null = null;
  @Input() propiedadDelObjetoQueTieneElNombre: keyof T | null = null;
  @Input() blur: boolean = false;
  @Input() textoErrorNoCompletado: string = '';

  @ViewChild('select') select!: ElementRef;
  mostrarMensaje: boolean = false;


  constructor(private comunicacionServicio: ComunicacionService) {

  }
  ngOnInit(): void {
    this.comunicacionServicio.reset$.subscribe(() => {
      this.resetValue();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      this.selectValueEmitter.emit('')
    }

  }


  resetValue(): void {
    this.select.nativeElement.value = ''
    this.currentValue = '';
  }

  selectValue(value: string) {
    this.comprobarSiElCampoEstaCompletado()
    this.selectValueEmitter.emit(this.currentValue);
  }


  marcarComoNoRelleno(): void {
    this.select.nativeElement.classList.remove('verde');
    this.select.nativeElement.classList.add('rojo');
    this.mostrarMensaje = true;
  }

  marcarComoRelleno(): void {
    this.select.nativeElement.classList.remove('rojo');
    this.select.nativeElement.classList.add('verde');
    this.mostrarMensaje = false;

  }


  comprobarSiElCampoEstaCompletado(): void {
    if (!this.blur) return;

    if (this.select.nativeElement.value !== '') {
      this.marcarComoRelleno();
    }

    else {
      this.marcarComoNoRelleno();

    }
  }


  asignarValorAlSelect(value: any): void {
    this.currentValue = value;
    this.select.nativeElement.value = this.currentValue;
  }




}
