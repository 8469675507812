import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContactoEmail } from 'src/app/models/contacto-email';
import { Correo } from 'src/app/models/correo';
import { EmailService } from 'src/app/services/email.service';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { EmailSignalService } from 'src/app/signals/email.signal.service';
import { formatDateToDDMMYYYY } from 'src/app/tools/date.tool';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute, Router } from '@angular/router';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';

@Component({
  selector: 'app-lista-emails',
  templateUrl: './lista-emails.component.html',
  styleUrls: ['./lista-emails.component.css']
})
export class ListaEmailsComponent {

  @ViewChildren("inputcheckbox", { read: ElementRef }) checkboxes!: QueryList<ElementRef>;

  public Editor = ClassicEditor;
  public configEditor: any = {
    toolbar: {
      items: [

      ]

    }
  }


  contactosEmail: ContactoEmail[] = [];
  emails: Correo[] = [];
  emailsBack: Correo[] = [];
  emailSeleccionado: boolean = false;
  email: Correo = new Correo();

  seleccionadosParaEliminar: number[] = [];
  estanTodosSeleccionados: boolean = false;
  numeroDeCorreosNoLeidos: number = 0;

  usuarioActual: string = '';

  bandejaSeleccionada!: string;
  private subscription?: Subscription;
  private vistoSuscripcion?: Subscription;


  constructor(private emailServicio: EmailService, private usuarioLocalServicio: UsuarioLocalService, private emailComunicacionSignal: EmailSignalService,
    private router: Router, private route: ActivatedRoute
  ) {

    const state = history.state;
    if (state) {
      const value = state.bandejaSeleccionada;
      this.bandejaSeleccionada = value
    }


  }

  async ngOnInit(): Promise<void> {

    this.vistoSuscripcion = this.emailComunicacionSignal.emailVisto$.subscribe(async () => {
      await this.getEmails();
    })

    this.subscription = this.emailComunicacionSignal.bandejaSeleccionada$.subscribe(async bandejaSeleccionada => {
      this.bandejaSeleccionada = bandejaSeleccionada;
      await this.getEmailDepediendoDeLaBandejaSeleccionada();
    })

    await this.getEmails();
    this.usuarioActual = (await this.usuarioLocalServicio.getLocalUser())?.id || '';
    await this.getEmailDepediendoDeLaBandejaSeleccionada();
  }



  async getEmails(): Promise<void> {
    this.emails = (await this.emailServicio.getEmails());
    this.emailsBack = [... this.emails];
  }

  async getEmailDepediendoDeLaBandejaSeleccionada(): Promise<void> {
    this.emailSeleccionado = false;
    if (this.bandejaSeleccionada === 'enviados') this.getEmailsEnviados();
    else if (this.bandejaSeleccionada === 'papelera') this.getEmailsEliminados();
    else this.getCorreosRecibidos();

  }

  async getCorreosRecibidos(): Promise<void> {
    this.numeroDeCorreosNoLeidos = 0;
    await this.getTodosLosEmails();
    this.emails = this.emails.filter((email => {

      if (email.remitente_id !== this.usuarioActual && email.borrado_receptor === false) {
        if (!email.visto) this.numeroDeCorreosNoLeidos += 1;
        return true
      }
      return false;
    }))
    this.emailComunicacionSignal.emailsNoLeidos(this.numeroDeCorreosNoLeidos);

  }

  async getEmailsEnviados(): Promise<void> {
    await this.getTodosLosEmails();
    this.emails = this.emails.filter((email => {
      return email.remitente_id === this.usuarioActual && email.borrado_remitente === false
    }))

  }

  async getEmailsEliminados(): Promise<void> {
    await this.getTodosLosEmails();
    this.emails = this.emails.filter((email => {
      return email.borrado_receptor === true || email.borrado_remitente === true
    }))
  }

  async getTodosLosEmails(): Promise<void> {
    this.emails = [...this.emailsBack]
  }


  getEmail(email: Correo): void {
    this.emailSeleccionado = true;
    this.email = email;
    this.marcarComoLeido(email);
  }

  async marcarComoLeido(email: Correo): Promise<void> {

    await this.emailServicio.actualizarVistoEmail(email.id);
    this.emailComunicacionSignal.actualizarVistoEmail();

  }

  /** ELIMINADA FUNCIONALIDAD POR PARTE DEL JEFE DE PROYECTO
    async eliminarEmail(): Promise<void> {
      if (this.seleccionadosParaEliminar.length < 1) return
      const respuestaEliminarCorreo = await this.emailServicio.deleteEmail(this.seleccionadosParaEliminar, this.bandejaSeleccionada === 'enviados' ? true : false)
  
      if (respuestaEliminarCorreo) {
        await mostrarMensajeDeAlerta('Éxito', 'Correo eliminado correctamente', 'success', 'Aceptar', '', true, false);
      }
      else {
        await mostrarMensajeDeAlerta('Error', 'No se ha podido eliminar el correo', 'error', 'Aceptar', '', true, false);
      }
  
      await this.getEmails();
      await this.getEmailDepediendoDeLaBandejaSeleccionada();
  
    }
   */

  async eliminarEmail(emailID: number): Promise<void> {
    const preguntaEliminarCorreo = await mostrarMensajeDeAlerta('Advertencia', '¿Está seguro/a de que desea eliminar el correo?', 'info', 'Aceptar', 'Cancelar', true, true)
    if (!preguntaEliminarCorreo) return;


    const respuestaEliminarCorreo = await this.emailServicio.deleteEmail(emailID, this.bandejaSeleccionada === 'enviados' ? true : false)


    if (respuestaEliminarCorreo) {
      await mostrarMensajeDeAlerta('Éxito', 'Correo eliminado correctamente', 'success', 'Aceptar', '', true, false);
    }
    else {
      await mostrarMensajeDeAlerta('Error', 'No se ha podido eliminar el correo', 'error', 'Aceptar', '', true, false);
    }

    await this.getEmails();
    await this.getEmailDepediendoDeLaBandejaSeleccionada();

  }




  /** ELIMINADA LA FUNCIONALIDAD POR PARTE DE JEFE DE PROYECTO
  
    seleccionarTodosLosCorreos(): void {
      this.estanTodosSeleccionados = !this.estanTodosSeleccionados;
      this.seleccionadosParaEliminar = [];
      this.checkboxes.forEach(checkbox => {
        if (this.estanTodosSeleccionados) {
          checkbox.nativeElement.checked = true
          this.seleccionadosParaEliminar = [parseInt(checkbox.nativeElement.id), ...this.seleccionadosParaEliminar]
        }
  
        else
          checkbox.nativeElement.checked = false
      });
  
      console.log(`Todos seleccionados para eliminar: ${this.seleccionadosParaEliminar}`);
    }
   */



  async filtrarCorreos(filtroInput: any) {
    const filtro = filtroInput.target.value as string;

    //this.emails = [... this.emailsBack];
    await this.getEmailDepediendoDeLaBandejaSeleccionada();
    if (filtro === '') {
      return;
    }
    this.emails = [...this.emails.filter((email => {
      return (email.asunto.toLowerCase()).includes(filtro.toLowerCase()) || (email.remitente.nombre.toLowerCase()).includes(filtro.toLowerCase());
    }))]

  }

  /* FUNCIONALIDAD ELIMINADA POR EL JEFE DE PROYECTO 

  seleccionarCorreo(id: number): void {

    if (this.seleccionadosParaEliminar.includes(id)) {
      this.seleccionadosParaEliminar = [...this.seleccionadosParaEliminar.filter((idAlmacenada => {
        return idAlmacenada !== id
      }))]
    } else {
      this.seleccionadosParaEliminar = [id, ...this.seleccionadosParaEliminar]

    }
    console.log(`Todos seleccionados para eliminar: ${this.seleccionadosParaEliminar}`);
  }
*/



  parsearFecha(fecha: Date): string {
    return formatDateToDDMMYYYY(fecha);
  }


}
