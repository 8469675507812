<div class="sidebar-content" id="sidebar">
    <section class="sidebar-user" [routerLink]="['usuario']" (click)="hiddeSideBarMenu()">
        <div class="sidebar-user-icon main-link" id="icono-usuario">
        </div>
        <p class="sidebar-user-name">{{this.userName}}</p>
        <p class="sidebar-user-name">MI CUENTA DE AOT</p>
        <div class="divider"></div>

    </section>

    <section class="sidebar-links">
        <a [routerLink]="['presentacion']" class="main-link sidebarlink onlymobile" id="main-link-presentacion"
            (click)="hiddeSideBarMenu()">Presentación</a>
        <a [routerLink]="['quienes-somos']" class="main-link sidebarlink" id="main-link-quienes-somos"
            (click)="hiddeSideBarMenu()">Programa AOT y equipo</a>
        <a [routerLink]="['documentos-disponibles']" class="main-link sidebarlink" id="main-link-documentos-disponibles"
            (click)="hiddeSideBarMenu()">Documentos disponibles</a>
        <a [routerLink]="['solicitud']" class="main-link sidebarlink" (click)="hiddeSideBarMenu()"
            id="main-link-solicitud">Nueva solicitud</a>
        <a [routerLink]="['foros']" class="main-link sidebarlink" (click)="hiddeSideBarMenu()"
            id="main-link-foros ">Foros de aprendizaje</a>
        <a id="main-link-concienciacion-ciudadana" [routerLink]="['concienciacion-ciudadana']"
            [ngClass]="{ 'disabled-link': versionPro}" class="main-link sidebarlink"
            (click)="hiddeSideBarMenu()">Materias transversales</a>
        <a id="main-link-newsletter" [routerLink]="['newsletter']" [ngClass]="{ 'disabled-link': versionPro}"
            class="main-link sidebarlink onlydesktop" (click)="hiddeSideBarMenu()">Newsletter</a>
        <a id="main-link-subvenciones" [routerLink]="['subvenciones']" [ngClass]="{ 'disabled-link': versionPro}"
            class="main-link sidebarlink  onlydesktop" (click)="hiddeSideBarMenu()">Subvenciones</a>
        <a id="main-link-subvenciones" [ngClass]="{ 'disabled-link': versionPro}"
            class="main-link sidebarlink onlydesktop" (click)="hiddeSideBarMenu()">Sentencias de
            interés</a>
        <a id="main-link-encuestas" [ngClass]="{ 'disabled-link': versionPro}"
            class="main-link sidebarlink  onlydesktop" (click)="hiddeSideBarMenu()">Informes
            OCJ
            <span class="tooltip-text">Informes de la Oficina de Consulta Jurídica</span>
        </a>
        <a id="main-link-encuestas" class="main-link sidebarlink  onlydesktop"
            [ngClass]="{ 'disabled-link': versionPro}" (click)="hiddeSideBarMenu()">Encuestas</a>




        <div class="divider onlymobile disabled-link"></div>
        <a href="" class="main-link sidebarlink onlymobile disabled-link" (click)="hiddeSideBarMenu()">Contacto AOT</a>
        <a href="" class="main-link sidebarlink onlymobile disabled-link" (click)="hiddeSideBarMenu()">Correo</a>
        <a href="" class="main-link sidebarlink onlymobile disabled-link" (click)="hiddeSideBarMenu()">Noticias</a>
        <a href="" class="main-link sidebarlink onlymobile disabled-link" (click)="hiddeSideBarMenu()">Ayuda AOT</a>

    </section>



</div>