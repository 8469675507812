<div class="available-documents">
    <div class="available-documents-search">
        <h2>Relación de documentos disponibles en AOT</h2>

    </div>
    <p>Los documentos disponibles son aquellos modelos de documentos que tenemos elaborados para que puedas solicitar.
        Para
        ello selecciona todos aquellos que sean de tu interés y los recibirás lo antes posible.</p>
    <p>Los documentos nuevos o actualizados están señalizados con la etiqueta “nuevo” y resumidos en el filtro
        “Últimos
        documentos publicados”.</p>
    <p>Si necesitas algún otro modelo relacionado con el urbanismo o el territorio no contenido en el listado,
        solicítalo en el
        menú de solicitudes\modelo y procederemos a su redacción.
    </p>
    <div class="available-documents-main-content">
        <div class="available-documents-main-content-filters">
            <div class="available-documents-main-content-filters-header" (click)="selectDocument($event)">
                <h3>Tipos de documentos disponibles</h3>
            </div>
            <div class="available-documents-main-content-filters-header-mobile onlymobile"
                (click)="showAvailableDocumentsFilters()">
                Tipos de documentos disponibles
            </div>
            <div class="available-documents-main-content-filters-list"
                id="available-documents-main-content-filters-list">
                <a (click)="selectDocument($event)" id="{{categoriaDocumentoDisponible.categoria}}"
                    class="secondary-link documentModels"
                    *ngFor="let categoriaDocumentoDisponible of categoriasDocumentosDisponibles">{{categoriaDocumentoDisponible.categoria}}</a>

            </div>


        </div>

        <div class="available-documents-main-content-results">
            <div class="available-documents-main-content-results-searchbar">
                <input type="search" placeholder="Buscar en los documentos disponibles" [(ngModel)]="searchPattern"
                    (ngModelChange)="searchDocument(searchPattern = $event)">
                <!-- <div class="buttons">


                    <app-radio [radioValues]="['Todos los disponibles', 'Últimos publicados']"
                        [radioName]="'filtroDcumentosDisponibles'" (radioValueEmmiter)="radioFilterButtonValue($event)"
                        [reset]="availableDocumentsRadioReset"></app-radio>


                </div>-->
            </div>
            <p class="available-documents-main-content-results-header">
                {{selectedModel}}
            </p>
            <div class="available-documents-main-content-results-documents">

                <p *ngIf="documentListFiltered.length === 0">No se ha encontrado ningún documento con esas
                    características</p>
                <ul *ngFor="let documentoDisponible of documentListFiltered ; let index = index">
                    <li class="available-document" id="{{index + 1}}" *ngIf="documentListFiltered.length !== 0"
                        (click)="requestAvailableDocument($event, documentoDisponible)">{{index
                        + 1}}.
                        {{documentoDisponible.disponible}}
                        <span *ngIf="documentoDisponible.nuevo">Nuevo</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>