export class Categoria_disponible {
    constructor(
        public categoria: string,
        public estado: boolean,
        public fecha: Date,
        public id: number,
        public imagen: string,
        public imagen_detalle: string,
        public orden: number,
        public subcategoria_id: number,
        public linea_id: number
    ) { }
}