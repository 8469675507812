import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Register } from '../models/register';
import { RespuestaApi } from '../models/respuesta-api';
import { REGISTRO } from '../helpers/global';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  public async registerUser(registro: Register): Promise<RespuestaApi> {
    const resp: RespuestaApi = await lastValueFrom(this.http.post(REGISTRO.URL_REGISTRO + `isla_id=${registro.isla_id}` + `&provincia_id=${registro.provincia_id}`, registro)) as RespuestaApi;
    return resp;
  }


  public async updateUser(registro: Register, usuario_id: string): Promise<RespuestaApi> {
    const resp: RespuestaApi = await lastValueFrom(this.http.put(REGISTRO.URL_ACTUALIZACION + usuario_id + `?isla_id=${registro.isla_id}` + `&provincia_id=${registro.provincia_id}`, registro)) as RespuestaApi;

    /** TODO - DEBERÍA DE TENER UN MODELO PARA LA RESPUESTA */
    return resp
  }


  public async borrarUsuario(usuario_id: string): Promise<RespuestaApi> {

    const resp: RespuestaApi = await lastValueFrom(this.http.post(REGISTRO.URL_BORRAR + usuario_id, {})) as RespuestaApi;
    return resp;

  }

}
