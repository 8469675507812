export const openModal = (modalID: string) => {
    let modal = document.getElementById(modalID);
    modal!.style.display = "flex";
}

export const closeModal = (modalID: string) => {
    let modal = document.getElementById(modalID);
    let span = document.getElementsByClassName("close")[0] as HTMLElement;
    modal!.style.display = "none";

}
