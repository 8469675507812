<div class="user">

    <div class="user-menu" id="user-menu">
        <a class="user-menu-link selected" id="requests" (click)="changeContent($event)">Solicitudes realizadas</a>
        <a class="user-menu-link" id="forums">Asistencia a foros de
            aprendizaje</a>
        <a class="user-menu-link " id="account" (click)="changeContent($event)">Mis datos</a>
    </div>
    <div class="user-content">
        <div class="user-section user-requests" *ngIf="showOption === 'requests'">
            <div class="user-requests-filter-button-mobile onlymobile" *ngIf="comprobarSiExistenSolicitudes">
                <img src="../../../assets/images/filtro.svg" alt="" (click)="mostrarFiltrosEnMovil()">
            </div>

            <div class="user-requests-filters onlydesktop" *ngIf="comprobarSiExistenSolicitudes">
                <div class="user-requests-filters-top">
                    <div class="user-requests-filters-header">
                        <p>Filtros</p>
                    </div>
                    <div class="user-requests-filters-content">
                        <app-select [text]="'Tipo de solicitud'" [objetos]="this.tematicas"
                            [propiedadDelObjetoQueTieneElValor]="'id'" [propiedadDelObjetoQueTieneElNombre]="'tematica'"
                            (selectValueEmitter)="actualizarFiltroDeTematica($event)"></app-select>
                        <div class="user-requests-filters-content-date">
                            <label for="fechaInicio">Fecha inicio</label>
                            <input type="date" (change)="actualizarFiltroDeFechaInicio(fechaInicio.value)" #fechaInicio
                                name="fechaInicio" id="fechaInicio">
                            <label for="fechaInicio">Fecha fin</label>

                            <input type="date" (change)="actualizarFiltroDeFechaFin(fechaFin.value)" #fechaFin
                                name="fechaFin" id="fechaFin">
                        </div>
                        <!-- <app-checkbox [checkboxValue]="4" [checkboxName]="'Pendiente de descarga'"
                            (checkBoxSeleccionadoEmitter)="actualizarFiltroPendienteDeDescarga($event)"></app-checkbox>-->
                    </div>
                </div>
                <div class="user-requests-filters-bottom">
                    <button class="secondary-button" (click)="limpiarTodosLosFiltros()">Limpiar filtros</button>
                </div>
            </div>
            <div class="user-requests-filters onlymobile" *ngIf="comprobarSiExistenSolicitudes" id="filtro-solicitudes">
                <div class="user-requests-filters-top">
                    <div class="user-requests-filters-header">
                        <p>Filtros</p>
                    </div>
                    <div class="user-requests-filters-content">
                        <input type="search" placeholder="Buscar" [(ngModel)]="patronDeBusqueda"
                            (ngModelChange)="buscarSolicitud(patronDeBusqueda = $event)">
                        <app-select [text]="'Tipo de solicitud'" [objetos]="this.tematicas"
                            [propiedadDelObjetoQueTieneElValor]="'id'" [propiedadDelObjetoQueTieneElNombre]="'tematica'"
                            (selectValueEmitter)="actualizarFiltroDeTematica($event)"></app-select>
                        <app-select [text]="'Estado de la solicitud'" [objetos]="estados"
                            [propiedadDelObjetoQueTieneElValor]="'id'" [propiedadDelObjetoQueTieneElNombre]="'estado'"
                            (selectValueEmitter)="actualizarFiltroDeEstado($event)"></app-select>
                        <div class="user-requests-filters-content-date">
                            <label for="fechaInicio">Fecha inicio</label>
                            <input type="date" (change)="actualizarFiltroDeFechaInicio(fechaInicio.value)" #fechaInicio
                                name="fechaInicio" id="fechaInicio">
                            <label for="fechaInicio">Fecha fin</label>

                            <input type="date" (change)="actualizarFiltroDeFechaFin(fechaFin.value)" #fechaFin
                                name="fechaFin" id="fechaFin">

                            <div class="user-requests-filters-bottom">
                                <button class="secondary-button" (click)="limpiarTodosLosFiltros()">Limpiar
                                    filtros</button>
                            </div>
                        </div>

                    </div>
                </div>



            </div>
            <div class="user-requests-list-no-solicitudes" *ngIf="!comprobarSiExistenSolicitudes">
                <strong>No existen solicitudes</strong>
            </div>
            <div class="user-requests-list" *ngIf="comprobarSiExistenSolicitudes">
                <div class="user-requests-list-header">
                    <div class="user-requests-list-header-searchbar">
                        <input type="search" placeholder="Buscar" [(ngModel)]="patronDeBusqueda"
                            (ngModelChange)="buscarSolicitud(patronDeBusqueda = $event)">
                        <div class="buttons">
                            <strong>Estado de solicitudes:</strong>

                            <app-radio [objetos]="estados" [propiedadDelObjetoQueTieneElNombre]="'estado'"
                                [propiedadDelObjetoQueTieneElValor]="'id'" [radioName]="'estados'"
                                [propiedadDelObjetoQueTieneElColor]="'color'"
                                (radioValueEmmiter)="actualizarFiltroDeEstado($event)" [valorPorDefecto]="-1">
                            </app-radio>


                        </div>
                    </div>
                </div>


                <div class="user-requests-list-content">
                    <h1 *ngIf="this.cargando">Cargando datos ... por favor espere
                    </h1>
                    <app-user-request *ngFor="let solicitud of aplicarFiltroAlArrayDeSolicitudes()"
                        [solicitud]="solicitud"></app-user-request>
                </div>
            </div>
        </div>
        <div class="user-section user-forums" *ngIf="showOption === 'forums'">
            <div class="user-forums-filters">
                <div class="user-forums-filters-top">
                    <div class="user-forums-filters-header">
                        <p>Filtros</p>
                    </div>
                    <div class="user-forums-filters-content">
                        <app-select [text]="'Fecha'" [options]="['']"></app-select>
                        <app-select [text]="''" [options]="['']"></app-select>
                        <app-radio [radioValues]="['Pendientes de descarga']"></app-radio>

                    </div>
                </div>
                <div class="user-forums-filters-bottom">
                    <button class="secondary-button" (click)="limpiarTodosLosFiltros()">Limpiar filtros</button>
                </div>


            </div>
            <div class="user-forums-list">
                <div class="user-forums-list-header">
                    <div class="user-forums-list-header-searchbar">
                        <input type="search" placeholder="Buscar">
                    </div>
                </div>
                <div class="user-forums-list-content">
                    <app-user-forum [forumName]="'Herramientas Alternativas de Modificación Urbanística'"
                        [forumDate]="'19/02/2019'" [forumIMG]="'../../../assets/images/forum1.png'"></app-user-forum>
                    <app-user-forum [forumName]="'El Órgano Ambiental'" [forumDate]="'12/04/2023'"
                        [forumStatus]="'finalizada'" [forumIMG]="'../../../assets/images/forum2.png'"></app-user-forum>

                </div>
            </div>
        </div>
        <div class="user-section user-account" *ngIf="showOption === 'account'">
            <app-register [size]="100" [usuarioLogeado]="true" [mostrarTitulo]="false"></app-register>
        </div>
    </div>

</div>