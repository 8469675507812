import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ComunicacionService } from 'src/app/servicios/comunicacion.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent<T> implements OnInit {

  @Input() checkboxValue: string | number = '';
  @Input() checkboxName: string = '';
  @Input() checkboxTextColor: string = 'red';
  @Output() checkboxValueEmmiter = new EventEmitter<boolean>();
  @Output() checkboxValorEmmiter = new EventEmitter<string | number>();
  @Output() checkBoxSeleccionadoEmitter = new EventEmitter<boolean>();
  checked = false;

  @ViewChild('checkbox') checkbox!: ElementRef;


  constructor(private comunicacionServicio: ComunicacionService) {
  }

  ngOnInit(): void {
    this.comunicacionServicio.reset$.subscribe(() => {
      this.resetValue();
    })
  }

  checkBoxValueChanged(): void {

    this.checkBoxSeleccionadoEmitterValue(this.checked);
    /*
        let checkbox: HTMLInputElement = document.getElementById(this.checkboxName) as HTMLInputElement;
        if (checkbox && !checkbox.checked) {
          this.checkboxValorEmmiter.emit(-1);
    
        } else {
          this.checkboxValueEmmiter.emit(this.checked);
          this.checkboxValorEmmiter.emit(this.checkboxValue);
        }*/

  }

  /*Emitimos verdadero o falso dependiendo de si está seleccionado o no el checkbox*/
  checkBoxSeleccionadoEmitterValue(valor: boolean): void {
    console.log(valor)
    this.checkBoxSeleccionadoEmitter.emit(valor);
  }

  resetValue(): void {
    let checkbox: HTMLInputElement = document.getElementById(this.checkboxName) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = false;
      checkbox.value = '';
    }
  }


  marcarComoNoRelleno(): void {
    this.checkbox.nativeElement.classList.add('rojo');

  }

  marcarComoRelleno(): void {
    this.checkbox.nativeElement.classList.remove('rojo');

  }



}
