import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { CorreoExterno } from 'src/app/models/correoExterno';
import { EmailService } from 'src/app/services/email.service';
import { emailPatron } from 'src/app/tools/email.tool';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(private readonly fb: FormBuilder,
    private emailService: EmailService
  ) { }

  contactoForm!: FormGroup;

  ngOnInit(): void {
    this.contactoForm = this.initForm();
  }

  initForm(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailPatron())]],
      remitente: ['', Validators.required,],
      mensaje: ['', Validators.required],
      provincia: ['', Validators.required]

    })

  }

  async onSubmit(): Promise<void> {

    Object.values(this.contactoForm.controls).forEach(control => {
      control.markAsTouched();
    });

    if (this.contactoForm.valid) {
      const emailContacto: CorreoExterno = this.contactoForm.value as CorreoExterno;
      const respuesta = await this.emailService.enviarEmailExterno(emailContacto)
      this.contactoForm.reset();

      if (respuesta) {
        mostrarMensajeDeAlerta('Correo enviado correctamente', 'Le responderemos en la mayor brevedad posible', 'success', 'Aceptar', '', true, false)
      } else {
        mostrarMensajeDeAlerta('Error', 'No se ha podido enviar su correo', 'warning', 'Aceptar', '', true, false)

      }

    }

  }

}
