import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMAIL } from '../helpers/global';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { ContactoEmail } from '../models/contacto-email';
import { UsuarioLocalService } from './usuario-local.service';
import { Correo } from '../models/correo';
import { RespuestaApi } from '../models/respuesta-api';
import { NuevoCorreo } from '../models/nuevo-correo';
import { CorreoExterno } from '../models/correoExterno';
import { CorreoAdjunto } from '../models/correo-adjunto';
import { normalizarNombreApellidos } from '../tools/string.tool';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private httpClient: HttpClient, private localUserService: UsuarioLocalService) { }


  async getEmails(): Promise<any[]> {
    const usuario = await this.localUserService.getLocalUser();

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`,
    });

    try {

      const respuesta: Observable<{ data: Correo[] }> = this.httpClient.get<{ data: Correo[] }>(EMAIL.URL_EMAILS + EMAIL.ORDENAR_EMAILS, { headers });
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      return [];
    }
  }

  async getEmail(idEmail: number): Promise<Correo | undefined> {
    const usuario = await this.localUserService.getLocalUser();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`,
    });

    try {
      const url = EMAIL.URL_EMAILS + idEmail + EMAIL.ORDENAR_EMAILS

      const respuesta: Observable<{ data: Correo }> = this.httpClient.get<{ data: Correo }>(url, { headers });
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      return;
    }
  }

  async actualizarVistoEmail(idEmail: number): Promise<Correo> {
    const usuario = await this.localUserService.getLocalUser();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`
    });

    try {
      const url = EMAIL.URL_EMAILS + idEmail
      const respuesta: RespuestaApi<Correo> = await lastValueFrom(this.httpClient.put(url, { 'visto': 1 }, { headers })) as RespuestaApi<Correo>;

      if (respuesta.status === 'error') Promise.reject(respuesta);

      return Promise.resolve(respuesta.data as Correo)

    } catch (error) {
      return Promise.reject(error)
    }

  }


  async getEmailAdjunto(idEmail: number): Promise<CorreoAdjunto[]> {
    const usuario = await this.localUserService.getLocalUser();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`,
    });

    try {
      const url = EMAIL.URL_EMAILS + idEmail + EMAIL.ADJUNTOS_EMAILS

      const respuesta: Observable<{ data: CorreoAdjunto[] }> = this.httpClient.get<{ data: CorreoAdjunto[] }>(url, { headers });
      const resultado = await lastValueFrom(respuesta);
      return Promise.resolve(resultado.data);
    } catch (error) {
      return Promise.reject([]);
    }

  }

  async getContactosEmail(emailContacto?: string): Promise<ContactoEmail[]> {
    try {
      const url = emailContacto
        ? EMAIL.URL_CONTACTO + emailContacto
        : EMAIL.URL_CONTACTO

      const respuesta: Observable<{ data: ContactoEmail[] }> = this.httpClient.get<{ data: ContactoEmail[] }>(url);
      let { data } = await firstValueFrom(respuesta);

      const contactosNormalizados = data.map(contacto => ({
        ...contacto,
        nombre: normalizarNombreApellidos(contacto.nombre),
        apellidos: normalizarNombreApellidos(contacto.apellidos),

      }))

      return contactosNormalizados.filter(contacto => {
        return contacto.role === 'supervisor' || contacto.role === 'user'
      })

    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }




  async enviarEmail(email: NuevoCorreo): Promise<boolean | null> {
    const usuario = await this.localUserService.getLocalUser();
    let formData: FormData = new FormData();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });


    formData.append('receptor_id', email.receptor_id.toString());
    formData.append('correo', email.correo);
    formData.append('asunto', email.asunto);

    email.correoAdjuntos.forEach(correoAdjunto => {
      formData.append('correo_adjuntos[][adjunto]', correoAdjunto)
    })

    try {
      const respuesta = await lastValueFrom(this.httpClient.post(EMAIL.URL_EMAILS, formData, { headers })) as RespuestaApi;
      console.log(respuesta)
      if (respuesta.status === 'success') return true;
      else return false;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return false;
    }


  }

  /** ELIMINADA FUNCIONALIDAD POR PARTE DEL JEFE DE PROYECTO
    async deleteEmail(emailID: number[], soyRemitente: boolean): Promise<boolean | null> {
      const usuario = await this.localUserService.getLocalUser();
      if (!usuario) return null;
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${usuario}`,
      });
  
      const remitente_o_Receptor = soyRemitente === true ? { 'borrado_remitente': 1 } : { 'borrado_receptor': 1 };
  
      try {
  
  
        const respuestasAPIBorradoCorreos = emailID.map(id =>
          lastValueFrom(this.httpClient.put<RespuestaApi>(`${EMAIL.URL_EMAILS}${id}`, remitente_o_Receptor, { headers }))
        );
  
        const respuestas = await Promise.all(respuestasAPIBorradoCorreos);
  
        const todasExitosas = respuestas.every(respuesta => respuesta.status === 'success');
  
        respuestas.forEach(respuesta => console.log(`Repuesta eliminar correos: ${respuesta.status}, ${respuesta.message}`));
  
        return todasExitosas;
      }
      catch (error) {
        console.error('Error en la solicitud HTTP de eliminar email', error);
        return false;
      }
    }
   */


  async deleteEmail(emailID: number, soyRemitente: boolean): Promise<boolean | null> {
    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const remitente_o_Receptor = soyRemitente === true ? { 'borrado_remitente': 1 } : { 'borrado_receptor': 1 };

    try {


      const respuestaAPIBorradoCorreo = await lastValueFrom(this.httpClient.put<RespuestaApi>(`${EMAIL.URL_EMAILS}${emailID}`, remitente_o_Receptor, { headers }))
      console.log(`Repuesta eliminar correos: ${respuestaAPIBorradoCorreo.status}, ${respuestaAPIBorradoCorreo.message}`)
      if (respuestaAPIBorradoCorreo.status === 'success') { return true }
      return false;
    }
    catch (error) {
      console.error('Error en la solicitud HTTP de eliminar email', error);
      return false;
    }
  }


  async enviarEmailExterno(emailExterno: CorreoExterno): Promise<boolean | null> {

    console.log(emailExterno)

    let formData: FormData = new FormData();

    formData.append('email', emailExterno.email);
    formData.append('remitente', emailExterno.remitente);
    formData.append('provincia', emailExterno.provincia);
    formData.append('mensaje', emailExterno.mensaje);

    try {
      const respuesta = await lastValueFrom(this.httpClient.post(EMAIL.CORREO_EXTERNO, formData)) as RespuestaApi;
      console.log(respuesta)
      if (respuesta.status === 'success') return true;
      else return false;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return false;
    }


  }

}
