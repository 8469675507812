import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestSignalService {
  private requestSubject = new Subject<void>();

  requestList$ = this.requestSubject.asObservable();

  enviarCambiosRequest(): void {
    this.requestSubject.next();
  }

  constructor() { }
}
