import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject, filter, finalize, shareReplay, switchMap, take, throwError } from 'rxjs';
import { Global } from '../helpers/global';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();
  private readonly urlsToSkip: string[] = [
    '/auth/jwt_login',
    '/auth/register',
    '/auth/reset_password',
    '/auth/reset_password_request',
    '/auth/validate_account',
    '/auth/validate_account_request',
    '/api/cargos',
    '/api/tematicas',
    '/api/provincias',
    '/api/profesiones',
    '/api/corporaciones',
    '/api/secciones',
    '/api/islas',
    '/api/municipios',
    '/utils/contacto',

  ];

  public jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldSkip(request.url)) return next.handle(request);

    const token = !request.url.includes("jwt_refresh") ? sessionStorage.getItem(Global.ACCESS_TOKEN) : localStorage.getItem(Global.REFRESH_TOKEN);
    const isExpired = !request.url.includes('jwt_refresh') ? this.jwtHelper.isTokenExpired(token) : false;
    const isApiUrl = request.url.startsWith(Global.url);
    if (token && isApiUrl && !isExpired) {
      request = this.addToken(request, token);
      return next.handle(request);
    } else {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);

        return this.authService.refreshAccessToken().pipe(
          switchMap(() => {
            const newToken = sessionStorage.getItem(Global.ACCESS_TOKEN);
            if (newToken) {
              request = this.addToken(request, newToken);
              return next.handle(request);
            } else {
              return throwError(() => new Error('No new token found'));
            }
          }),
          finalize(() => {
            this.refreshTokenInProgress = false;
          }),
          shareReplay()
        );
      } else {
        this.authService.setLoggedIn(false);
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap(() => this.intercept(request, next))
        );
      }
    }
  }

  private shouldSkip(url: string): boolean {
    return this.urlsToSkip.some((urlToSkip) => url.includes(urlToSkip));
  }

  private addToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
