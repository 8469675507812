<!-- Trigger/Open The Modal -->

<!-- The Modal -->
<div id="loginModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <h1>Iniciar sesión<strong (click)="closeModal()">X</strong></h1>

        <app-login></app-login>
    </div>

</div>