import { Usuario } from "./usuario";

export class Correo {
    constructor(
        public id: number = -1,
        public asunto: string = '',
        public correo: string = '',
        public fecha: Date = new Date(),
        public estado: number = -1,
        public remitente_id: string = '',
        public receptor_id: string = '',
        public visto: boolean = false,
        public receptor: Usuario = new Usuario(),
        public remitente: Usuario = new Usuario(),
        public borrado_receptor: boolean = false,
        public borrado_remitente: boolean = false

    ) { }
}