import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Seccion } from '../models/seccion';
import { SECCIONES } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeccionService {

  constructor(private httpClient: HttpClient) { }


  async getSeccion(codigoSeccion?: number): Promise<Seccion[]> {
    try {
      const url = codigoSeccion
        ? SECCIONES.URL + codigoSeccion
        : SECCIONES.URL;

      const respuesta: Observable<{ data: Seccion | Seccion[] }> = this.httpClient.get<{ data: Seccion | Seccion[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      // Si la respuesta es un solo objeto, envuélvelo en un array
      const cargos: Seccion[] = Array.isArray(resultado.data) ? resultado.data : [resultado.data];

      return new Promise((resolve) => {
        resolve(cargos);
      });
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  getNombreSecciones(secciones: Seccion[]): string[] {
    return secciones
      .filter(secciones => secciones.seccion !== 'Seleccione sección')
      .map(secciones => secciones.seccion);
  }
}
