<div class="login-form">
  <form class="form" [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="!resetMode">
    <div class="input-group mb-3 has-validation">
      <input type="email" class="aot-login-input email" formControlName="email"
        [ngClass]="{ ' is-invalid': submitted && f['email'].errors }" [placeholder]="TEXTS.CORREO" email>
      <div class="invalid-feedback" *ngIf="submitted && f['email'].errors">
        Este campo es obligatorio y de tipo email
      </div>
    </div>
    <div class="input-group mb-3">
      <input type="password" formControlName="password" class="aot-login-input password" id="password"
        [ngClass]="{ ' is-invalid': submitted && f['password'].errors }" [placeholder]="TEXTS.PASSWORD" required>
      <img src="../../../assets/images/ojo.svg" id="eye" (click)="mostrarPasswordIntroducidoPorElUsuario()">
      <div class="invalid-feedback" *ngIf="submitted && f['password'].errors">
        Este campo es obligatorio
      </div>
    </div>
    <div class="row justify-content-center login-buttons">

      <button type="submit" class="primary-button" [routerLink]="['/presentacion']">{{TEXTS.ACCEDER}}</button>
      <div class="remember-password">
        <hr>
        <p class="remember-password-text" (click)="cambiarModoDeRecuperacionDePassword()"> Olvidé la contraseña</p>
        <hr>
      </div>


    </div>
  </form>

  <form class="form" [formGroup]="resetForm" (ngSubmit)="resetPassword()" *ngIf="resetMode">
    <div class="input-group mb-3 has-validation">
      <input type="email" class="aot-login-input email" formControlName="reference"
        [ngClass]="{ ' is-invalid': submitted && ff['reference'].errors }" [placeholder]="TEXTS.CORREO" email>
      <div class="invalid-feedback" *ngIf="submitted && ff['reference'].errors">
        Este campo es obligatorio y de tipo email
      </div>
    </div>
    <div class="input-group mb-3 has-validation hidden">
      <input type="email" class="aot-login-input email">
    </div>
    <div class="row justify-content-center login-buttons">

      <button class="primary-button" type="submit"
        [routerLink]="['/presentacion']">{{TEXTS.RECUPERAR_PASSWORD}}</button>

      <div class="remember-password">
        <hr>
        <p class="remember-password-text" (click)="cambiarModoDeRecuperacionDePassword()"> Iniciar sesión</p>
        <hr>
      </div>

    </div>
  </form>
  <button class="secondary-button" (click)="registrarNuevoUsuario()">Registrarse</button>

</div>