import { Estado } from "./estado";
import { Expediente } from "./expediente";
import { SolicitudApi } from "./solicitud-api";
import { Tematica } from "./tematica";
import { Usuario } from "./usuario";

export class Solicitud {
    constructor(
        public id: number = 0,
        public estado_id: number = 1,
        public usuario: Usuario = new Usuario(),
        public usuario_id: string = '',
        public solicita: string = '',
        public descripcion: string = '',
        public documentacion: string = '',
        public fecha: Date = new Date(),
        public estado: Estado = new Estado(),
        public tematica: Tematica = new Tematica(),
        public tematica_id: number = -1,
        public referencia_catastral: string = '',
        public expediente: Expediente = new Expediente()
    ) { }




    parsearSolicitudParaLaAPI(idUsuario: string): SolicitudApi {
        return {
            descripcion: this.descripcion,
            usuario_id: idUsuario,
            documentacion: this.documentacion,
            estado_id: this.estado.id,
            solicita: this.solicita,
            tematica_id: this.tematica.id,
            referencia_catastral: this.referencia_catastral
        } as SolicitudApi
    }
}