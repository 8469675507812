import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Solicitud } from 'src/app/models/solicitud';
import { formatDateToDDMMYYYY } from '../../tools/date.tool';
import { RequestService } from 'src/app/services/request.service';
import { ExpedienteService } from 'src/app/services/expediente.service';
import { MensajeExpediente } from 'src/app/models/mensaje-expediente';
import { InputComponent } from '../input/input.component';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { RequestSignalService } from 'src/app/signals/request.signal.service';
import { cerrarMensaje, mostrarMensajeDeAlerta, mostrarMensajeDeCarga } from 'src/app/helpers/alertas';
import { Global } from 'src/app/helpers/global';
import { delay } from 'src/app/tools/delay.tool';


@Component({
  selector: 'app-user-request',
  templateUrl: './user-request.component.html',
  styleUrls: ['./user-request.component.css']
})
export class UserRequestComponent implements OnInit {

  @ViewChild('contenido', { read: ElementRef }) contentElement!: ElementRef;
  @ViewChild('nuevoMensajeAlExpediente') nuevoMensajeAlExpediente!: InputComponent<any>;

  ficheroPDFFormatoBLob: Blob | undefined;
  mensajesExpediente: MensajeExpediente[] = [];
  nuevoMensaje: string = '';
  idUsuarioLocal: string = '';

  urlFicheroAdjunto: string = Global.FICHERO_ADJUNTO;


  constructor(private solicitudUsuarioServicio: RequestService, private expedienteServicio: ExpedienteService, private usuarioLocalServicio: UsuarioLocalService,
    private requestSignalService: RequestSignalService
  ) {

  }


  @Input() solicitud: Solicitud | undefined;

  async ngOnInit(): Promise<void> {
    this.usuarioLocalServicio.getLocalUser().then((usuarioLocal => {
      this.idUsuarioLocal = usuarioLocal?.id || '';
    }))

    this.parsearEstadoYColor();
  }


  ficheros: any[] = [];


  parsearEstadoYColor(): void {
    if (this.solicitud?.estado.id === 3) {
      this.solicitud.estado.id = 2;
      this.solicitud.estado_id = 2;
      this.solicitud.estado.color = '#FB702B'
    }
  }

  openChat(solicitud?: Solicitud): void {
    if (!solicitud || solicitud.tematica.id === 5 || solicitud.estado.id < 2) return;
    document.getElementById(`${(solicitud.id || 0).toString()}-chat`)?.classList.toggle('show');


  }


  abrirMasInfo(id: number): void {
    document.getElementById(`${id.toString()}-masinfo`)?.classList.toggle('show');
    const img = document.querySelector(`#download-image-${id}`) as HTMLImageElement;
    img.classList.toggle('rotar');


    this.obtenerArchivosAdjuntosDeLaSolicitud();

  }

  async obtenerArchivosAdjuntosDeLaSolicitud(): Promise<void> {
    if (this.solicitud) {

      let ficherosTemporales = await this.solicitudUsuarioServicio.getAdjuntoSolicitudUsuario(this.solicitud.id);
      if (ficherosTemporales) {
        this.ficheros = [...ficherosTemporales.data]
      }
    }
  }


  eliminarArchivoAdjuntoDeLaSolicitud(idArchivo: number): void {

    mostrarMensajeDeAlerta('Aviso', '¿Estás seguro que deseas eliminar el adjunto?', 'warning', 'Aceptar', 'Cancelar', true, true).then(async respuesta => {
      if (!respuesta) return

      await this.solicitudUsuarioServicio.eliminarAdjuntoSolicitudUsuario(idArchivo);
      await this.obtenerArchivosAdjuntosDeLaSolicitud();
    })

  }

  formatDate(date: Date | string): string {
    return formatDateToDDMMYYYY(date);
  }

  /** NECESITA SER REFACTORIZADO URGENTE */
  activarInputFile(): void {
    document.getElementById(`archivoASubir-${this.solicitud?.id}`)?.click();
  }

  async archivoSeleccionado(event: any): Promise<void> {
    const inputFile = event.target as HTMLInputElement;
    const validExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'zip'];

    if (inputFile.files && inputFile.files[0]) {
      const file: File = event.target.files[0];

      if (file.size / (1024 * 1024) > 25) {
        await mostrarMensajeDeAlerta('Error', 'El archivo es demasiado grande, límite 25Mb', 'warning', 'Aceptar', '', true, false);
        return;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && !validExtensions.includes(fileExtension)) {
        mostrarMensajeDeAlerta('Error en el tipo de archivo', 'Solamente se permiten los siguientes tipos de archivo: doc, docx, xls, xlsx, jpg, jpeg, png, pdf, zip, txt', 'warning', 'Aceptar', '', true, false)
        inputFile.value = '';
      }



      if (this.solicitud?.id)
        await this.solicitudUsuarioServicio.subirAdjuntoAUnaSolicitud(file, this.solicitud.id);
      let ficherosTemporales = await this.solicitudUsuarioServicio.getAdjuntoSolicitudUsuario(this.solicitud!.id);
      if (ficherosTemporales) {
        this.ficheros = [...ficherosTemporales.data]
      }

    }
  }


  async abrirModal(): Promise<void> {
    if (this.solicitud) {
      let ficherosTemporales = await this.solicitudUsuarioServicio.getAdjuntoSolicitudUsuario(this.solicitud.id);
      if (ficherosTemporales) {
        this.ficheros = [...ficherosTemporales.data]
      }
    }
    document.getElementById(`miModal-${this.solicitud?.id}`)!.style.display = 'flex';



  }

  cerrarModal(): void {
    document.getElementById(`miModal-${this.solicitud?.id}`)!.style.display = 'none';

  }

  async abrirPDF(): Promise<void> {
    if (this.solicitud?.expediente) {
      mostrarMensajeDeCarga('Generando PDF', 'Por favor espere', 'info');
      delay(700);

      this.solicitudUsuarioServicio.getPDFDeSolicitud((this.solicitud?.expediente !== null) ? this.solicitud?.expediente.id : 0).subscribe(async data => {
        if (data.type === "application/json") {
          const reader = new FileReader();
          reader.readAsText(data);
          reader.onload = () => {
            const jsend = JSON.parse(reader.result as string);
            if (jsend.status === "error" && jsend.code === 422) {
              console.log("El expediente no está en estado de informe", "Advertencia");
            } else if (jsend.status === "error" && jsend.code === 404) {
              console.log("No se ha encontrado el expediente.", "Error");
            } else {
              console.log("Ha ocurrido un error al obtener el expediente.", "Error");
            }
          };
        } else {
          this.ficheroPDFFormatoBLob = new Blob([data], { type: 'application/pdf' });
          if (this.ficheroPDFFormatoBLob) {
            cerrarMensaje();
            const urlPdf = URL.createObjectURL(this.ficheroPDFFormatoBLob);
            window.open(urlPdf, '_blank');
            await this.solicitudUsuarioServicio.actualizarEstadoDeSolicitud(this.solicitud?.id!)
            this.requestSignalService.enviarCambiosRequest();
          }
        }


      })
    }
  }

  enviarMensajeAlExpediente(): void {
    if (this.solicitud?.expediente) {
      this.expedienteServicio.crearMensajeParaUnExpediente(this.solicitud?.expediente.id, this.nuevoMensaje).then(resp => {
        if (resp) {
          this.getMensajesDelExpediente();
          this.nuevoMensaje = '';
          this.nuevoMensajeAlExpediente.resetValorDelInput();
        }
      })
    }

  }

  async getMensajesDelExpediente(): Promise<void> {
    if (this.solicitud?.expediente) {
      this.mensajesExpediente = [... await this.expedienteServicio.getMensajeExpediente(this.solicitud?.expediente.id)];
      setTimeout(() => {
        this.scrollHaciaAbajo();
      }, 10);
    }
  }

  mensajeDelUsuario(mensajeExpediente: MensajeExpediente): boolean {
    return this.idUsuarioLocal === mensajeExpediente.usuario_id;
  }

  private scrollHaciaAbajo() {
    const content = this.contentElement.nativeElement as HTMLElement;
    content.scrollTop = content.scrollHeight;
  }






}
