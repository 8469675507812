<section id="home">
    <div class="home-info" id="home-info">
        <video class="video-background onlydesktop" id="aot-video" autoplay muted playsinline
            onloadedmetadata="this.muted = true" oncanplay="this.play()">
            <source src="../../../assets/videos/presentacion_aot_v4.mp4" type="video/mp4">
            Tu navegador no soporta la etiqueta de video HTML5.
        </video>

        <img src="../../../assets/images/aot.png" alt="" class="onlymobile">


        <div class="home-info-text">
            <h4> El <strong>Programa de Apoyo a las corporaciones locales</strong> e insulares de las islas de La
                Palma,
                La
                Gomera, El Hierro, Lanzarote y Fuerteventura, así como las corporaciones locales de menos de
                100.000 habitantes de las islas de Tenerife y Gran Canaria, para impulsar el desarrollo de las
                actividades de las oficinas técnicas, y la concienciación ciudadana en materia de urbanismo es
                un encargo que el Gobierno de Canarias hace a Gesplan con el fin de poner a disposición de las
                oficinas técnicas un equipo multidisciplinar para ayudarles en todo aquello que puedan
                necesitar, dentro del objeto de este programa.</h4>

        </div>


        <button id="enterButton" class="primary-button" (click)="openLoginModal()">Entrar</button>

        <p>Para <strong>consulta del archivo de planeamiento</strong>, click <a target="_blank"
                href={{urlPlaneamiento}}><u>aquí</u></a></p>


    </div>



</section>


<app-login-modal></app-login-modal>