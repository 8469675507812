import { Component } from '@angular/core';

@Component({
  selector: 'app-condiciones-de-uso',
  templateUrl: './condiciones-de-uso.component.html',
  styleUrls: ['./condiciones-de-uso.component.css']
})
export class CondicionesDeUsoComponent {

}
