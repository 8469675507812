<div class="emails">
    <div class="emails-sidebar">
        <app-sidebar-correo></app-sidebar-correo>
    </div>
    <div class="nuevo-email">
        <form (ngSubmit)="onSubmit()" [formGroup]="emailForm" *ngIf="emailForm">
            <div class="nuevo-email-content">
                <div class="aot-input" *ngIf="!emailConversacion">
                    <label for="receptor_id">Nombre de destinatario</label>
                    <input autocomplete="off" [value]="contactoSeleccionado" (blur)="ocultarContactoEmail()"
                        (keyup)="filtrarContactoEmail($event)" type="text" id="receptor_id" name="receptor_id"
                        formControlName="receptor_id" placeholder='Introduzca el nombre' required
                        [ngClass]="{'rojo': emailForm.get('receptor_id')?.touched && emailForm.get('receptor_id')?.errors?.['required'], 'verde': emailForm.get('receptor_id')?.valid}">
                    <div *ngIf="emailForm.get('receptor_id')?.touched && emailForm.get('receptor_id')?.errors?.['required']"
                        style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>

                    <div class="contactos-email" [hidden]="!contactosEmailVisibles">
                        <!-- Mostrar aquí la información de los contactos -->
                        <div *ngFor="let contacto of contactosEmail" class="contacto-email"
                            (click)="setEmailContactoID(contacto)">{{ contacto.nombre }} {{
                            contacto.apellidos}}</div>
                    </div>
                </div>

                <div class="aot-input" *ngIf="emailConversacion">
                    <label for="receptor_id">Nombre de destinatario</label>
                    <input autocomplete="off" type="text" disabled
                        [value]="emailConversacion.receptor.nombre + ' ' + emailConversacion.receptor.apellidos">
                </div>

                <div class="aot-input" *ngIf="!emailConversacion">
                    <label for="asunto">Asunto</label>
                    <input type="text" id="asunto" name="asunto" formControlName="asunto"
                        placeholder='Introduzca el asunto' required
                        [ngClass]="{'rojo': emailForm.get('asunto')?.touched && emailForm.get('asunto')?.errors?.['required'], 'verde': emailForm.get('asunto')?.valid}">
                    <div *ngIf="emailForm.get('asunto')?.touched && emailForm.get('asunto')?.errors?.['required']"
                        style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                </div>

                <div class="aot-input" *ngIf="emailConversacion">
                    <label for="asunto">Asunto</label>
                    <input autocomplete="off" type="text" disabled [value]="emailConversacion.asunto">
                </div>

                <div class="aot-input">
                    <label for="mensaje">Mensaje</label>

                    <ckeditor [editor]="Editor" data="" #correo formControlName="correo"
                        [ngClass]="{'rojo': emailForm.get('correo')?.touched && emailForm.get('correo')?.errors?.['required'], 'verde': emailForm.get('correo')?.valid}">
                    </ckeditor>
                    <div *ngIf="emailForm.get('correo')?.touched && emailForm.get('correo')?.errors?.['required']"
                        style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
                </div>
            </div>

            <div class="nuevo-email-footer">
                <div class="nuevo-email-footer-adjuntos">
                    <div class="adjuntos">
                        <strong>Adjuntos: <br><small>Tamaño máximo de archivo 25Mb</small></strong>
                        <div class="adjuntos-container">
                            <p *ngFor="let adjunto of archivosAdjuntos" class="adjunto"> {{adjunto.name}} <span
                                    class="eliminar-adjunto"
                                    (click)="eliminarArchivoEnArrayDeAdjuntos(adjunto)">X</span>
                            </p>
                        </div>

                    </div>

                    <div class="adjuntos" *ngIf="esEmailParaReenviar">
                        <strong>Adjuntos reenviados: </strong>
                        <p *ngFor="let adjunto of archivosAdjuntosReenviar" class="adjunto"> {{adjunto.adjunto}}
                        </p>
                    </div>


                </div>
                <div class="nuevo-email-footer-botones">
                    <input class=" seleccionarArchivo" type="file" (change)="onFileSelected($event)"
                        accept=".doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf,.zip,.txt" multiple>
                    <button class="primary-button" [disabled]="!emailForm.valid">Enviar</button>
                </div>

            </div>
        </form>


    </div>

</div>