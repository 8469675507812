<div class="reset-form">
    <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm" *ngIf="resetPasswordForm">
        <div class="password-container">
            <input type="password" placeholder="Contraseña" id="resetPassword" name="password"
                formControlName="password" required [ngClass]="{
                                        'rojo': (resetPasswordForm.get('password')?.touched && resetPasswordForm.get('password')?.invalid),
                                        'verde': resetPasswordForm.get('password')?.valid
                                    }">
            <img src="../../../assets/images/ojo.svg" id="eye" (click)="mostrarPasswordIntroducidoPorElUsuario()">
            <div *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.get('password')?.errors?.['required']"
                style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
            <div *ngIf="resetPasswordForm.get('password')?.errors?.['pattern']" style="color: rgb(169, 72, 47)">
                La contraseña debe tener entre 8 y 20 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un símbolo especial. No debe contener espacios ni la letra ñ.
            </div>
        </div>
        <div class="password-container">
            <input type="password" placeholder="Repetir contraseña" id="repetirResetPassword" name="password_confirm"
                formControlName="password_confirm" required [ngClass]="{
                                        'rojo': resetPasswordForm.hasError('passwordsNotMatch') && (resetPasswordForm.get('password_confirm')?.dirty || resetPasswordForm.get('password_confirm')?.touched),
                    'verde': !resetPasswordForm.hasError('passwordsNotMatch') && resetPasswordForm.get('password_confirm')?.valid
                                    }">
            <img src="../../../assets/images/ojo.svg" id="eye2" (click)="mostrarPasswordIntroducidoPorElUsuario()">
            <div *ngIf="resetPasswordForm.get('password_confirm')?.touched && resetPasswordForm.get('password_confirm')?.errors?.['required']"
                style="color: rgb(169, 72, 47)">Este campo es obligatorio</div>
            <div *ngIf="resetPasswordForm.hasError('passwordsNotMatch') && (resetPasswordForm.get('password_confirm')?.dirty || resetPasswordForm.get('password_confirm')?.touched)"
                style="color: rgb(169, 72, 47)">
                Las contraseñas no coinciden
            </div>

        </div>
        <!--        <button class="primary-button" (click)="onSubmit()">Aceptar</button>-->
        <button type="submit" class="primary-button">Aceptar</button>

    </form>
</div>