const palabrasNoCapitalizables = ['de', 'y', 'la', 'del', 'el', 'los', 'las'];


const capitalizacionDeTitulosDesdeArray = (nombreEnArray: Array<string>): Array<string> => {
    return nombreEnArray.map(nombre => {
        if (!nombre) return '';
        const nombreEnMinusculas = nombre.toLocaleLowerCase();
        if (esPalabraNoCapitalizable(nombreEnMinusculas)) return nombreEnMinusculas
        return nombreEnMinusculas.charAt(0).toLocaleUpperCase() + nombreEnMinusculas.slice(1);
    });
}

const esPalabraNoCapitalizable = (palabra: string): boolean => {

    return palabrasNoCapitalizables.includes(palabra)

}

export const normalizarNombreApellidos = (nombre: string): string => {
    if (!nombre) return '';
    const nombreApellidosSpliteado = nombre.trim().split(/\s+/);
    return capitalizacionDeTitulosDesdeArray(nombreApellidosSpliteado).join(' ');
}
