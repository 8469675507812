import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComunicacionService } from 'src/app/servicios/comunicacion.service';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css']
})
export class RadioComponent<T> implements OnChanges, OnInit, AfterViewInit {
  @Input() radioValues: string[] = [];
  @Input() radioName: string = '';
  @Input() reset: boolean = false;
  @Output() radioValueEmmiter = new EventEmitter<string>();
  selectedRadio = '';
  @Input() objetos: T[] = [];
  @Input() propiedadDelObjetoQueTieneElValor: keyof T | null = null;
  @Input() propiedadDelObjetoQueTieneElNombre: keyof T | null = null;
  @Input() propiedadDelObjetoQueTieneElColor: keyof T | null = null;
  @Input() valorPorDefecto: number | string | null = null;

  constructor(private comunicacionServicio: ComunicacionService) {
    this.comunicacionServicio.reset$.subscribe(() => {
      console.log('comunicación con el reset')
      this.resetRadioValue();
    })
    if (this.reset) this.resetRadioValue();
  }
  ngAfterViewInit(): void {
    if (this.valorPorDefecto) this.establecerValorPorDefecto(this.valorPorDefecto)
  }
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reset'] && changes['reset'].currentValue) this.resetRadioValue();
  }

  establecerValorPorDefecto(valor: string | number): void {
    const radios: NodeListOf<HTMLInputElement> = document.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
  }


  radioValueChanged(value: any): void {
    this.selectedRadio = value;
    console.log(this.selectedRadio)

    this.radioValueEmmiter.emit(value);
  }

  resetRadioValue(): void {
    let radio = document.getElementById(`radio-${this.radioName}-${this.selectedRadio}`) as HTMLInputElement;
    console.log(radio)
    radio.checked = false;
  }


}
