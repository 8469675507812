import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { TitlePageComponent } from './shared/title-page/title-page.component';
import { AvailableDocumentsComponent } from './pages/available-documents/available-documents.component';
import { LoginComponent } from './auth-login/login/login.component';
import { RegisterComponent } from './auth-login/register/register.component';
import { PasswordRecoveryComponent } from './auth-login/password-recovery/password-recovery.component';
import { FooterComponent } from './shared/footer/footer.component';
import { InputComponent } from './shared/input/input.component';
import { SelectComponent } from './shared/select/select.component';
import { RadioComponent } from './shared/radio/radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AotContentTitleComponent } from './shared/aot-content-title/aot-content-title.component';
import { LoginModalComponent } from './shared/login-modal/login-modal.component';
import { ProgramComponent } from './pages/program/program.component';
import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { ContactComponent } from './pages/contact/contact.component';
import { RequestComponent } from './pages/request/request.component';
import { TextAreaComponent } from './shared/text-area/text-area.component';
import { EmailComponent } from './pages/correo/email/email.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AvailableDocumentsService } from './services/available-documents.service';
import { JwtInterceptor } from './auth-login/jwt.interceptor';
import { UserComponent } from './pages/user/user.component';
import { StickyHeaderComponent } from './shared/sticky-header/sticky-header.component';
import { UserRequestComponent } from './shared/user-request/user-request.component';
import { CheckboxComponent } from './shared/checkbox/checkbox.component';
import { AotContactComponent } from './pages/aot-contact/aot-contact.component';
import { SidebarFilterComponent } from './shared/sidebar-filter/sidebar-filter.component';
import { UserForumComponent } from './shared/user-forum/user-forum.component';
import { ForumsComponent } from './pages/forums/forums.component';
import { ForumComponent } from './shared/forum/forum.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GrantsComponent } from './pages/grants/grants.component';
import { ValidarUsuarioComponent } from './pages/validar-usuario/validar-usuario.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { ConcienciacionCiudadanaComponent } from './pages/concienciacion-ciudadana/concienciacion-ciudadana.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { InputPasswordComponent } from './shared/input-password/input-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NuevoEmailComponent } from './pages/correo/nuevo-email/nuevo-email.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SidebarCorreoComponent } from './pages/correo/sidebar-correo/sidebar-correo.component';
import { ListaEmailsComponent } from './pages/correo/lista-emails/lista-emails.component';
import { CondicionesDeUsoComponent } from './pages/condiciones-de-uso/condiciones-de-uso.component';
import { PoliticaDeCookiesComponent } from './pages/politica-de-cookies/politica-de-cookies.component';
import { PoliticaDePrivacidadComponent } from './pages/politica-de-privacidad/politica-de-privacidad.component';
import { PoliticaDePrivacidadPublicaComponent } from './pages/politica-de-privacidad-publica/politica-de-privacidad-publica.component';
import { PoliticaDeCookiesPublicaComponent } from './pages/politica-de-cookies-publica/politica-de-cookies-publica.component';
import { CondicionesDeUsoPublicaComponent } from './pages/condiciones-de-uso-publica/condiciones-de-uso-publica.component';
import { BannerCookiesComponent } from './shared/banner-cookies/banner-cookies.component';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        SidebarComponent,
        WelcomeComponent,
        TitlePageComponent,
        AvailableDocumentsComponent,
        LoginComponent,
        RegisterComponent,
        PasswordRecoveryComponent,
        FooterComponent,
        InputComponent,
        SelectComponent,
        RadioComponent,
        AotContentTitleComponent,
        LoginModalComponent,
        ProgramComponent,
        WhoWeAreComponent,
        ContactComponent,
        RequestComponent,
        TextAreaComponent,
        EmailComponent,
        UserComponent,
        StickyHeaderComponent,
        UserRequestComponent,
        CheckboxComponent,
        AotContactComponent,
        SidebarFilterComponent,
        UserForumComponent,
        ForumsComponent,
        ForumComponent,
        GrantsComponent,
        ValidarUsuarioComponent,
        NewsletterComponent,
        ConcienciacionCiudadanaComponent,
        PageNotFoundComponent,
        InputPasswordComponent,
        ResetPasswordComponent,
        NuevoEmailComponent,
        SidebarCorreoComponent,
        ListaEmailsComponent,
        CondicionesDeUsoComponent,
        PoliticaDeCookiesComponent,
        PoliticaDePrivacidadComponent,
        PoliticaDePrivacidadPublicaComponent,
        PoliticaDeCookiesPublicaComponent,
        CondicionesDeUsoPublicaComponent,
        BannerCookiesComponent,

    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        CKEditorModule], providers: [
            AvailableDocumentsService,
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
