import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioLocalService } from './usuario-local.service';
import { Global } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';
import { RespuestaApi } from '../models/respuesta-api';
import { MensajeExpediente } from '../models/mensaje-expediente';

@Injectable({
  providedIn: 'root'
})
export class ExpedienteService {

  constructor(private httpClient: HttpClient, private localUserService: UsuarioLocalService) { }


  async crearExpediente(id: number, disponible_id: number): Promise<void | null> {

    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    try {
      const url = Global.URL_EXPEDIENTES + '/iniciar'
      const respuesta: any = this.httpClient.post(url, { 'solicitud_id': id, 'disponible_id': disponible_id }, { headers });
      console.log(respuesta)

    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
    }


  }


  async crearMensajeParaUnExpediente(idExpediente: number, mensaje: string): Promise<boolean | null> {
    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    try {
      const respuesta = await lastValueFrom(this.httpClient.post(Global.url + Global.URL_EXPEDIENTES + '/' + idExpediente + '/mensajes', { 'mensaje': mensaje }, { headers })) as RespuestaApi;
      if (respuesta.status === 'success') return true;
      else return false;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return false;
    }


  }


  async getMensajeExpediente(idExpediente: number): Promise<MensajeExpediente[]> {
    const usuario = await this.localUserService.getLocalUser();

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`,
    });

    try {
      const url = Global.url + Global.URL_EXPEDIENTES + '/' + idExpediente + '/mensajes?include_associations=usuarios&sort=fecha';

      const respuesta: Observable<{ data: MensajeExpediente[] }> = this.httpClient.get<{ data: MensajeExpediente[] }>(url, { headers });
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      return [];
    }
  }

}
