import { Global } from "./global";

export const MATERIAS_TRANSVERSALES_PARTICIPANDO_AOT = [

    {
        bloque: 1,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}sembrando_ciudades_saludables.jpg`,
        titulo: 'Sembrando Ciudades Saludables',
        descripcion: 'En colaboración con el Ayuntamiento de Santa Úrsula, la Mesa Sectorial Municipal de la Salud y el IES de Santa Úrsula, se desarrolló un programa de actividades para fomentar una nueva cultura de ciudades saludables. Este programa se centró en la movilidad sostenible y en la creación de espacios públicos inclusivos y seguros, promoviendo la participación activa de la infancia y la juventud en el cambio. Las actividades abordaron tres temáticas principales: accesibilidad en el espacio público, calidad ambiental urbana y el derecho al espacio público. El proceso culminó con el cierre definitivo al tráfico del área intervenida.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}sembrando_ciudades_saludables.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}1_1_que_es_un_proyecto.mp4`,

    },
    {
        bloque: 2,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}encuentros_placeta.jpg`,
        titulo: 'Encuentros en la placeta',
        descripcion: 'Entre finales de 2022 y principios de 2023, colaboramos con el Ayuntamiento de Tegueste en un proceso de participación ciudadana. Vecinas y vecinos trabajaron juntos para definir y poner en valor un nuevo espacio peatonal adyacente a La Placeta Pedro Melián Díaz, creado tras los cambios en la circulación de la zona.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}encuentros_placeta.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}encuentros_placeta.mp4`,


    },
    {
        bloque: 3,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.jpg`,
        titulo: 'AOT en los procesos participativos municipales',
        descripcion: 'Este video es un ejemplo de buenas prácticas en la creación de espacios de participación ciudadana para la toma de decisiones en planificación y ordenación urbana y territorial. En él se presentan las experiencias del equipo de AOT de Santa Cruz de Tenerife con diversas administraciones públicas durante el período 2019-2021:',
        lista: ['Foro de Sostenibilidad con el Ayuntamiento del Puerto de la Cruz.', 'Proyecto de Concienciación en Urbanismo y Patrimonio con la Infancia, con el Ayto. del Puerto de la Cruz y el colegio Tomás de Iriarte.', 'Mesa de Niños y Niñas por el Medioambiente con el Ayuntamiento de Tegueste.'],
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}aot_proc_particip_municipales.mp4`,

    }
];


export const MATERIAS_TRANSVERSALES_CREANDO_CONCIENCIA = [

    {
        bloque: 1,
        imagen: `${Global.IMAGENES_MATERIAS_TRANSVERSALES}territorio_vida.jpg`,
        titulo: 'Un Territorio para la Vida',
        descripcion: 'El video que se presenta bajo el título “Un Territorio para la vida” ofrece una introducción general sobre el concepto de urbanismo y su importancia en nuestras vidas. Usando el marco territorial de Canarias, combina imágenes de archivo y actuales de sus entornos naturales y urbanos.',
        urlVIDEO: `${Global.VIDEOS_MATERIAS_TRANSVERSALES}territorio_vida.mp4`,
        urlPDF: `${Global.PDF_MATERIAS_TRANSVERSALES}1_1_que_es_un_proyecto.mp4`,

    }
]
