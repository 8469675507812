export class Seccion {


    constructor(
        public id: number = -1,
        public seccion: string = '',
        public fecha: Date = new Date(),
        public estado: boolean = true,
        public orden: number = -1
    ) {

    }
}