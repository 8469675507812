import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Correo } from 'src/app/models/correo';
import { EmailService } from 'src/app/services/email.service';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})



export class EmailComponent implements OnInit {

  public Editor = ClassicEditor;
  public configEditor: any = {
    toolbar: {
      items: [

      ]

    }
  }



  email?: Correo;



  constructor(private emailServicio: EmailService, private route: ActivatedRoute) {



  }
  async ngOnInit(): Promise<void> {

    const emailID = this.route.snapshot.params['id'];
    if (emailID) {
      this.email = await this.emailServicio.getEmail(emailID);
    }

    // await this.getEmails();
  }

  /*
    async getEmails(): Promise<void> {
      this.emails = (await this.emailServicio.getEmails()).filter((email => { return email.estado === 0 }));
    }
  
  
    parsearFecha(fecha: Date): string {
      return formatDateToDDMMYYYY(fecha);
    }
  
  
    contarCorreosNoLeidos(): number {
  
      return this.emails.filter((email => {
        return email.visto === false
      })).length;
  
    }
  
  
  
    cambiarVista(id: string): void {
      if (id === 'nuevo-email') {
        const nuevoEmail = document.getElementById('nuevo-email') as HTMLElement;
        nuevoEmail.classList.add('mostrar')
        const listaEmails = document.getElementById('lista-emails') as HTMLElement;
        listaEmails.classList.remove('mostrar')
      }
      else {
        const nuevoEmail = document.getElementById('nuevo-email') as HTMLElement;
        nuevoEmail.classList.remove('mostrar')
  
        const listaEmails = document.getElementById('lista-emails') as HTMLElement;
        listaEmails.classList.add('mostrar')
  
        this.seleccionarBandeja(id);
  
      }
  
    }
  
    seleccionarBandeja(bandeja: string) {
      this.emailComunicacionSignal.seleccionarBandeja(bandeja);
    }*/

}
