import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CORPORACIONES } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';
import { Corporacion } from '../models/corporacion';

@Injectable({
  providedIn: 'root'
})
export class CorporacionService {

  constructor(private httpClient: HttpClient) { }

  async getCorporacion(codigoCorporacion?: string): Promise<Corporacion[]> {
    try {
      const url = codigoCorporacion
        ? CORPORACIONES.URL + codigoCorporacion
        : CORPORACIONES.URL;

      const respuesta: Observable<{ data: Corporacion[] }> = this.httpClient.get<{ data: Corporacion[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return await this.eliminarCorporacionesNoInteresadasParaUsuario(resultado.data)
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  async eliminarCorporacionesNoInteresadasParaUsuario(corporaciones: Corporacion[]): Promise<Corporacion[]> {
    return corporaciones
      .filter(corporaciones => corporaciones.corporacion !== 'Seleccione corporación' && corporaciones.corporacion !== 'Gesplan')
  }
}
