export class Register {
    constructor(
        public nombre: string = '',
        public apellidos: string = '',
        public password: string = '',
        public password_confirm: string = '',
        public email: string = '',
        public municipio_id: number = -1,
        public provincia_id: number = -1,
        public isla_id: number = -1,
        public corporacion_id: number = -1,
        public seccion_id: number = -1,
        public profesion_id: number = -1,
        public cargo_id: number = -1,
        public telefono: string = '',
        public extension: string = '',
        public movil: number = -1,
        public comunicaciones: boolean = false,
        public newsletters: boolean = false,
        public sentencias: boolean = false,


    ) { }
}

export const tieneValoresPorDefecto = (registro: Register): boolean => {
    const valoresPorDefecto = new Register();
    for (const key in valoresPorDefecto) {
        if (key === 'comunicaciones' || key === 'newsletters' || key === 'sentencias' || key === 'extension' || key === 'telefono' || key === 'movil') return true;
        else if (valoresPorDefecto.hasOwnProperty(key) && valoresPorDefecto[key as keyof Register] === registro[key as keyof Register])
            return false;
    }
    return true;
}

export const passwordYPassword_confirmSonIguales = (registro: Register): boolean => {
    return registro.password === registro.password_confirm;
}

