import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SolicitudesMunicipio } from 'src/app/models/solicitudes-municipio';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements AfterViewInit, OnInit {


  // TODO
  data: SolicitudesMunicipio[] = [];


  currentValueToShow = 0;
  currentMunicipalityToShow = '';

  constructor(private requestServicio: RequestService) {

  }
  async ngOnInit(): Promise<void> {
    this.data = [... await this.requestServicio.getSolicitudesPorMunicipio()];
    console.log(this.data)
    this.data.forEach(value => {
      let elemento = document.getElementById('_' + value.municipio_id) as HTMLElement;
      let e = elemento.childNodes[0] as SVGCircleElement;
      e.setAttribute('r', this.calcularRadio(value.recuento));


      let tooltipSpan = document.getElementById('tooltip-span') as HTMLElement;

      window.onmousemove = function (e) {
        let x = e.clientX,
          y = e.clientY;
        tooltipSpan.style.top = (y + 20) + 'px';
        tooltipSpan.style.left = (x + 20) + 'px';
      };

      e.addEventListener('mouseenter', () => {
        this.currentValueToShow = value.recuento;
        this.currentMunicipalityToShow = value.municipio;
        tooltipSpan.classList.add('show');
      })

      e.addEventListener('mouseleave', () => {
        tooltipSpan.classList.remove('show');
      })

    })

  }
  ngAfterViewInit(): void {
  }


  calcularRadio(valor: number): string {
    if (valor > 0 && valor <= 20) return '6'
    if (valor > 20 && valor <= 40) return '8'
    if (valor > 40 && valor <= 60) return '9'
    if (valor > 60 && valor <= 80) return '4'
    if (valor > 80 && valor <= 100) return '5'
    if (valor > 100 && valor <= 150) return '6'
    if (valor > 150 && valor <= 200) return '7'
    if (valor > 200 && valor <= 250) return '8'
    if (valor > 250 && valor <= 300) return '9'
    if (valor > 300) return '10'
    return '0'

  }

}
