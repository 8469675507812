import { Component, Injectable, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { parseURL } from './tools/url.tool';
import { Output, EventEmitter } from '@angular/core';
import { NavegacionService } from './services/navegacion.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'Gesplan AOT';
  subtitle = '';
  isLoged: boolean = false;
  backgroundURL = '';
  currentHeader: any;
  isPresentationPage = false;
  isValidarUsuarioPage: boolean = false;

  urls = [{
    url: 'presentacion',
    title: '¡Hola!',
    subtitle: 'Estamos aquí para ayudarte',
    image: 'presentacion.png',
    align: 'center'

  }, {
    url: 'programa',
    title: 'Objeto del programa',
    subtitle: '',
    image: 'programa.png',
    align: 'start'

  },
  {
    url: 'quienes-somos',
    title: 'Programa AOT y equipo',
    subtitle: '',
    image: 'quienes-somos.png',
    align: 'start'

  }
    ,
  {
    url: 'lineas-actuacion',
    title: 'Líneas de actuación',
    subtitle: '',
    image: 'lineas-actuacion.png',
    align: 'start'

  },
  {
    url: 'documentos-disponibles',
    title: 'Documentos disponibles',
    subtitle: '',
    image: 'documentos-disponibles.png',
    align: 'start'

  },
  {
    url: 'solicitud',
    title: 'Nueva Solicitud',
    subtitle: '',
    image: 'solicitud.png',
    align: 'start'

  }
    ,
  {
    url: 'emails',
    title: 'Correos',
    subtitle: '',
    image: 'emails.png',
    align: 'start'

  },
  {
    url: 'email',
    title: 'Correo',
    subtitle: '',
    image: 'email.png',
    align: 'start'

  },

  {
    url: 'nuevo-email',
    title: 'Nuevo correo',
    subtitle: '',
    image: 'email.png',
    align: 'start'

  },
  {
    url: 'usuario',
    title: 'Mi cuenta',
    subtitle: '',
    image: 'usuario.png',
    align: 'start'

  },
  {
    url: 'contacto-aot',
    title: 'Contacto AOT',
    subtitle: '',
    image: 'contacto-aot.png',
    align: 'start'

  },
  {
    url: 'foros',
    title: 'Foros de aprendizaje',
    subtitle: '',
    image: 'foros.png',
    align: 'start'

  },
  {
    url: 'subvenciones',
    title: 'Subvenciones',
    subtitle: '',
    image: 'subvenciones.png',
    align: 'start'

  },
  {
    url: 'newsletter',
    title: 'Newsletter',
    subtitle: '',
    image: 'newsletter.png',
    align: 'start'

  },
  {
    url: 'concienciacion-ciudadana',
    title: 'Materias transversales',
    subtitle: '',
    image: 'concienciacion-ciudadana.png',
    align: 'start'

  },
  {
    url: 'validar-usuario',
    title: 'Validar usuario',
    subtitle: '',
    image: 'validar-usuario.png',
    align: 'start'

  }
    ,
  {
    url: 'reset-password',
    title: 'Reiniciar contraseña',
    subtitle: '',
    image: 'reset-password.png',
    align: 'start'

  }
    ,
  {
    url: 'condiciones-de-uso',
    title: 'Condiciones de uso',
    subtitle: '',
    image: 'condiciones-de-uso.png',
    align: 'start'

  }
    ,
  {
    url: 'politica-de-cookies',
    title: 'Política de cookies',
    subtitle: '',
    image: 'politica-de-cookies.png',
    align: 'start'

  },
  {
    url: 'politica-de-privacidad',
    title: 'Política de privacidad',
    subtitle: '',
    image: 'politica-de-privacidad.png',
    align: 'start'

  }
  ];


  scrollChange: EventEmitter<number> = new EventEmitter();

  pos: number = 0;

  constructor(private router: Router, private navegacionServicio: NavegacionService) {

  }



  /** TODO - MUY Provisional Code */
  ngOnInit(): void {

    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        const mainContent = document.querySelector('.aot-router-outlet');
        if (mainContent) {
          mainContent.scrollTop = 0;
        }

        const mainContentPublic = document.querySelector('.aot-router-outlet-public');
        if (mainContentPublic) {
          mainContentPublic.scrollTop = 0;
        }

        let url = await parseURL(e.url);

        if (url.includes('reset-password')) url = url.split('?')[0];

        if (url.includes('validar-usuario')) url = url.split('/')[0];

        if (url.includes('email')) url = url.split('/')[0];
        if (url.includes('nuevo-email')) url = url.split('?')[0];


        this.isPresentationPage = url === 'presentacion';
        this.backgroundURL = `../../../assets/images/banners/${url}`;
        this.currentHeader = this.getCurrentHeader(url);
        this.isValidarUsuarioPage = false;

        if (url === '' || url === 'login' || url === 'registro' || url === 'contacto' || url === 'politica-de-privacidad-publica'
          || url === 'condiciones-de-uso-publica' || url === 'politica-de-cookies-publica'
        ) {
          this.isLoged = false;

        }
        else if (url.includes('validar-usuario')) {
          this.isLoged = false;
          this.isValidarUsuarioPage = true;
        }
        else {
          this.isLoged = true;

        }
      }
    })
  }

  getCurrentHeader(url: string): string {
    let header: any;
    this.urls.filter((e) => e.url === url).map(value => header = value);
    return header;
  }
}