import { Component, OnInit } from '@angular/core';
import * as HTMLElementTOOLS from '../../tools/html-elements.tool';
import { PILDORAS_FORMATIVAS } from 'src/app/helpers/pildoras_formativas';
import { Global } from 'src/app/helpers/global';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forums',
  templateUrl: './forums.component.html',
  styleUrls: ['./forums.component.css']
})
export class ForumsComponent implements OnInit {

  public pildorasFormativas: any[] = [];
  presentacionPildoraVideoURL: null | string = null;
  versionPro: boolean = false;

  constructor() {

    this.pildorasFormativas = [...PILDORAS_FORMATIVAS];



  }
  ngOnInit(): void {
    this.versionPro = environment.version === 'pro';

    this.presentacionPildoraVideoURL = Global.VIDEOS_PILDORAS_FORMATIVAS + 'bienvenida_pildoras_formativas.mp4'
  }

  expandHeightColumn(e: any): void {
    document.getElementById(e)?.classList.toggle('open');

  }

  async selectedForum(e: EventTarget): Promise<void> {
    let selectedHTMLElement = e as HTMLElement;

    await HTMLElementTOOLS.removeClassForAllClasName('forum-link', 'selected');

    await HTMLElementTOOLS.addClassToElement('selected', selectedHTMLElement.id);

    await HTMLElementTOOLS.removeClassForAllClasName('forum-section', 'current');

    await HTMLElementTOOLS.addClassToElement('current', `${selectedHTMLElement.id}-section`)
  }


  openVideo(videoName: string): void {
    window.open(`${videoName}`, '_blank');
  }
}
