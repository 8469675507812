import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { APPVERSION } from '../helpers/version';

@Injectable({
    providedIn: 'root'
})
export class CheckVersion implements CanActivate {

    constructor(private router: Router) { }

    canActivate(): boolean {
        const currentVersion = APPVERSION.version;
        const storedVersion = localStorage.getItem('appVersion');

        if (!storedVersion) {
            console.log('Primera versión implementada');
            localStorage.setItem('appVersion', currentVersion);
            this.reloadPage();
            return false;
        }
        else if (storedVersion !== currentVersion) {
            console.log('Actualizada la versión');
            localStorage.setItem('appVersion', currentVersion);
            this.reloadPage();
            return false;
        }
        else {
            console.log('Ya está en la última versión');
            return true;
        }
    }

    reloadPage(): void {
        window.location.reload();
    }
}
