<div class="sidebar-correo">
    <button class="primary-button" [routerLink]="['/nuevo-email']" *ngIf="botonAMostrar === 'nuevo_correo'">Nuevo
        correo</button>
    <button class="primary-button" [routerLink]="['/emails']" *ngIf="botonAMostrar === 'bandeja_entrada'">Bandeja de
        entrada</button>
    <div class="email-filters">
        <div class="email-filters-header">
            <p class="">Carpetas</p>
        </div>
        <div class="email-filters-list">
            <div class="email-filter-list-item" (click)="seleccionarBandeja('recibidos')">
                <a id="recibidos" class="email-filter-list-link selected"><span><img
                            src="../../../assets/images/recibidos.svg" alt=""
                            class="email-filter-list-item-link-icon"></span><span
                        class="email-filter-list-item-link-text">Recibidos</span><span
                        *ngIf="this.numeroEmailNoLeidos !== 0"
                        class="email-filter-list-item-link-number">{{this.numeroEmailNoLeidos}}
                    </span></a>
            </div>
            <div class="email-filter-list-item" (click)="seleccionarBandeja('enviados')">
                <a id="enviados" class="email-filter-list-link"><span><img src="../../../assets/images/enviados.svg"
                            alt="" class="email-filter-list-item-link-icon"></span><span
                        class="email-filter-list-item-link-text">Enviados</span><span></span></a>
            </div>
            <div class="email-filter-list-item" (click)="seleccionarBandeja('papelera')">
                <a id="papelera" class="email-filter-list-link"><span><img src="../../../assets/images/papelera.svg"
                            alt="" class="email-filter-list-item-link-icon"></span><span
                        class="email-filter-list-item-link-text">Papelera</span></a>
            </div>


        </div>
    </div>
</div>