<div class="validar-usuario">
    <div class="validar-usuario-logo">
        <img src="../../../assets/images/aot.png" alt="">
        <img src="../../../assets/images/footer.png" alt="">
    </div>
    <pre id="mensaje" class="validar-usuario-mensaje">{{mensaje}}</pre>

    <div class="validar-usuario-caducado" *ngIf="this.mostrarInputParaEmailNoVerificado">
        <div class="aot-input">
            <label for="enviarEmailParaVerificar">Introduzca su email para volver a validarlo</label>
            <input type="text" (keyup)="comprobarEmail($event)" #enviarEmailParaVerificar id="enviarEmailParaVerificar"
                [(ngModel)]="emailParaVerificar" placeholder="Email para validarlo">
        </div>
        <span *ngIf="mostrarMensajeDeErrorEmail">Error el formato de email no es correcto</span>
        <button class="primary-button" (click)="enviarNuevaValidacionAlUsuario($event)"
            [disabled]="mostrarMensajeDeErrorEmail">Enviar</button>
    </div>



</div>