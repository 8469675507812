import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-de-cookies',
  templateUrl: './politica-de-cookies.component.html',
  styleUrls: ['./politica-de-cookies.component.css']
})
export class PoliticaDeCookiesComponent {

}
