import { Global } from "./global";

export const PILDORAS_FORMATIVAS = [
    {
        bloque: 1,
        imagen_bloque: `${Global.IMAGENES_PILDORAS_FORMATIVAS}pildora_bloque_1_v2.jpg`,
        nombre_del_bloque: 'Introducción a proyectos y principales plataformas de convocatorias',
        descripcion_bloque: 'El primer bloque de estas píldoras formativas está enfocado en la definición de qué es un proyecto, detallando su estructura, contenido y las distintas fases que lo componen, con el objetivo de comprender cómo desarrollar un proyecto europeo de calidad. Además, se presentan las principales plataformas para consultar convocatorias de proyectos europeos.',
        tiempo: '60 minutos',
        temas: [
            {
                nombre: '¿Qué es un proyecto?',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}1_1_que_es_un_proyecto.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}1_1_que_es_un_proyecto.pptx`
            },
            {
                nombre: 'Sistema Nacional de Publicidad de Subvenciones y Ayudas Públicas',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}1_2_sistema_nacional_subvenciones.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}1_2_sistema_nacional_subvenciones.pptx`
            },
            {
                nombre: 'Página de proyectos de la Unión Europea',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}1_3_pagina_proyectos_union_europea.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}1_3_pagina_proyectos_union_europea.pptx`
            },
            {
                nombre: 'Interreg',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}1_4_interreg.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}1_4_interreg.pptx`
            }
        ]
    },
    {
        bloque: 2,
        nombre_del_bloque: 'Guía para la elaboración y presentación de un proyecto competitivo',
        imagen_bloque: `${Global.IMAGENES_PILDORAS_FORMATIVAS}pildora_bloque_2.jpg`,
        descripcion_bloque: 'El segundo bloque de estas píldoras formativas se centra en cómo presentar un proyecto competitivo, analizando aspectos clave como la identificación de beneficiarios, la definición de problemas y soluciones, y el establecimiento de objetivos generales y específicos. También se profundiza en la creación de paquetes de trabajo, indicadores de éxito, sostenibilidad e impacto del proyecto, además de la planificación, el presupuesto, y los modelos de formularios técnicos y económicos necesarios para una presentación exitosa.',
        tiempo: '165 minutos',
        temas: [
            {
                nombre: 'Beneficiarios',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_1_beneficiarios.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_1_beneficiarios.pptx`
            },
            {
                nombre: 'Árbol de problemas y árbol de soluciones',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_2_arbol_problemas_soluciones.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_2_arbol_problemas_soluciones.pptx`
            },
            {
                nombre: 'Objetivo general y objetivos específicos',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_3_objetivo_general_especificos.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_3_objetivo_general_especificos.pptx`
            },
            {
                nombre: 'Paquetes de trabajo',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_4_paquetes_trabajo.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_4_paquetes_trabajo.pptx`
            },
            {
                nombre: 'Paquetes de trabajo de desarrollo',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_5_paquetes_trabajo_desarrollo.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_5_paquetes_trabajo_desarrollo.pptx`
            },
            {
                nombre: 'Paquetes de trabajo transversales',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_6_paquetes_trabajo_transversales.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_6_paquetes_trabajo_transversales.pptx`
            },
            {
                nombre: 'Indicadores',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_7_indicadores.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_7_indicadores.pptx`
            },
            {
                nombre: 'Impacto y sostenibilidad',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_8_impacto_sostenibilidad.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_8_impacto_sostenibilidad.pptx`
            },
            {
                nombre: 'Matriz de planificación de proyecto',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_9_matriz_planificacion_proyecto.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_9_matriz_planificacion_proyecto.pptx`
            },
            {
                nombre: 'Presupuesto',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_10_presupuesto.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_10_presupuesto.pptx`
            },
            {
                nombre: 'Modelos de formulario técnico y económico',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}2_11_modelos_formulario_tecnico_economico.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}2_11_modelos_formulario_tecnico_economico.pptx`
            }
        ]
    },
    {
        bloque: 3,
        nombre_del_bloque: 'Aspectos clave en la gestión de un proyecto europeo',
        imagen_bloque: `${Global.IMAGENES_PILDORAS_FORMATIVAS}pildora_bloque_3.jpg`,
        descripcion_bloque: 'En este apartado se analizan en profundidad las claves para la gestión eficaz de un proyecto europeo, abordando aspectos fundamentales como la gestión del consorcio, las estrategias de comunicación y difusión, y la elaboración de cronogramas y presupuestos. Esta guía práctica proporciona así las herramientas necesarias para una buena coordinación y gestión de los socios, el tiempo y los recursos financieros, asegurando el cumplimiento de los plazos establecidos.',
        tiempo: '60 minutos',
        temas: [
            {
                nombre: 'Gestión del Consorcio',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}3_1_gestion_consortio.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}3_1_gestion_consortio.pptx`
            },
            {
                nombre: 'Comunicación y Difusión',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}3_2_comunicacion_difusion.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}3_2_comunicacion_difusion.pptx`
            },
            {
                nombre: 'Cronograma y presupuesto',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}3_3_cronograma_presupuesto.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}3_3_cronograma_presupuesto.pptx`
            }
        ]
    },
    {
        bloque: 4,
        nombre_del_bloque: 'Cierre del proyecto',
        imagen_bloque: `${Global.IMAGENES_PILDORAS_FORMATIVAS}pildora_bloque_4.jpg`,
        descripcion_bloque: 'El cuarto bloque se dedica al cierre de un proyecto, cubriendo tres aspectos clave: la justificación técnica, la justificación económica y la evaluación general del proyecto. Estas tres píldoras sirven de guía para preparar la documentación final, justificar los resultados y evaluar el proyecto en su totalidad, asegurando que se cumplan todos los requisitos y se concluya de manera efectiva.',
        tiempo: '45 minutos',
        temas: [
            {
                nombre: 'Justificación técnica',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}4_1_justificacion_tecnica.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}4_1_justificacion_tecnica.pptx`
            },
            {
                nombre: 'Justificación económica',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}4_2_justificacion_economica.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}4_2_justificacion_economica.pptx`
            },
            {
                nombre: 'Evaluación general del proyecto',
                urlVIDEO: `${Global.VIDEOS_PILDORAS_FORMATIVAS}4_3_evaluacion_general_proyecto.mp4`,
                urlPPT: `${Global.PRESENTACIONES_PILDORAS_FORMATIVAS}4_3_evaluacion_general_proyecto.pptx`
            }
        ]
    }
];
