import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  esUsuarioLogeado: boolean;
  usuario?: Usuario | null;


  constructor(private usuarioLocalServicio: UsuarioLocalService) {

    this.esUsuarioLogeado = false;

  }
  async ngOnInit(): Promise<void> {
    this.usuario = await this.usuarioLocalServicio.getLocalUser();
    if (this.usuario) this.esUsuarioLogeado = true;
  }



}
