import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css']
})
export class TextAreaComponent {
  @Input() placeholder: string = '';
  @Input() textAreaValor: string = '';
  @Input() textAreaLabel: string = '';
  @Output() textAreaValueEmitter = new EventEmitter<string>();

  mostrarMensaje: boolean = false;
  @Input() blur: boolean = false;
  @Input() textoErrorNoCompletado: string = '';

  @ViewChild('inputText') inputText!: ElementRef;


  setValorTextArea(value: string) {
    this.textAreaValueEmitter.emit(this.textAreaValor);
    this.comprobarSiElCampoEstaCompletado();
  }

  marcarComoNoRelleno(): void {
    this.inputText.nativeElement.classList.add('rojo');
    this.inputText.nativeElement.classList.remove('verde');
    this.mostrarMensaje = true;


  }

  marcarComoRelleno(): void {
    this.inputText.nativeElement.classList.remove('rojo');
    this.inputText.nativeElement.classList.add('verde');
    this.mostrarMensaje = false;


  }

  comprobarSiElCampoEstaCompletado(): void {
    if (!this.blur) return;

    if (this.inputText.nativeElement.value !== '') {
      this.marcarComoRelleno()
    }

    else {
      this.marcarComoNoRelleno();
    }
  }
}
