import { Component } from '@angular/core';

@Component({
  selector: 'app-condiciones-de-uso-publica',
  templateUrl: './condiciones-de-uso-publica.component.html',
  styleUrls: ['./condiciones-de-uso-publica.component.css']
})
export class CondicionesDeUsoPublicaComponent {

}
