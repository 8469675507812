<div class="newsletter">
    <div class="newsletter-filters">
        <img src="../../../assets/images/flecha_izquierda.svg" alt="">
        <span>Enero</span>
        <span>Febrero</span>
        <span>Marzo</span>
        <span>Abril</span>
        <span>Mayo</span>
        <span>Junio</span>
        <span class="newsletter-filter active">Julio</span>
        <span>Agosto</span>
        <span>Septiembre</span>
        <span>Octubre</span>
        <span>Noviembre</span>
        <span>Diciembre</span>
        <img src="../../../assets/images/flecha_derecha.svg" alt="">
        <strong>AÑO 2023</strong>
    </div>
    <div class="newsletter-content">
        <div class="newsletter-header"></div>
        <div class="newsletter-novedades">
            <img src="../../../assets/images/mensaje.svg" alt="">
            <h2>NOVEDADES</h2>
            <div class="newsletter-novedad">
                <div class="novedad">
                    <img src="../../../assets/images/newsletter/verano.svg" alt="">
                </div>
                <div class="novedad">
                    <div class="novedad-contenido">
                        <p class="novedad-texto">En este primer semestre del año, las corporaciones locales han seguido
                            confiando en nuestro programa, superando las 1.000 solicitudes. Seguiremos desarrollando
                            nuestra
                            labor de apoyo a lo largo de estos meses de verano</p>
                    </div>
                </div>
            </div>
            <div class="newsletter-novedad invertido">
                <div class="novedad">
                    <img src="../../../assets/images/newsletter/cc_ursula.svg" alt="">
                </div>
                <div class="novedad">
                    <div class="novedad-contenido">
                        <h3 class="novedad-titulo">PROYECTO DE CONCIENCIACIÓN CIUDADANA "SEMBRANDO CIUDADES SALUDABLES",
                            EN
                            SANTA ÚRSULA</h3>
                        <p class="novedad-texto">Puedes visualizar el nuevo vídeo sobre el desarrollo del proyecto de
                            concienciación ciudadana en el municipio de Santa Úrsula con el apoyo del equipo de AOT.</p>
                        <button class="novedad-boton">DESCÚBRELO</button>
                    </div>
                </div>
            </div>
        </div>




        <div class="newsletter-documentos-disponibles">
            <h3>NUEVOS DOCUMENTOS DISPONIBLES</h3>
            <p>En la plataforma de <strong>AOT</strong> en el apartado de <a
                    routerLink="/documentos-disponibles"><strong>DOCUMENTOS DISPONIBLES</strong></a>,
                durante la temporada estival se han añadido los siguientes documentos:</p>
            <ul>
                <li>Modelo de informe técnico para la modificación sustancia del PGO. Previo a la Aprobación definitiva.
                </li>
                <li>Modelo de informe técnico para la modificación sustancia del PGO. Aprobación inicial.</li>
                <li>Modelo de informe técnico para la modificación sustancia del PGO. Previo a la Aprobación inicial.
                </li>
            </ul>
            <strong>Para solicitar nuevos documentos, <a class="newsletter-documentos-disponibles-enlace"
                    routerLink="/solicitud">HAZ CLICK
                    AQUÍ</a></strong>
        </div>


        <div class="newsletter-separator"></div>

        <div class="newsletter-publicaciones">
            <img src="../../../assets/images/newsletter/publicaciones.svg" alt="">
            <h2>PUBLICACIONES E INFORMACIÓN DE INTERÉS</h2>
            <div class="newsletter-publicacion">
                <div class="publicacion-contenido">
                    <h3>BOE núm. 141, de 14 de junio de 2023, páginas 84261 a 84281</h3>
                    <p>Real Decreto 445/2023, de 13 de junio, por el que se <strong>modifican los anexos I, II y III de
                            la Ley 21/2013, de 9 de
                            diciembre, de evaluación ambiental.</strong>
                        La metodología utilizada para el análisis y modificación de los epígrafes de los anexos de la
                        Ley 21/2013, de 9 de
                        diciembre, parte de la determinación de los impactos significativos típicos de cada tipo de
                        proyecto. Esta labor se ha
                        basado en los criterios de selección que contempla el anexo III de la Directiva 2011/92/UE del
                        Parlamento Europeo y del
                        Consejo, de 13 de diciembre de 2011, que regula los criterios a aplicar en el establecimiento de
                        umbrales por parte de
                        los Estados miembros para la determinación del régimen de evaluación ambiental aplicable a los
                        distintos tipos de
                        proyectos.</p>
                </div>
                <a class="publicacion-boe" href="https://www.boe.es/eli/es/rd/2023/06/13/445" target="_blank"><img
                        src="../../../assets/images/BOE.svg" alt=""></a>

            </div>
            <div class="newsletter-publicacion">
                <div class="publicacion-contenido">
                    <h3>BOC Nº 122. Viernes 23 de junio de 2023 - 2035</h3>
                    <p>DECRETO 102/2023, de 15 de junio, por el que se aprueba definitivamente el <strong>Plan
                            Hidrológico Insular de la Demarcación
                            Hidrográfica de La Gomera, tercer ciclo (2021-2027).</strong> Las actuaciones que
                        desarrollan las medidas que conforman el
                        programa de medidas del Plan Hidrológico Insular de la Demarcación Hidrográfica de La Gomera,
                        tercer ciclo (2021-2027),
                        estarán supeditadas a la disponibilidad presupuestaria de la Administración pública competente
                        para su realización.</p>
                </div>
                <a class="publicacion-boe" href="https://www.gobiernodecanarias.org/boc/archivo/2023/123/pda/001.html"
                    target="_blank"><img src="../../../assets/images/newsletter/BOC.jpg" alt=""></a>
            </div>


        </div>

        <div class="newsletter-separator"></div>

        <div class="newsletter-lecturas-recomendadas">
            <img src="../../../assets/images/newsletter/lecturas.svg" alt="">
            <h2>LECTURAS RECOMENDADAS</h2>
            <div class="newsletter-lectura">
                <div class="lectura-contenido">
                    <h3>Estrategia Nacional de Infraestructura Verde y de la Conectividad y Restauración Ecológicas</h3>
                    <p>InfraestructuraVerde y de la Conectividad y Restauración Ecológicas es el documento de
                        planificación< estratégica que regula la implantación y el desarrollo de la infraestructura
                            Verde en España, estableciendo un marco administrativo y técnico armonizado para el conjunto
                            del territorio español, incluyendo las aguas marítimas bajo soberanía o jurisdicción
                            nacional.</p>
                </div>
                <a class="lectura-enlace" href="">SABER MÁS <span><img src="../../../assets/images/flecha_derecha.svg"
                            alt=""></span></a>
            </div>
        </div>

    </div>







    <div class="newsletter-footer">
        <p>oficinatecnica&#64;gesplan.es</p>
    </div>
</div>