import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { TEXTS } from 'src/app/helpers/texts';
import { Usuario } from 'src/app/models/usuario';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { closeModal } from 'src/app/tools/modal.tool';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public TEXTS: typeof TEXTS = TEXTS;
  public submitted: boolean = false;
  public resetMode: boolean = false;
  public resetEmail: string = '';
  public mostrarPassword: boolean = false;
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  public resetForm: FormGroup = new FormGroup({
    reference: new FormControl(''),
  });


  constructor(public _router: Router,
    private usuarioLocalServicio: UsuarioLocalService,
    private formBuilder: FormBuilder,
    private authService: AuthService) {


  }

  ngOnInit() {
    this.isLogin();
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.resetForm = this.formBuilder.group({
      reference: ['', Validators.required],
    })

  }
  get f(): { [key: string]: AbstractControl } { return this.loginForm.controls; }
  get ff(): { [key: string]: AbstractControl } { return this.resetForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.login(this.loginForm.value).subscribe(
      (response) => {
        if (response.status === "success") {
          this.usuarioLocalServicio.setUser(response.data as Usuario);
          this.closeModal();

          this._router.navigate(['presentacion']);
        } else if (response.code === 401) {
          // TODO: mostrar mensaje de error usuario o contraseña no válidos
          console.log(response);
          mostrarMensajeDeAlerta('Error', 'Usuario o contraseña no válidos', 'warning', 'Aceptar', '', true, false);
        } else {
          // TODO: mostrar mensaje de error
          console.log(response.message);
        }
      });
  }

  private isLogin() {
    const state = this._router.routerState.snapshot;
    if (state.url === "/" && this.authService.checkLooggedIn(state)) this._router.navigate(['presentacion']);


  }

  async closeModal(): Promise<void> {
    this.resetMode = false;
    closeModal('loginModal');

  }


  cambiarModoDeRecuperacionDePassword(): void {
    this.resetMode = !this.resetMode;
    if (this.resetMode) {
      this.resetLoginForm();
    } else {
      this.resetResetForm();
    }
  }

  private resetLoginForm() {
    this.loginForm.reset(); // Restablecer los valores y estado del formulario de login
  }

  private resetResetForm() {
    this.resetForm.reset(); // Restablecer los valores y estado del formulario de recuperación de contraseña
  }



  resetPassword(): void {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }

    this.authService.requestResetPassword(this.resetForm.value.reference).subscribe(
      (response) => {

        mostrarMensajeDeAlerta('Éxito', 'Si la dirección de correo existe en el sistema, recibirás un correo electrónico para reestablecer la contraseña', 'success', 'Aceptar', '', true, false)
        this.closeModal();
        /*
        if (response.status === "success" || response.code === 401) {
          mostrarMensajeDeAlerta('Éxito', 'Si el usuario existe en el sistema, se enviará un email para reestablecer la contraseña', 'Succe', 'Aceptar', '', true, false)
          this.closeModal();
        } else if (response.code === 401) {
          // TODO: mostrar mensaje de error usuario o contraseña no válidos
          console.log(response.message);
          mostrarMensajeDeAlerta('Éxito', 'Si el usuario existe en el sistema, se enviará un email para reestablecer la contraseña', 'warning', 'Aceptar', '', true, false)
        } else {
          // TODO: mostrar mensaje de error
          console.log(response.message);
          mostrarMensajeDeAlerta('Error', 'Usuario inválido', 'warning', 'Aceptar', '', true, false)

        }*/
      });

  }

  mostrarPasswordIntroducidoPorElUsuario(): void {
    this.mostrarPassword = !this.mostrarPassword;
    const password = document.getElementById('password') as HTMLInputElement;

    password.type = this.mostrarPassword ? 'text' : 'password';
  }

  async limpiarUsuarioLocal(): Promise<void> {
    await this.usuarioLocalServicio.eliminarUsuarioLocal();
  }


  async registrarNuevoUsuario(): Promise<void> {
    await this.limpiarUsuarioLocal();
    this.closeModal();
    this._router.navigate(['registro'])

  }
}
