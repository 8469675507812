export const removeClassForAllClasName = (classToSearch: string, classToRemove: string): Promise<boolean> => {
    let items = document.querySelectorAll(`.${classToSearch}`);
    if (!items) return Promise.resolve(false);
    items.forEach(item => {
        item.classList.remove(classToRemove);
    })
    return Promise.resolve(true);
}

export const addClassToElement = (classToAdd: string, targetID: string): Promise<boolean> => {
    let item = document.getElementById(targetID);
    if (!item) return Promise.resolve(false);
    item.classList.add(classToAdd);
    return Promise.resolve(true);

}


export const toogleClassToElement = (classToToggle: string, targetID: string): Promise<boolean> => {
    let item = document.getElementById(targetID);
    if (!item) return Promise.resolve(false);
    item.classList.toggle(classToToggle);
    return Promise.resolve(true);
}