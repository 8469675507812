import { Isla } from '../models/isla';
export class Municipio {
  constructor(
    public id: number = -1,
    public municipio: string = '',
    public cod_municipio: string = '',
    public cod_normalizado: string = '',
    public estado: number = -1,
    public isla: Isla = new Isla(),
    public codigo_aot: string = '',
    public isla_id: number = -1
  ) { }
}