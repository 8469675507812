
export class Expediente {
    constructor(
        public estado_id: number = -1,
        public expediente: string = '',
        public fecha_fin: Date = new Date(),
        public fecha_inicio: Date = new Date(),
        public id: number = -1,
        public solicitud_id: number = -1,
        public ir: number = -1,
        public nota: string = '',
        public resumen: string = '',
        public revision: boolean = false,
        public tags: string = '',
        public validado: any = null,
    ) { }
}
