/**TODO */

const parseURL = async (url: string) => {
    let urlParsed = url.replace('/', '');
    if (urlParsed === 'requests') urlParsed = 'dashboard';
    if (urlParsed.startsWith('services')) urlParsed = 'services/users/reset-password';
    return urlParsed;
}


export { parseURL }