<div class="main-container">
    <section>
        <h2>¿Qué son las cookies?</h2>
        <p>Las cookies son pequeños fragmentos de texto que los sitios web que visitas envían al navegador. Permiten que
            los
            sitios web recuerden información sobre tu visita lo que puede hacer que sea más fácil volver a visitar los
            sitios y hacer que estos te resulten más útiles.</p>

    </section>

    <section>
        <h2>Tipos de cookies utilizamos</h2>
        <p><strong>Tipos de cookies según quien sea quien gestione el dominio desde donde se envían las cookies y trate
                los
                datos que se obtengan se pueden distinguir dos tipos:</strong></p>
        <ul>
            <li><strong>Cookies propias:</strong> son aquéllas que se envían al equipo terminal del usuario desde un
                equipo o
                dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
            </li>
            <li><strong>Cookies de terceros:</strong> aquéllas que se envían al equipo terminal del usuario desde un
                equipo o
                dominio que no es gestionado por el editor sino por otra entidad que trata los datos obtenidos a través
                de
                las
                cookies.</li>
        </ul>
        <p><strong>Tipos de cookies según su finalidad:</strong></p>
        <ul>
            <li>
                <strong>Cookies técnicas:</strong> son aquellas que permiten al usuario la navegación a través de una
                página
                web
                plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan
                incluyendo
                aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus
                funciones y
                servicios como por ejemplo controlar el tráfico y la comunicación de datos identificar la sesión acceder
                a
                partes de acceso restringido recordar los elementos que integran un pedido realizar el proceso de compra
                de
                un
                pedido gestionar el pago controlar el fraude vinculado a la seguridad del servicio realizar la solicitud
                de
                inscripción o participación en un evento contar visitas a efectos de la facturación de licencias del
                software
                con el que funciona el servicio (sitio web plataforma o aplicación) utilizar elementos de seguridad
                durante
                la
                navegación almacenar contenidos para la difusión de vídeos o sonido habilitar contenidos dinámicos (por
                ejemplo
                animación de carga de un texto o imagen) o compartir contenidos a través de redes sociales.
            </li>

            <li><strong>Cookies de preferencias o personalización:</strong> son aquellas que permiten recordar
                información
                para
                que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia
                de
                la
                de otros usuarios como por ejemplo el idioma el número de resultados a mostrar cuando el usuario realiza
                una
                búsqueda el aspecto o contenido del servicio en función del tipo de navegador a través del cual el
                usuario
                accede al servicio o de la región desde la que accede al servicio etc.
            </li>
            <li><strong>Cookies de análisis o medición:</strong> son aquellas que permiten al responsable de las mismas
                el
                seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas
                incluida
                la cuantificación de los impactos de los anuncios. La información recogida mediante este tipo de cookies
                se
                utiliza en la medición de la actividad de los sitios web aplicación o plataforma con el fin de
                introducir
                mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</li>
            <li><strong>Cookies de publicidad comportamental:</strong> son aquellas que almacenan información del
                comportamiento
                de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación lo que
                permite
                desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
            <li><strong>Tipos de cookies según el plazo de tiempo que permanecen activadas:</strong></li>
            <li><strong>Cookies de sesión:</strong> Son un tipo de cookies diseñadas para recabar y almacenar datos
                mientras
                el
                usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa
                conservar
                para
                la prestación del servicio solicitado por el usuario en una sola ocasión (por ejemplo una lista de
                productos
                adquiridos) y desaparecen al terminar la sesión.</li>
            <li><strong>Cookies persistentes:</strong> Son un tipo de cookies en el que los datos siguen almacenados en
                el
                terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie y
                que
                puede ir de unos minutos a varios años.</li>

        </ul>
    </section>


    <section>

        <h2>¿Qué cookies utilizamos?</h2>
        <p>En esta Plataforma utilizamos cookies de terceros en concreto las cookies de Google Analytics que se usan
            medir
            las interacciones de usuario en los sitios web. Google Analytics es una herramienta sencilla y fácil de usar
            que
            ayuda a los propietarios de sitios web a medir cómo interactúan los usuarios con el contenido del sitio.
            Cuando
            un usuario navega por las páginas de un sitio web Google Analytics proporciona al propietario de dicho sitio
            etiquetas JavaScript (bibliotecas) para registrar la información sobre la página que ha visto un usuario;
            por
            ejemplo la URL de la página. Las bibliotecas JavaScript de Google Analytics usan cookies HTTP para
            "recordar" lo
            que ha hecho un usuario en páginas o en interacciones con el sitio web anteriores. Las cookies de Google
            Analytics incluyen varias cookies HTTPS que recopilan datos diversos sobre los usuarios de tu sitio web para
            ofrecerte información sobre el rendimiento de tu dominio. Las cookies de Google Analytics incluyen ga
            (cookie
            utilizada para distinguir a los usuarios individuales de tu dominio) _gid (cookie utilizada para distinguir
            a
            los usuarios individuales de tu dominio) _gat (cookie utilizada para para acelerar las tasas de solicitud)
            AMP_TOKEN (cookie que contiene un ID único asociado a cada usuario de tu dominio) y _gac (cookie que
            contiene un
            ID único que hace que Google Analytics y Ads funcionen juntos).</p>

        <table border="1">
            <thead>
                <tr>
                    <th>Nombre de la cookie</th>
                    <th>Tipo de cookie</th>
                    <th>Titularidad</th>
                    <th>Finalidad</th>
                    <th>Duración</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>_ga</td>
                    <td>Google Analytics</td>
                    <td>Terceros</td>
                    <td>Permite a los servicios distinguir a un visitante de otro</td>
                    <td>2 años</td>
                </tr>
                <tr>
                    <td>__utmz</td>
                    <td>Cookies de sesión</td>
                    <td>Terceros</td>
                    <td>Permiten recabar y almacenar datos mientras el Usuario accede a una página web.</td>
                    <td>6 meses</td>
                </tr>
                <tr>
                    <td>__utma</td>
                    <td>Cookies de sesión</td>
                    <td>Terceros</td>
                    <td>Permiten recabar y almacenar datos mientras el Usuario accede a una página web.</td>
                    <td>2 años</td>
                </tr>
                <tr>
                    <td>__utmt</td>
                    <td>Cookies de sesión</td>
                    <td>Terceros</td>
                    <td>Permiten recabar y almacenar datos mientras el Usuario accede a una página web.</td>
                    <td>10 minutos</td>
                </tr>
            </tbody>
        </table>


    </section>


    <section>
        <h2>¿Quién utiliza las cookies?</h2>
        <p>Las cookies que están en la Plataforma –Cookies de Analytics- son recogidas por terceros y a las cuales
            tenemos
            acceso para su utilización por parte de Gesplan. Como ya se ha referenciado estas cookies permiten
            cuantificar
            el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los
            usuarios
            del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la
            oferta
            de productos o servicios que le ofrecemos.</p>

    </section>


    <section>
        <h2>Configuración de las Cookies.</h2>
        <p>Si la persona usuaria deseara permitir bloquear o eliminar las cookies deberá seguir los siguientes pasos en
            función del navegador que se use:</p>
        <ul>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Internet Explorer</li>
            <li>Safari</li>
            <li>Safari para IOS (iPhone iPad)</li>
        </ul>
    </section>



    <section>
        <h2>Deshabilitación de las cookies.</h2>
        <p>Si la persona usuaria deseara deshabilitar las cookies el servicio prestado en la Plataforma AOT no se vería
            afectado y seguiría funcionando con normalidad ya que la única cookie que utilizamos es la de Google
            Analytics
            que funciona aun con la cookie desactivada.</p>
    </section>

    <section>
        <h2>Aceptación rechazo o modificación de cookies.</h2>
        <p>Al acceder a la Plataforma AOT aparecerá automáticamente un aviso en donde la persona usuaria podrá Aceptar o
            Rechazar las Cookies. Si la persona usuaria hace click en la opción “Aceptar” se entenderá que se ha
            obtenido el
            consentimiento por parte de ésta. Asimismo si tras este aviso la persona usuaria no aceptara las cookies sin
            haber hecho click se entenderá que se ha obtenido dicho consentimiento.</p>
    </section>

    <section>
        <h2>Actualización de la presente Política de Cookies.</h2>
        <p>Esta Política de Cookies podrá ser modificada en cualquier momento así como las cookies utilizadas por lo que
            se
            recomienda revisar la Política de Cookies que cada vez que acceda a la Plataforma AOT con el fin de estar
            debidamente informado. Estos cambios podrán ser motivados por exigencias normativas o por instrucciones de
            la
            Agencia Estatal de Protección de Datos.</p>

    </section>



</div>