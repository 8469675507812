import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Global } from '../helpers/global';
import { Observable, lastValueFrom, shareReplay } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Solicitud } from '../models/solicitud';
import { RespuestaSolicitudApi } from '../models/respuesta.api';
import { UsuarioLocalService } from './usuario-local.service';
import { Disponible } from '../models/disponible';
import { Tematica } from '../models/tematica';
import { TematicaService } from './tematica.service';
import { SolicitudesMunicipio } from '../models/solicitudes-municipio';
import { SolicitudesAnuales } from '../models/solicitudes-anuales';



@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private httpClient: HttpClient, private authService: AuthService, private localUserService: UsuarioLocalService
    , private tematicaServicio: TematicaService) {

  }

  /** TODO - NECESITA SER REFACTORIZADO */

  async getUserRequest(): Promise<any[]> {
    const usuario = await this.localUserService.getLocalUser();

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario?.access_token}`,
    });

    try {
      const url = Global.url + 'solicitudes/?' + 'sort=Solicitudes.id&direction=DESC&include_associations=expedientes, tematicas, estados'

      const respuesta: Observable<{ data: Solicitud[] }> = this.httpClient.get<{ data: Solicitud[] }>(url, { headers });
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      return [];
    }
  }

  /** TODO - NECESITA SER REFACTORIZADO */

  async crearSolicitud(solicitud: Solicitud, archivo?: File): Promise<RespuestaSolicitudApi | null> {


    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const formData = new FormData();

    formData.append('descripcion', solicitud.descripcion);
    formData.append('documentacion', solicitud.documentacion);
    formData.append('estado_id', solicitud.estado_id.toString());
    formData.append('solicita', solicitud.solicita);
    formData.append('tematica_id', solicitud.tematica.id.toString());
    formData.append('referencia_catastral', solicitud.referencia_catastral);
    if (archivo)
      formData.append('solicitud_adjuntos[][adjunto]', archivo)


    const respuesta = await lastValueFrom(this.httpClient.post(Global.url + Global.URL_SOLICITUD, formData, { headers })) as RespuestaSolicitudApi;

    return respuesta;

  }


  /**DEPRECATED */
  async subirAdjuntoAUnaSolicitud(archivo: File, idSolicitud: number): Promise<boolean> {

    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return false;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const url = `/${idSolicitud}/solicitud-adjuntos`;
    const formData = new FormData();

    formData.append('usuario_id', usuario.id);
    formData.append('adjunto', archivo);


    const respuesta = await lastValueFrom(this.httpClient.post(Global.url + Global.URL_SOLICITUD + url, formData, { headers })) as RespuestaSolicitudApi;
    if (respuesta.status !== 'success') {
      return false
    }

    return true;

  }


  async getAdjuntoSolicitudUsuario(idSolicitud: number): Promise<RespuestaSolicitudApi | null> {
    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const url = `/${idSolicitud}/solicitud-adjuntos`;
    const respuesta = await lastValueFrom(this.httpClient.get(Global.url + Global.URL_SOLICITUD + url, { headers })) as RespuestaSolicitudApi;
    console.log(respuesta)
    return respuesta;
  }





  async eliminarAdjuntoSolicitudUsuario(idAdjunto: number): Promise<boolean> {
    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return false;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const url = `solicitud-adjuntos/${idAdjunto}`;
    const respuesta = await lastValueFrom(this.httpClient.delete(Global.url + url, { headers })) as RespuestaSolicitudApi;
    if (respuesta.status === 'success') return true;
    return false;

  }




  /** TODO - NECESITA SER REFACTORIZADO */
  async crearSolicitudAPartirDeUnDisponible(disponible: Disponible): Promise<boolean | null> {
    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return null;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    let solicitud = new Solicitud();
    solicitud.solicita = disponible.disponible_categoria.categoria;
    solicitud.descripcion = disponible.disponible;
    let tematicas: Tematica[] = await this.tematicaServicio.getTematica(5);
    solicitud.tematica = tematicas[0];

    const respuesta = await lastValueFrom(this.httpClient.post(Global.url + Global.URL_SOLICITUD, solicitud.parsearSolicitudParaLaAPI(usuario.id), { headers })) as RespuestaSolicitudApi;
    const nuevaSolicitud: Solicitud = respuesta.data as Solicitud;
    const nuevaSolicitudID: number = nuevaSolicitud.id;
    const respuestaExpediente: RespuestaSolicitudApi = await lastValueFrom(this.httpClient.post(Global.url + Global.URL_EXPEDIENTES + '/iniciar', { 'solicitud_id': nuevaSolicitudID, 'disponible_id': disponible.id }, { headers })) as RespuestaSolicitudApi;

    console.log(respuestaExpediente)
    if (respuestaExpediente.status !== 'success') {
      return false
    }


    return true;

  }



  /**OBTENER INFORME DE SOLICITUD */

  getPDFDeSolicitud(idExpediente: number): Observable<any> {
    return this.httpClient.get(Global.url + 'expedientes/pdf/' + idExpediente, { responseType: 'blob' }).pipe(
      shareReplay(1)
    );
  }

  async actualizarEstadoDeSolicitud(idSolicitud: number): Promise<RespuestaSolicitudApi> {

    const usuario = await this.localUserService.getLocalUser();
    if (!usuario) return Promise.reject(null);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${usuario}`,
    });

    const respuesta = await lastValueFrom(this.httpClient.put(Global.url + Global.URL_SOLICITUD + '/' + idSolicitud, {
      estado_id: 5
    }, { headers })) as RespuestaSolicitudApi;

    if (respuesta.status === 'success') return Promise.resolve(respuesta.data);
    return Promise.reject(false)

  }




  /** OBTENER SOLICITUDES POR MUNICIPIOS */

  async getSolicitudesPorMunicipio(): Promise<SolicitudesMunicipio[]> {
    try {
      const url = Global.url + Global.URL_SOLICITUD + '/contar?sort=municipio&direction=DESC';

      const respuesta: Observable<{ data: SolicitudesMunicipio | SolicitudesMunicipio[] }> = this.httpClient.get<{ data: SolicitudesMunicipio | SolicitudesMunicipio[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      return (Array.isArray(resultado.data) ? resultado.data : [resultado.data]) as SolicitudesMunicipio[];
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }

  }

  async getSolicitudesPorAnio(): Promise<SolicitudesAnuales[]> {
    try {
      const url = Global.url + Global.URL_SOLICITUD + Global.URL_SOLICITUDES_ANUALES;

      const respuesta: Observable<{ data: SolicitudesAnuales | SolicitudesAnuales[] }> = this.httpClient.get<{ data: SolicitudesAnuales | SolicitudesAnuales[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return (Array.isArray(resultado.data) ? resultado.data : [resultado.data]) as SolicitudesAnuales[];

    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];

    }
  }

}
