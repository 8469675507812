<footer id="footer">
    <a [routerLink]="['/condiciones-de-uso']" class="footer-link" *ngIf="esUsuarioLogeado">Condiciones de uso</a>
    <a [routerLink]="['/condiciones-de-uso-publica']" class="footer-link" *ngIf="!esUsuarioLogeado">Condiciones de
        uso</a>
    <div class="divider"></div>
    <a class="footer-link" [routerLink]="['/politica-de-privacidad']" *ngIf="esUsuarioLogeado">Política de
        privacidad</a>
    <a class="footer-link" [routerLink]="['/politica-de-privacidad-publica']" *ngIf="!esUsuarioLogeado">Política de
        privacidad</a>
    <div class="divider"></div>
    <a [routerLink]="['/politica-de-cookies']" class="footer-link" *ngIf="esUsuarioLogeado">Política de cookies</a>
    <a [routerLink]="['/politica-de-cookies-publica']" class="footer-link" *ngIf="!esUsuarioLogeado">Política de
        cookies</a>
</footer>