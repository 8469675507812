import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaApi } from '../models/respuesta-api';
import { lastValueFrom, Observable } from 'rxjs';
import { USUARIO } from '../helpers/global';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  async getInfoUsuario(usuarioID: string): Promise<Usuario> {
    const url: string = USUARIO.URL_INFO_USUARIO + usuarioID + USUARIO.INFO_USUARIO_ASOCIACIONES;
    try {
      const respuesta: Observable<{ data: Usuario }> = this.http.get<{ data: Usuario }>(url);
      const resultado: RespuestaApi<Usuario> = await lastValueFrom(respuesta) as RespuestaApi<Usuario>;
      if (resultado.status === 'success') return Promise.resolve(resultado.data as Usuario);

      else return Promise.reject(null)
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return Promise.reject(null);
    }
  }
}
