import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { RespuestaApi } from 'src/app/models/respuesta-api';
import { AuthService } from 'src/app/services/auth.service';
import { ValidarUsuarioService } from 'src/app/services/validar-usuario.service';
import { verificarEmail } from 'src/app/tools/email.tool';

@Component({
  selector: 'app-validar-usuario',
  templateUrl: './validar-usuario.component.html',
  styleUrls: ['./validar-usuario.component.css']
})
export class ValidarUsuarioComponent {
  token: string | null = null;
  mostrarInputParaEmailNoVerificado: boolean = false;
  mostrarMensajeDeErrorEmail: boolean = false;
  emailParaVerificar: string = '';
  mensaje: string = '';

  constructor(private route: ActivatedRoute,
    private validarUsuario: ValidarUsuarioService,
    private router: Router,
    private authService: AuthService) {

    // Obtén el valor del parámetro 'token' de la URL
    this.token = this.route.snapshot.paramMap.get('token');
    if (this.token) {
      this.validarCuentaDeUsuario(this.token);
    }
  }



  async validarCuentaDeUsuario(token: string): Promise<void> {
    const respuestaValidacion: RespuestaApi = await this.validarUsuario.verificarUsuarioEnELServidor(token)


    if (respuestaValidacion.status === 'success') {
      this.mensaje = 'Su email ha sido verificado correctamente.\nLe daremos acceso lo antes posible.\nPor favor espere su correo de bienvenida.'
      //await mostrarMensajeDeAlerta('Usuario verificado', 'La cuenta de email se ha verificado, ya puede iniciar sesión', 'success', 'Aceptar', '', true, false)
    }
    else if (respuestaValidacion.message?.toLocaleLowerCase() === 'token already expired') {
      this.mostrarInputParaEmailNoVerificado = true;
      this.mensaje = 'Enlace caducado, vuelva a enviar su email a través del siguiente formulario'
      // await mostrarMensajeDeAlerta('Error, enlace caducado', 'Vuelva a enviar su email a través del siguiente formulario', 'warning', 'Aceptar', '', true, false)
    }
    else {
      this.mensaje = 'Error al verificar la cuenta.\nPóngase en contacto con el administrador'

      // await mostrarMensajeDeAlerta('Error al verificar la cuenta', 'Póngase en contacto con el administrador', 'warning', 'Aceptar', '', true, false)
    }

  }


  async enviarNuevaValidacionAlUsuario(event: Event): Promise<void> {
    if (!(this.emailParaVerificar)) return;
    event.preventDefault();
    this.authService.validateAccountRequest(this.emailParaVerificar).subscribe(async resp => {
      if (resp.status === 'success') {
        await mostrarMensajeDeAlerta('Éxito', 'Se ha enviado un nuevo enlace de validación, porfavor revise su email', 'success', 'Aceptar', '', true, false)
      }
      else {
        await mostrarMensajeDeAlerta('Error', 'Póngase en contacto con el administrador', 'warning', 'Aceptar', '', true, false)

      }

      this.router.navigate(['']);
    })
  }


  comprobarEmail(event: Event): boolean {
    const email = (event.target as HTMLInputElement)?.value;
    if (!email) {
      return false;
    }

    const emailValido = verificarEmail(email);

    if (!emailValido) {
      this.mostrarMensajeDeErrorEmail = true;
      document.getElementById('enviarEmailParaVerificar')?.classList.add('rojo')
      document.getElementById('enviarEmailParaVerificar')?.classList.remove('verde')
      return false;
    }
    else {
      this.mostrarMensajeDeErrorEmail = false;
      document.getElementById('enviarEmailParaVerificar')?.classList.remove('rojo')
      document.getElementById('enviarEmailParaVerificar')?.classList.add('verde')

      return true;
    }

  }



}
