import { Component, ElementRef, ViewChild } from '@angular/core';
import { Global } from 'src/app/helpers/global';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  urlPlaneamiento: string = Global.ARCHIVO_PLANEAMIENTO;

  showHomeInfo = false;

  openLoginModal(): void {
    let modal = document.getElementById("loginModal");
    modal!.style.display = "flex";

  }

  closeLoginModal(): void {
    let modal = document.getElementById("loginModal");
    document.getElementsByClassName("close")[0] as HTMLElement;
    modal!.style.display = "none";

  }


}
