import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.css']
})
export class SidebarFilterComponent {

  @Input() title: string = '';

  @Input() selectTitle: string = '';
  @Input() selectVisible: boolean = false;
  @Input() selectValues: string[] = [];

  @Input() dateFromTitle: string = '';
  @Input() dateFromVisible: boolean = false;

  @Input() checkboxTitle: string = '';
  @Input() checkboxVisible: boolean = false;






}
