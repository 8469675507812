<div class="who-menu" id="who-menu">
    <a #participandoAOT class="secondary-link who-menu-link selected" id="participando-aot"
        (click)="materialTransversalSeleccionada(participandoAOT?.id)">Participando con AOT</a>
    <a #creandoConciencia class="secondary-link who-menu-link" id="creando-conciencia"
        (click)="materialTransversalSeleccionada(creandoConciencia?.id)">Creando
        conciencia</a>
    <a #mapa class="secondary-link who-menu-link " id="mapa" (click)="materialTransversalSeleccionada(mapa?.id)"
        [ngClass]="{ 'disabled-link': versionPro}">Mapa
        de participación</a>
</div>


<div class="concienciacion-ciudadana">


    <div id="participando-aot-seccion" class="materias-transversales-seccion">
        <h2 class="concienciacion-ciudadana-seccion-titulo">Participando con AOT</h2>
        <div class="concienciacion-ciudadana-seccion-texto-explicativo">
            <p>Dentro de Participando con AOT, se ha trabajado en procesos participativos y acciones de concienciación
                ciudadana en materia de urbanismo. Aquí, puedes consultar algunas acciones realizadas con distintos
                Ayuntamientos en los últimos años.
            </p>
        </div>

        @for (materiaTransversal of materiasTransveraslesParticipandoAOT; track materiaTransversal.bloque){
        <div [class.revertido]="materiaTransversal.bloque % 2 === 0" class="concienciacion-ciudadana-seccion">
            <img src={{materiaTransversal.imagen}} alt="">
            <div class="concienciacion-ciudadana-seccion-info">
                <small>{{materiaTransversal.categoria}}</small>
                <h1>{{materiaTransversal.titulo}}</h1>
                <p>{{materiaTransversal.descripcion}}</p>
                <ul class="concienciacion-ciudadana-seccion-info-lista">
                    @for(elemento of materiaTransversal.lista; track elemento){
                    <li class="concienciacion-ciudadana-seccion-info-lista-elemento">{{elemento}}</li>
                    }
                </ul>

                <div class="concienciacion-ciudadana-seccion-iconos">
                    <img src="../../../assets/images/videooscuro.png" alt=""
                        (click)="openVideo(materiaTransversal.urlVIDEO)">
                </div>
            </div>

        </div>
        }
    </div>



    <div id="creando-conciencia-seccion" class="materias-transversales-seccion">
        <h2 class="concienciacion-ciudadana-seccion-titulo">Creando conciencia</h2>
        <div class="concienciacion-ciudadana-seccion-texto-explicativo">
            <p>En esta sección encontrarás material de concienciación que busca facilitar un acercamiento a la población
                sobre temas relacionados con el urbanismo y la planificación territorial.
            </p>
        </div>

        @for (creandoConciencia of materiasTransveraslesCreandoConciencia; track creandoConciencia.bloque){
        <div [class.revertido]="creandoConciencia.bloque % 2 === 0" class="concienciacion-ciudadana-seccion">
            <img src={{creandoConciencia.imagen}} alt="">
            <div class="concienciacion-ciudadana-seccion-info">
                <small>{{creandoConciencia.categoria}}</small>
                <h1>{{creandoConciencia.titulo}}</h1>
                <p>{{creandoConciencia.descripcion}}</p>
                <ul class="concienciacion-ciudadana-seccion-info-lista">
                    @for(elemento of creandoConciencia.lista; track elemento){
                    <li class="concienciacion-ciudadana-seccion-info-lista-elemento">{{elemento}}</li>
                    }
                </ul>

                <div class="concienciacion-ciudadana-seccion-iconos">
                    <img src="../../../assets/images/videooscuro.png" alt=""
                        (click)="openVideo(creandoConciencia.urlVIDEO)">
                </div>
            </div>

        </div>
        }
    </div>





    <div id="mapa-seccion" class="materias-transversales-seccion " [ngClass]="{ 'disabled-link': versionPro}">

        <h2 class="concienciacion-ciudadana-seccion-titulo">Mapa de participación</h2>
        <div class="concienciacion-ciudadana-seccion-texto-explicativo">
            <p>Mapeo de experiencias relacionadas con la participación ciudadana en materia de urbanismo impulsadas
                desde la
                administración pública en Canarias.</p>
        </div>

        <img class="imagen-provisional" src="../../../assets/images/imagen_provisional_2.png" alt="">
    </div>




</div>