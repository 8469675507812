import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tematica } from '../models/tematica';
import { TEMATICAS } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';
import { TEXTS } from '../helpers/texts';

@Injectable({
  providedIn: 'root'
})
export class TematicaService {


  constructor(private httpClient: HttpClient) { }

  async getTematica(codigoTematica?: number): Promise<Tematica[]> {
    try {
      const url = codigoTematica
        ? TEMATICAS.URL + codigoTematica
        : TEMATICAS.URL;

      const respuesta: Observable<{ data: Tematica | Tematica[] }> = this.httpClient.get<{ data: Tematica | Tematica[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      // Si la respuesta es un solo objeto, envuélvelo en un array
      const tematicas: Tematica[] = Array.isArray(resultado.data) ? resultado.data : [resultado.data];

      return new Promise((resolve) => {
        resolve(tematicas);
      });
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }



  async filtroTematicaPorNombre(nombreTematica: string, tematicas: Tematica[]): Promise<Tematica | undefined> {
    return Promise.resolve(tematicas.find(tematica => tematica.tematica === nombreTematica));
  }




  async getTematicasUsuarioSinPrivilegios(): Promise<Tematica[]> {
    const tematicas: Tematica[] = await this.getTematica();
    return new Promise((resolve) => {
      resolve(
        [...tematicas.filter(tematica => tematica.id > 0 && tematica.id < 4)])
    });
  }


  async getTematicasIncluyendoLaOpcionTodos(): Promise<Tematica[]> {
    const tematicas: Tematica[] = await this.getTematica();
    return new Promise((resolve) => {
      resolve(
        [...tematicas.filter(tematica => tematica.id !== 4).sort((tematica1, tematica2) => {
          if (tematica1.tematica < tematica2.tematica) return -1;
          else return 1
        }), new Tematica(-1, 'Todos', true)])
    });
  }





  getNombreTematicasNuevaSolicitud(tematicas: Tematica[]): string[] {
    return tematicas
      .filter(tematica => tematica.tematica !== 'Formacion' && tematica.tematica !== 'Disponibles')
      .map(tematicas => tematicas.tematica);
  }





  // REVISAR

  getNombreTematicas(tematicas: Tematica[]): string[] {
    return tematicas
      .map(tematicas => tematicas.tematica);
  }



  getIdTematica = (tematicas: Tematica[], nombreTematica: string): number | null => {
    const tematicaEncontrada = tematicas.find((tematica) => tematica.tematica === nombreTematica);
    return tematicaEncontrada ? tematicaEncontrada.id : null;
  };


  getInformacionAdicionalTematica(nombreTematica: string): string {
    return TEXTS.NUEVAS_SOLICITUDES[nombreTematica as keyof typeof TEXTS.NUEVAS_SOLICITUDES]
  }
}
