import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Estado } from 'src/app/models/estado';
import { Solicitud } from 'src/app/models/solicitud';
import { Tematica } from 'src/app/models/tematica';
import { Usuario } from 'src/app/models/usuario';
import { EstadoService } from 'src/app/services/estado.service';
import { RequestService } from 'src/app/services/request.service';
import { TematicaService } from 'src/app/services/tematica.service';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { ComunicacionService } from 'src/app/servicios/comunicacion.service';
import { RequestSignalService } from 'src/app/signals/request.signal.service';
import { formatDateToYYYYMMDD, verificarRangoDeFechas } from 'src/app/tools/date.tool';
import { toogleClassToElement } from 'src/app/tools/html-elements.tool';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  showOption = 'requests';
  usuario: Usuario | null = null;
  tematicas: Tematica[] = [];
  estados: Estado[] = [];
  patronDeBusqueda = '';
  cargando = true;
  reset = false;

  solicitudesDelUsuario: Solicitud[] = [];
  solicitudesDelUsuarioFiltradas: Solicitud[] = [];


  filtrosSolicitudes = {
    estados: null as number | null,
    tematica: null as number | null,
    fechaInicio: null as string | null,
    fechaFin: null as string | null,
    pendienteDescarga: null as number | null
  };


  private suscripcionRequestServiceSignal?: Subscription;


  constructor(private tematicasServicio: TematicaService,
    private requestService: RequestService,
    private usuarioLocalService: UsuarioLocalService,
    private estadoServicio: EstadoService,
    private comunicacionServicio: ComunicacionService,
    private requestSignalServicio: RequestSignalService
  ) {
  }


  async ngOnInit(): Promise<void> {


    this.tematicas = await this.tematicasServicio.getTematicasIncluyendoLaOpcionTodos();
    this.estados = await this.estadoServicio.getEstadosIncluyendoLaOpcionTodos();

    this.usuario = await this.usuarioLocalService.getLocalUser() as Usuario;
    if (!this.usuario) return;


    this.getSolicitudes();

    this.suscripcionRequestServiceSignal = this.requestSignalServicio.requestList$.subscribe(async () => {
      this.getSolicitudes();
    })


  }

  comprobarSiExistenSolicitudes(): boolean {
    return this.solicitudesDelUsuario.length !== 0;
  }

  getSolicitudes(): void {
    this.requestService.getUserRequest().then(response => {
      this.solicitudesDelUsuario = [...response];
      this.solicitudesDelUsuarioFiltradas = [...response];
      this.cargando = false;

    })

  }


  changeContent(e: Event): void {
    let targetContent = e.target as HTMLElement;
    let id = targetContent.id;

    if (id) {
      this.showOption = id;
    }

    this.changeSelectedMenu(id);

  }


  changeSelectedMenu(id: string): void {

    let elements = document.querySelectorAll('.user-menu-link') as NodeList;
    elements.forEach(element => {
      const link = element as HTMLElement;
      link.classList.remove('selected');
      if (link.id === id) link.classList.add('selected')
    });

  }





  actualizarFiltroDeTematica(filtro: number | string): void {
    console.log('El filtro de la temática elegido es: ', filtro);

    const filtroTematica = (typeof filtro === 'string') ? parseInt(filtro) : filtro;
    this.filtrosSolicitudes.tematica = (filtroTematica === -1) ? null : filtroTematica;

    console.log('Filtros: ', this.filtrosSolicitudes);

    this.aplicarFiltroAlArrayDeSolicitudes();
  }


  actualizarFiltroDeEstado(filtro: number | string): void {
    console.log('El filtro del estado elegido es: ', filtro);
    const filtroEstado = (typeof filtro === 'string') ? parseInt(filtro) : filtro;
    this.filtrosSolicitudes.estados = (filtroEstado === -1) ? null : filtroEstado;

    console.log('Filtros: ', this.filtrosSolicitudes);
    this.aplicarFiltroAlArrayDeSolicitudes();

  }

  actualizarFiltroDeFechaInicio(filtro: string): void {
    console.log('El filtro del estado elegido es: ', filtro);
    this.filtrosSolicitudes.fechaInicio = formatDateToYYYYMMDD(new Date(filtro));
    console.log('Filtros: ', this.filtrosSolicitudes);
    this.aplicarFiltroAlArrayDeSolicitudes();

  }

  actualizarFiltroDeFechaFin(filtro: string): void {
    console.log('El filtro del estado elegido es: ', filtro);
    this.filtrosSolicitudes.fechaFin = formatDateToYYYYMMDD(new Date(filtro));
    console.log('Filtros: ', this.filtrosSolicitudes);
    this.aplicarFiltroAlArrayDeSolicitudes();

  }


  aplicarFiltroAlArrayDeSolicitudes(): Solicitud[] {
    this.reset = false;


    return [...this.solicitudesDelUsuarioFiltradas].filter(solicitud => {

      // Filtrar por estado si está definido
      if (this.filtrosSolicitudes.estados !== null && solicitud.estado.id !== this.filtrosSolicitudes.estados) {
        return false;
      }

      // Filtrar por temática si está definida
      if (this.filtrosSolicitudes.tematica !== null && solicitud.tematica.id !== this.filtrosSolicitudes.tematica) {
        return false;
      }

      // Filtrar por fecha si está definida
      if (this.filtrosSolicitudes.fechaInicio !== null && this.filtrosSolicitudes.fechaFin !== null) {

        return verificarRangoDeFechas(this.filtrosSolicitudes.fechaInicio as string, this.filtrosSolicitudes.fechaFin as string, formatDateToYYYYMMDD(solicitud.fecha));
      }

      // Filtrar por fecha si está definida
      if (this.filtrosSolicitudes.pendienteDescarga !== null) {

        return false
      }

      // Si pasa todos los filtros, incluir la solicitud en el resultado
      return true;
    });
  }


  buscarSolicitud(patronDeBusqueda: string) {
    this.solicitudesDelUsuarioFiltradas = [...[]];
    this.solicitudesDelUsuarioFiltradas = [...this.solicitudesDelUsuario.filter(document => {
      return (document.solicita.toLowerCase().includes(patronDeBusqueda.toLowerCase()) || document.descripcion.toLowerCase().includes(patronDeBusqueda.toLowerCase()))
    })];
  }

  limpiarTodosLosFiltros(): void {

    this.comunicacionServicio.enviarReset();

    this.solicitudesDelUsuarioFiltradas = [... this.solicitudesDelUsuario];
    this.filtrosSolicitudes.estados = null;
    this.filtrosSolicitudes.fechaFin = null;
    this.filtrosSolicitudes.fechaInicio = null;
    this.filtrosSolicitudes.tematica = null;

    let fechaInicio = document.getElementById('fechaInicio') as HTMLInputElement;
    fechaInicio.value = '';

    let fechaFin = document.getElementById('fechaFin') as HTMLInputElement;
    fechaFin.value = '';

  }



  async mostrarFiltrosEnMovil(): Promise<void> {

    await toogleClassToElement('show', 'filtro-solicitudes');

  }






  /*
    async actualizarValoresDeLosFiltrosPorEstado(filtro: number | string): Promise<void> {
  
      switch (filtro) {
        case '1':
          this.filtrosSolicitudesDelUsuario.informes.estado = !this.filtrosSolicitudesDelUsuario.informes.estado
          break;
        case 2:
          this.filtrosSolicitudesDelUsuario.enCurso.estado = !this.filtrosSolicitudesDelUsuario.enCurso.estado
          break;
        case 3:
          this.filtrosSolicitudesDelUsuario.finalizadas.estado = !this.filtrosSolicitudesDelUsuario.finalizadas.estado
          break;
        case 1:
          this.filtrosSolicitudesDelUsuario.pendientes.estado = !this.filtrosSolicitudesDelUsuario.pendientes.estado
          break;
      }
  
      await this.filtrarSolicitudPorEstado();
    }
  
  */





  /** SE PUEDE REFACTORIZAR 

  async filtrarSolicitudPorEstado(): Promise<void> {

    this.solicitudesDelUsuarioFiltradas = [...[]];

    if (this.filtrosSolicitudesDelUsuario.enCurso.estado) {
      this.solicitudesDelUsuarioFiltradas = [... await unirArraysEliminandoDuplicados(this.solicitudesDelUsuarioFiltradas, await filtrarArrayPorTipo(this.userRequests, 'estado_id', this.filtrosSolicitudesDelUsuario.enCurso.valor))];
    }

    if (this.filtrosSolicitudesDelUsuario.finalizadas.estado) {
      this.solicitudesDelUsuarioFiltradas = [... await unirArraysEliminandoDuplicados(this.solicitudesDelUsuarioFiltradas, await filtrarArrayPorTipo(this.userRequests, 'estado_id', this.filtrosSolicitudesDelUsuario.finalizadas.valor))];
    }


    if (this.filtrosSolicitudesDelUsuario.pendientes.estado) {
      console.log('Pendientes: ', await filtrarArrayPorTipo(this.userRequests, 'estado_id', this.filtrosSolicitudesDelUsuario.pendientes.valor))
      this.solicitudesDelUsuarioFiltradas = [... await unirArraysEliminandoDuplicados(this.solicitudesDelUsuarioFiltradas, await filtrarArrayPorTipo(this.userRequests, 'estado_id', this.filtrosSolicitudesDelUsuario.pendientes.valor))];
      console.log('filtrados: ', this.solicitudesDelUsuarioFiltradas)

    }



    if (this.filtrosSolicitudesDelUsuario.informes.estado) {
      console.log('Informes: ', await filtrarArrayPorTipo(this.userRequests, 'tematica_id', this.filtrosSolicitudesDelUsuario.informes.valor))
      this.solicitudesDelUsuarioFiltradas = [... await unirArraysEliminandoDuplicados(this.solicitudesDelUsuarioFiltradas, await filtrarArrayPorTipo(this.userRequests, 'tematica_id', this.filtrosSolicitudesDelUsuario.informes.valor))];
      console.log('filtrados: ', this.solicitudesDelUsuarioFiltradas)

    }





    // console.log('Array General: ', this.userRequests, 'Array concatenado: ', this.solicitudesDelUsuarioFiltradas)
  }*/

}
