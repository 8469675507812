import { Component } from '@angular/core';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { closeModal } from 'src/app/tools/modal.tool';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent {
  constructor(private usuarioLocalServicio: UsuarioLocalService) {

  }
  closeModal(): void {
    closeModal('loginModal');
  }

}
