import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, catchError, map, of, shareReplay, tap, throwError } from 'rxjs';
import { CAMBIAR_PASSWORD, Global } from '../helpers/global';
import { Usuario } from '../models/usuario';
import { Jsend } from '../models/jsend';
import { UsuarioLocalService } from './usuario-local.service';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { RespuestaApi } from '../models/respuesta-api';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  private userLogged = new BehaviorSubject<Usuario | null>(null);
  private apiUrl = Global.url;
  private isLoggedIn = new BehaviorSubject<boolean>(false);


  constructor(private http: HttpClient,
    private router: Router,
    private usuarioLocalService: UsuarioLocalService) { }

  login(body: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'auth/jwt_login', body).pipe(
      shareReplay(),
      tap(response => {
        if (response.data) {
          console.log(response.data)
          this.setTokens(response.data);
        }
      })
    );
  }

  private setTokens(data: any) {
    this.userLogged.next(data);
    sessionStorage.setItem(Global.ACCESS_TOKEN, data.access_token);
    localStorage.setItem(Global.REFRESH_TOKEN, data.refresh_token);
    this.isLoggedIn.next(true);
  }




  doRegister(newUser: any): Observable<Jsend> {
    return this.http.post<any>(this.apiUrl + 'auth/register', newUser)
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }

  // method to reset password
  resetPassword(body: any): Observable<Jsend> {
    return this.http.post<any>(this.apiUrl + 'auth/reset_password', body)
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }


  // method to change password for loged users
  changePassword(body: any): Observable<RespuestaApi> {
    return this.http.post<any>(CAMBIAR_PASSWORD.URL, body)
      .pipe(shareReplay(),
        catchError(this.handleError))
  }

  // method to validate account
  validateAccount(token: string): Observable<Jsend> {
    // set query parameter token with the id
    const params = new HttpParams().set('token', token);
    return this.http.post<any>(this.apiUrl + 'auth/validate_account', params)
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }

  validateAccountRequest(email: string): Observable<Jsend> {
    return this.http.post<any>(this.apiUrl + 'auth/validate_account_request?componentPath=validar-usuario', { reference: email }).pipe(
      shareReplay(),
      catchError(this.handleError)
    );
  }

  // method to request reset password
  requestResetPassword(email: string): Observable<Jsend> {
    return this.http.post<any>(this.apiUrl + 'auth/reset_password_request', { reference: email, base_url: Global.resetPassword })
      .pipe(
        shareReplay(),
        catchError(this.handleError)
      );
  }

  logOut() {
    this.clearTokens();
    this.usuarioLocalService.eliminarUsuarioLocal();
    this.router.navigate(['']);
    localStorage.clear();
  }

  clearTokens(): void {
    sessionStorage.removeItem(Global.ACCESS_TOKEN);
    localStorage.removeItem(Global.REFRESH_TOKEN);
    this.isLoggedIn.next(false);

  }

  refreshAccessToken(): Observable<Jsend> {
    return this.http.post<any>(this.apiUrl + 'auth/jwt_refresh', {}).pipe(
      shareReplay(),
      tap(response => {
        if (response.status === 'error' && response.message!.includes('Invalid token provided')) {
          console.log('Invalid token provided');
          this.clearTokens();
        }
        else if (response.status === 'success') {
          this.setTokens(response.data);
        }
      }),
      catchError(error => {
        this.clearTokens();
        return throwError(() => new Error(error));
      })
    );
  }

  public storeUserLogged(user: Usuario): void {
    this.userLogged.next(user);
  }

  public getUserLogged$(): Observable<Usuario | null> {
    return this.userLogged.asObservable();
  }

  public getUserLoggedValueAsync(): Promise<Usuario | null> {
    return new Promise((resolve) => {
      const valorActual = this.userLogged.getValue();
      resolve(valorActual);
    });
  }


  isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  setLoggedIn(loggedIn: boolean) {
    this.isLoggedIn.next(loggedIn);
  }

  getRole(): Observable<Jsend> {
    const params = new HttpParams().set('entorno', 'consulta');
    return this.http.get<Jsend>(this.apiUrl + 'usuarios/role', { params }).pipe(
      shareReplay(),
      tap(response => {
        if (response.status === 'success' && response.data === true) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError(error => {
        return throwError(() => new Error(error));
      })
    );
  }


  checkLooggedIn(state: RouterStateSnapshot): Observable<boolean> {
    const token = sessionStorage.getItem(Global.ACCESS_TOKEN);
    if (token) {
      try {
        if (!this.jwtHelper.isTokenExpired(token)) {
          this.setLoggedIn(true);
          return this.getRole().pipe(
            tap(async response => {
              if (response.data == false) {
                this.clearTokens();
                if (await mostrarMensajeDeAlerta('Ruta no permitida', 'Su perfil de usuario no le permite acceder a la gestión de la plataforma, acuda a /gestion del dominio.', 'warning', 'Aceptar', '', true, false)) {
                  this.logOut();
                }
              }
            }),
            map(response => {
              if (response.data === true) {
                return true;
              } else {
                return false;
              }
            }),
            catchError(error => {
              console.log(error);
              return of(false);
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    return this.checkRefreshToken().pipe(
      tap(isRefreshSuccess => {
        if (!isRefreshSuccess) {
          this.clearTokens();
          // TODO: redirect to login page. Podrias utilizar el parametro state.url para redirigir al usuario a la pagina que intentaba acceder
          this.router.navigate(['']);
        }
      }),
    );
  }

  private checkRefreshToken(): Observable<boolean> {
    const refreshToken: string = localStorage.getItem(Global.REFRESH_TOKEN)!;

    if (!refreshToken) {
      return of(false);
    }

    return this.refreshAccessToken().pipe(
      map(response => response.status === "success"),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

}
