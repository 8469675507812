import { Component, Input, OnInit } from '@angular/core';
import { Cargo } from 'src/app/models/cargo';
import { Corporacion } from 'src/app/models/corporacion';
import { Isla } from 'src/app/models/isla';
import { Municipio } from 'src/app/models/municipio';
import { Profesion } from 'src/app/models/profesion';
import { Provincia } from 'src/app/models/provincia';
import { Register } from 'src/app/models/register';
import { Seccion } from 'src/app/models/seccion';
import { Usuario } from 'src/app/models/usuario';
import { CargoService } from 'src/app/services/cargo.service';
import { CorporacionService } from 'src/app/services/corporacion.service';
import { IslaService } from 'src/app/services/isla.service';
import { MunicipioService } from 'src/app/services/municipio.service';
import { ProfesionService } from 'src/app/services/profesion.service';
import { ProvinciaService } from 'src/app/services/provincia.service';
import { RegisterService } from 'src/app/services/register.service';
import { SeccionService } from 'src/app/services/seccion.service';
import { UsuarioLocalService } from 'src/app/services/usuario-local.service';
import { mostrarMensajeDeAlerta } from 'src/app/helpers/alertas';
import { comprobarCondicionesPassword, comprobarSiDosPasswordSonIguales, passwordPattern } from 'src/app/tools/password.tool';
import { Router } from '@angular/router';
import { RespuestaApi } from 'src/app/models/respuesta-api';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailPatron } from 'src/app/tools/email.tool';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { patronSoloNumeros, patronSoloTexto } from 'src/app/tools/patrones.tool';
import { ErrorData } from 'src/app/models/error-data';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


  @Input() size = 70;
  @Input() title = "Registro";
  @Input() mostrarTitulo: boolean = true;
  @Input() usuarioLogeado: boolean = false;

  /** Valores por defecto de la BBDD para los selectores */
  cargos: Cargo[] = [];
  profesiones: Profesion[] = [];

  provincias: Provincia[] = [];
  provinciasBackup: Provincia[] = [];

  corporaciones: Corporacion[] = [];
  corporacionesBackup: Corporacion[] = [];


  islas: Isla[] = [];
  islasBackup: Isla[] = [];


  municipios: Municipio[] = [];
  municipiosBackup: Municipio[] = [];


  secciones: Seccion[] = [];


  registro: Register = new Register();
  usuario: Usuario = {} as Usuario;

  mostrarPassword: boolean = false;

  constructor(private readonly fb: FormBuilder,
    private usuarioLocalServicio: UsuarioLocalService,
    private cargosService: CargoService,
    private profesionService: ProfesionService,
    private seccioneService: SeccionService,
    private provinciaService: ProvinciaService,
    private corporacionesService: CorporacionService,
    private islasService: IslaService,
    private municipioService: MunicipioService,
    private registroServicio: RegisterService,
    private router: Router,
    private authService: AuthService,
    private usuarioServicio: UsuarioService) {

  }

  registroForm!: FormGroup;


  /**COMPROBACIONES DE USUARIO LOGEADO */
  esUsuarioLogeado: boolean = false;



  async ngOnInit(): Promise<void> {
    this.registroForm = await this.initForm();

    this.cargos = [... await this.cargosService.getCargo()]
    this.profesiones = [... await this.profesionService.getProfesion()]
    this.secciones = [... await this.seccioneService.getSeccion()]
    this.provincias = [... await this.provinciaService.getProvincia()]
    this.corporaciones = [... await this.corporacionesService.getCorporacion()]
    this.islas = [... await this.islasService.getIsla()]
    this.municipios = [... await this.municipioService.getMunicipio()]

    this.provinciasBackup = [... this.provincias]
    this.islasBackup = [... this.islas]
    this.municipiosBackup = [... this.municipios]


    if (!this.usuarioLogeado) {
      await this.usuarioLocalServicio.eliminarUsuarioLocal();
    } else {
      await this.crearUsuarioLogeado();
      this.registroForm = await this.initForm();
      if (this.esUsuarioLogeado) this.onPatchValue();
    }

    this.registroForm.get('provincia_id')?.disable();
    this.registroForm.get('municipio_id')?.disable();
    this.registroForm.get('isla_id')?.disable();








    this.registroForm.get('corporacion_id')?.valueChanges.subscribe((corporacionID: string) => {

      /** RESETEAMOS TODO Y DESHABILITAMOS LO QUE NO SE PUEDE SELECCIONAR POR AHORA */
      this.resetProvincia();
      this.resetIsla();
      this.resetMunicipio();

      this.desHabilitarProvincia();
      this.desHabilitarIsla();
      this.desHabilitarMunicipio();

      /** CUANDO TENGAMOS VALOR EN LA CORPORACIÓN HABILITAMOS QUE EL USUARIO PUEDA ELEGIR PROVINCIA */
      if (corporacionID) this.habilitarProvincia();
    })


    this.registroForm.get('provincia_id')?.valueChanges.subscribe((provinciaID: string) => {

      /** RESETEAMOS TODO Y DESHABILITAMOS LO QUE NO SE PUEDE SELECCIONAR POR AHORA */
      this.resetIsla();
      this.resetMunicipio();

      this.desHabilitarIsla();
      this.desHabilitarMunicipio();

      /** CUANDO TENGAMOS VALOR EN LA PROVINCIA HABILITAMOS QUE EL USUARIO PUEDA ELEGIR ISLA */
      if (provinciaID) {
        const valorCorporacion: number = parseInt(this.registroForm.get('corporacion_id')?.value);
        const valorProvincia: number = parseInt(this.registroForm.get('provincia_id')?.value);
        this.islas = [...this.islasBackup.filter((isla => {
          //console.log(`Corporacion_ID: ${valorCorporacion}, Isla provincia ID: ${isla.provincia_id} , Provincia ID: ${valorProvincia}`)

          if (valorCorporacion === 2) {
            return isla.provincia_id === valorProvincia && isla.id !== 5 && isla.id !== 4
          }
          return isla.provincia_id === valorProvincia


        }))]

        if (valorCorporacion !== 1)
          this.habilitarIsla();
      }
    })

    this.registroForm.get('isla_id')?.valueChanges.subscribe((islaID: string) => {

      /** RESETEAMOS TODO Y DESHABILITAMOS LO QUE NO SE PUEDE SELECCIONAR POR AHORA */
      this.resetMunicipio();
      this.desHabilitarMunicipio();

      if (islaID) {
        const valorCorporacion: number = parseInt(this.registroForm.get('corporacion_id')?.value);
        const valorIsla: number = parseInt(this.registroForm.get('isla_id')?.value);
        this.municipios = [...this.municipiosBackup.filter((municipio => {
          if (valorCorporacion === 3) {
            return municipio.isla_id === valorIsla && municipio.id !== 75 && municipio.id !== 37 && municipio.id !== 56 && municipio.id !== 35
          }
          return municipio.isla_id === valorIsla && municipio.id

        }))]
        /** CUANDO TENGAMOS VALOR EN LA ISLA HABILITAMOS QUE EL USUARIO PUEDA ELEGIR MUNICIPIO */
        if (valorCorporacion > 2) this.habilitarMunicipio();
      }

    })

  }







  resetProvincia(): void {
    this.provincias = [...this.provinciasBackup];
    this.registroForm.get('provincia_id')?.reset();
  }

  habilitarProvincia(): void {
    this.registroForm.get('provincia_id')?.enable();
    this.registroForm.get('provincia_id')?.setValue('')
  }

  desHabilitarProvincia(): void {
    this.registroForm.get('provincia_id')?.disable();

  }

  resetIsla(): void {
    this.islas = [...this.islasBackup];
    this.registroForm.get('isla_id')?.reset();
  }

  habilitarIsla(): void {
    this.registroForm.get('isla_id')?.enable();
    this.registroForm.get('isla_id')?.setValue('')

  }

  desHabilitarIsla(): void {
    this.registroForm.get('isla_id')?.disable();

  }


  resetMunicipio(): void {
    this.municipios = [...this.municipiosBackup];
    this.registroForm.get('municipio_id')?.reset();

  }

  habilitarMunicipio(): void {
    this.registroForm.get('municipio_id')?.enable();
    this.registroForm.get('municipio_id')?.setValue('')

  }

  desHabilitarMunicipio(): void {
    this.registroForm.get('municipio_id')?.disable();
  }



  async onSubmit(): Promise<void> {

    this.markFormGroupTouched(this.registroForm);

    this.registro = this.registroForm.value as Register;

    if (this.registroForm.valid) {

      if (this.esUsuarioLogeado) {
        const respuestaCreacionUsuario: RespuestaApi = await this.registroServicio.updateUser(this.registro, this.usuario.id);
        if (respuestaCreacionUsuario.status === 'success') {
          const usuarioActualizado = await this.usuarioServicio.getInfoUsuario(this.usuario.id)
          if (usuarioActualizado) await this.usuarioLocalServicio.updateUser(usuarioActualizado);
          await mostrarMensajeDeAlerta('Éxito', 'Datos modificados correctamente', 'success', 'Aceptar', '', true, false)
          this.router.navigate(['presentacion'])

        } else {
          mostrarMensajeDeAlerta('Error', 'No se ha podido modificar los datos, póngase en contacto con un administrador', 'warning', 'Aceptar', '', true, false)

        }
        return;
      }

      const respuestaCreacionUsuario: RespuestaApi<ErrorData> = await this.registroServicio.registerUser(this.registro);
      if (respuestaCreacionUsuario.status !== 'success') {
        if (respuestaCreacionUsuario.code === 422) {
          if (respuestaCreacionUsuario.data?.nombre) {
            mostrarMensajeDeAlerta('Error ', 'El nombre es demasiado largo', 'warning', 'Aceptar', '', true, false)
            return;
          }

          if (respuestaCreacionUsuario.data?.apellidos) {
            mostrarMensajeDeAlerta('Error ', 'El apellido es demasiado largo', 'warning', 'Aceptar', '', true, false)
            return;
          }

          if (respuestaCreacionUsuario.data?.email) {
            mostrarMensajeDeAlerta('Error al crear el usuario', 'El usuario ya existe', 'warning', 'Aceptar', '', true, false)
            return;
          }

        }
        mostrarMensajeDeAlerta('Error al crear el usuario', 'Póngase en contacto con el administrador', 'warning', 'Aceptar', '', true, false)
        return;

      }

      await mostrarMensajeDeAlerta('Usuario creado con éxito', 'Por favor revise su email para validar su cuenta', 'success', 'Aceptar', '', true, false)
      this.router.navigate([''])
    } else {
      await mostrarMensajeDeAlerta('Error', 'Los campos marcados en rojo son obligatorios', 'warning', 'Aceptar', '', true, false)

    }

  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  async initForm(): Promise<FormGroup<any>> {

    const passwordValidators = this.esUsuarioLogeado ? [] : [Validators.required, Validators.pattern(passwordPattern())];
    const confirmPasswordValidators = this.esUsuarioLogeado ? [] : [Validators.required];
    const privacidadValidators = this.esUsuarioLogeado ? [] : [Validators.requiredTrue];
    const desactivarValidators = this.esUsuarioLogeado ? [] : [Validators.requiredTrue];


    return this.fb.group({
      nombre: ['', [Validators.required, Validators.pattern(patronSoloTexto())]],
      apellidos: ['', [Validators.required, Validators.pattern(patronSoloTexto())]],
      seccion_id: ['', [Validators.required]],
      otra_seccion: ['', [Validators.pattern(patronSoloTexto())]],
      cargo_id: ['', [Validators.required]],
      otro_cargo: ['', [Validators.pattern(patronSoloTexto())]],
      profesion_id: ['', [Validators.required]],
      otra_profesion: ['', [Validators.pattern(patronSoloTexto())]],
      corporacion_id: [{ value: '', disabled: this.esUsuarioLogeado }, [Validators.required]],
      provincia_id: ['', [Validators.required]],
      isla_id: ['', [Validators.required]],
      municipio_id: ['', [Validators.required]],
      telefono: ['', [Validators.pattern(patronSoloNumeros())]],
      extension: ['', [Validators.pattern(patronSoloNumeros())]],
      email: ['', [Validators.required, Validators.pattern(emailPatron())]],
      movil: ['', [Validators.pattern(patronSoloNumeros())]],
      newsletters: [false],
      subvenciones: [false],
      sentencias: [false],
      privacidad: [false, privacidadValidators],
      desactivar: [false, desactivarValidators],
      password: ['', passwordValidators],
      password_confirm: ['', confirmPasswordValidators],
    }, { validators: [this.matchPasswords] } as AbstractControlOptions);

  }


  matchPasswords(formGroup: FormGroup): { [key: string]: any } | null {
    const passwordControl = formGroup.get('password');
    const repeatPasswordControl = formGroup.get('password_confirm');

    if (passwordControl && repeatPasswordControl && passwordControl.value !== repeatPasswordControl.value) {
      return { passwordsNotMatch: true };
    }

    return null;
  }

  mostrarPasswordIntroducidoPorElUsuario(): void {
    this.mostrarPassword = !this.mostrarPassword;
    const password = document.getElementById('passwordRegistro') as HTMLInputElement;
    const repetirPassword = document.getElementById('repetirPasswordRegistro') as HTMLInputElement;

    password.type = this.mostrarPassword ? 'text' : 'password';
    repetirPassword.type = this.mostrarPassword ? 'text' : 'password';
  }


  onPatchValue(): void {
    this.registroForm.patchValue({
      nombre: this.usuario.nombre || '',
      apellidos: this.usuario.apellidos || '',
      seccion_id: this.usuario.seccion ? this.usuario.seccion.id : '',
      otra_seccion: this.usuario.otra_seccion ? this.usuario.otra_seccion : '',
      cargo_id: this.usuario.cargo_id ? this.usuario.cargo_id : '',
      otro_cargo: this.usuario.otro_cargo ? this.usuario.otro_cargo : '',
      profesion_id: this.usuario.profesion ? this.usuario.profesion.id : '',
      otra_profesion: this.usuario.otra_profesion ? this.usuario.otra_profesion : '',
      corporacion_id: this.usuario.corporacion_id ? this.usuario.corporacion_id : '',
      provincia_id: this.usuario.municipio.isla.provincia ? this.usuario.municipio.isla.provincia.id : '',
      isla_id: this.usuario.municipio && this.usuario.municipio.isla ? this.usuario.municipio.isla.id : '',
      municipio_id: this.usuario.municipio.id ? this.usuario.municipio.id : '',
      telefono: this.usuario.telefono || '',
      extension: this.usuario.extension || '',
      email: this.usuario.email || '',
      movil: this.usuario.movil || '',
      newsletters: this.usuario.newsletters || false,
      subvenciones: this.usuario.subvenciones || false,
      sentencias: this.usuario.sentencias || false,

    })

  }


  async crearUsuarioLogeado(): Promise<void> {

    const usuarioLocal = await this.usuarioLocalServicio.getLocalUser();
    if (usuarioLocal) {
      this.esUsuarioLogeado = true;
      this.usuario = { ...usuarioLocal as Usuario }
    }

    else {
      console.log('No hay usuario logeado')
    }

  }


  async eliminarUsuario(): Promise<void> {

    const aceptar = await mostrarMensajeDeAlerta('Aviso', '¿Está seguro/a que desea eliminar su cuenta?', 'warning', 'Aceptar', 'Cancelar', true, true);

    if (!aceptar) return;

    const resp = await this.registroServicio.borrarUsuario(this.usuario.id);
    if (resp.status === 'success') {
      await mostrarMensajeDeAlerta('Éxito', 'Cuenta eliminada con éxito', 'success', 'Aceptar', '', true, false);
      this.authService.logOut();
    }

  }

  /**TODO - REFACTORIZAR */
  async cambiarPassword(): Promise<void> {
    const { value: formValues } = await Swal.fire({
      showDenyButton: true,
      denyButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      html: `
       <style>
       .change-password-container{
        min-height: 250px;
       }
        .password-container {
          position: relative;
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .error-container {
          position: relative;
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap:30px;
        }
        .password-container label {
          margin-bottom: 5px;
          font-family: var(--custom-font-family);
        }
        .password-container input {
          width: 80%;
          padding: 8px;
          position: relative;
        }
        .password-container img {
          position: absolute;
          right: 15%;
          top:40px;
         
          cursor: pointer;
        }
        #password-error, #confirm-error {
          color: rgb(169, 72, 47);
          font-size: 0.875em;
          height: 20px;
          display: none;
          font-family: var(--custom-font-family);
          font-size: 16px;
        }
        #password-error.show, #confirm-error.show {
          display: block;
        }
      </style>
      <div class="change-password-container">
      <div class="password-container">
        <label for="swal-input1">Nueva contraseña:</label>
        <input type="password" id="swal-input1" class="swal2-input">
        <img src="../../../assets/images/ojo.svg" id="toggle-password1">
      </div>
      <div class="password-container">
        <label for="swal-input2">Confirmar contraseña:</label>
        <input type="password" id="swal-input2" class="swal2-input">
        <img src="../../../assets/images/ojo.svg" id="toggle-password2">
      </div>
      <div class="error-container">
        <div id="password-error">
          La contraseña debe tener entre 8 y 20 caracteres, incluyendo al menos una mayúscula, un número y un carácter especial.
        </div>
        <div id="confirm-error">
          Las contraseñas no coinciden.
        </div>
      </div>
      <div>
    `,
      focusConfirm: false,
      didOpen: () => {
        const togglePassword1 = document.getElementById("toggle-password1")!;
        const togglePassword2 = document.getElementById("toggle-password2")!;
        const passwordInput1 = document.getElementById("swal-input1") as HTMLInputElement;
        const passwordInput2 = document.getElementById("swal-input2") as HTMLInputElement;
        const passwordError = document.getElementById("password-error")!;
        const confirmError = document.getElementById("confirm-error")!;

        togglePassword1.addEventListener('click', () => {
          if (passwordInput1.type === 'password') {
            passwordInput1.type = 'text';
          } else {
            passwordInput1.type = 'password';
          }
        });

        togglePassword2.addEventListener('click', () => {
          if (passwordInput2.type === 'password') {
            passwordInput2.type = 'text';
          } else {
            passwordInput2.type = 'password';
          }
        });

        const validatePassword = () => {
          if (!comprobarCondicionesPassword(passwordInput1.value)) {
            passwordError.classList.add('show');
          } else {
            passwordError.classList.remove('show');
          }
        };

        passwordInput1.addEventListener('input', validatePassword);

        const validateConfirmPassword = () => {
          if (!comprobarSiDosPasswordSonIguales(passwordInput1.value, passwordInput2.value)) {
            confirmError.classList.add('show');
          } else {
            confirmError.classList.remove('show');
          }
        };

        passwordInput2.addEventListener('input', validateConfirmPassword);
      },
      preConfirm: () => {
        const passwordInput1 = document.getElementById("swal-input1") as HTMLInputElement;
        const passwordInput2 = document.getElementById("swal-input2") as HTMLInputElement;
        const password = passwordInput1.value;
        const confirmPassword = passwordInput2.value;

        if (!comprobarCondicionesPassword(password) || !comprobarSiDosPasswordSonIguales(password, confirmPassword)) {
          return false;
        }

        return [password, confirmPassword];
      }
    });

    if (formValues) {
      this.authService.changePassword(
        {
          password: formValues[0],
          password_confirm: formValues[0]
        }
      ).subscribe(resp => {
        console.log(resp)
        if (resp.status === 'success') mostrarMensajeDeAlerta('Éxito', 'La contraseña se ha modificado correctamente', 'success', 'Aceptar', '', true, false);
        else mostrarMensajeDeAlerta('Error', 'Contacte con el administrador', 'warning', 'Aceptar', '', true, false)
      })
      //
    }
  }


}
