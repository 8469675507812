<div class="main-container">
    <h1>POLÍTICA DE PRIVACIDAD</h1>
    <section>
        <h2>PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2>
        <p>Gestión y Planeamiento Territorial y Medioambiental S.A. (en adelante Gesplan) informa a las personas
            usuarias de
            la Plataforma AOT https://www.territoriocanario.org/ a través de esta Política de Privacidad sobre el
            tratamiento y protección de los datos de carácter personal de las personas usuarias que puedan ser recabados
            mediante su navegación registro así como en las solicitudes de informes técnicos y/o jurídicos.</p>
        <p>Gesplan adopta las medidas necesarias para garantizar la seguridad integridad y confidencialidad de los datos
            conforme a lo establecido en la legislación anteriormente citada.</p>
        <p>Mediante la solicitud de registro de la plataforma AOT o mediante la solicitud de cualquier informe técnico
            y/o
            jurídico se recogen y tratan datos personales de cada persona usuaria el tratamiento de los cuales se rige
            por
            la presente Política de Privacidad. Estos datos son necesarios para la gestión y mantenimiento de los
            servicios
            prestados por Gesplan a través de la Plataforma AOT.</p>
    </section>

    <section>
        <h2>RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES</h2>
        <p>La persona responsable del tratamiento de los datos personales que se traten es Gestión y Planeamiento
            Territorial y Medioambiental S.A. (Gesplan) inscrita en el Registro Mercantil de Las Palmas Folio 172 Tomo
            1231
            Hoja GC 13426 Inscripción 2ª.con domicilio en Calle León y Castillo 54 bajo código postal 35003
            info&#64;territoriocanario.org Las Palmas de Gran Canaria con CIF A-38279972 representado por Manuel Agoney
            Piñero
            Ortiz (Consejero Delegado) con NIF 43829417L.</p>
        <p>Asimismo contamos con una persona delegada de protección de datos ante la que se podrá realizar cualquier
            consulta relativa al uso de los datos personales mediante el siguiente correo electrónico
            prodacan&#64;gmail.com.
        </p>
    </section>

    <section>
        <h2>DATOS DE CARÁCTER PERSONAL QUE SE RECABAN</h2>
        <p>La recogida y tratamiento de los datos de carácter personal tiene como finalidad identificar al personal
            técnico
            que requiera de un asesoramiento a través de la Plataforma AOT. También se podrán registrar en la plataforma
            otros profesionales liberales que deberán aportar los mismos datos de carácter personal pero la finalidad
            será
            la de identificar a la persona usuaria de la plataforma.</p>
        <p>La persona usuaria deberá inscribirse aportando diversos datos de carácter personal que son los siguientes:
        </p>
        <ul>
            <li>Nombre</li>
            <li>Apellidos</li>
            <li>Profesión</li>
            <li>Teléfono</li>
            <li>E-mail</li>
            <li>Corporación a la que pertenece</li>
            <li>Cargo</li>
            <li>Isla donde ejerce su profesión</li>
            <li>Municipio donde ejerce su profesión</li>
            <li>Contraseña para acceder a la web AOT</li>
        </ul>
        <p>Idéntico tratamiento resultará de aplicación para los datos sobre terceros que por parte del personal
            técnicos de
            las distintas Administraciones de Canarias se deban aportar para la resolución de los informes técnicos y
            jurídicos estos datos serán con carácter general referencias catastrales de inmuebles direcciones así como
            solicitudes de licencias urbanísticas donde conste el nombre apellidos teléfono y DNI de la persona
            interesada.
            Estos datos personales que nos ha facilitado serán tratados por esta entidad a efectos organizativos
            internos
            para gestionar de una forma efectiva las solicitudes recibidas de parte de los interesados.</p>
        <p>Por último se informa que se podrá hacer uso de la información que se obtenga de las cookies cuando sean
            aceptadas por la persona usuaria.</p>
        <p>Gesplan garantiza el uso adecuado de la información y en especial de los datos de carácter personal así como
            el
            pleno cumplimiento de las obligaciones en materia de protección de datos. En lo concerniente al tratamiento
            y al
            deber de confidencialidad la información facilitada y en su caso los ficheros adjuntos –en ningún caso se
            almacenará dicha información- es de carácter exclusivo y estrictamente confidencial dirigido expresamente a
            la
            contestación de la solicitud recibida.</p>
    </section>

    <section>
        <h2>FINALIDAD DEL TRATAMIENTO DE DATOS Y BASE LEGAL</h2>
        <p>La finalidad de la obtención de los datos de carácter personal es la creación de una cuenta de usuario que
            permitirá el acceso a la plataforma AOT. La plataforma permite la comunicación bidireccional entre la
            persona
            usuaria y el equipo multidisciplinar que dan respuesta a las solicitudes a través de la plataforma AOT con
            objeto de dar una respuesta adecuada y eficiente a las solicitudes realizadas. Dichos datos a los que se
            hacen
            alusión en este párrafo serán los expuestos en el apartado 3 de esta Política de Privacidad. Con dicho
            registro
            en la plataforma la persona usuaria podrá hacernos llegar cuestiones sobre las que necesiten asesoramiento
            sobre
            materia urbanística pudiendo solicitar informes técnicos y/o jurídicos redacción de modelos de informes
            técnicos
            y jurídicos modelos de Ordenanzas y descarga de los documentos ya alojados en la plataforma AOT.</p>
        <p>Se recabará la información de las cookies con el fin de personalizar y facilitar la navegación de la persona
            usuaria identificar a las personas usuarias registradas y poder autocompletar formularios. La persona
            usuaria
            mediante la configuración de su ordenador podrá impedir la instalación de estos cookies no obstante en tal
            caso
            las personas usuarias registradas no podrán acceder a los servicios prestados.</p>
        <p>La base legal que legitima el tratamiento de los datos de carácter personal para la creación de un perfil de
            usuario es la del consentimiento otorgado a la hora de realizar el registro en la plataforma AOT en la que
            se
            requerirá que haga click la casilla de verificación que indica que se ha leído y entendido la política de
            privacidad de la Plataforma. No obstante la persona usuaria podrá retirar en cualquier momento el
            consentimiento
            sin que dicha actuación afecte a la licitud del tratamiento basada en el consentimiento previo a su
            retirada.
            También en el momento de la inscripción se requerirá a la persona técnica preste su consentimiento de manera
            expresa e inequívoca mediante el cual se comprometa a notificar o en su caso la persona responsable de la
            baja
            correspondiente.</p>
    </section>

    <section>
        <h2>PUBLICIDAD</h2>
        <p>Para recibir información en la Plataforma o a través de ésta se recabará la dirección e-mail de la persona
            usuaria con la finalidad de difundir noticias urbanísticas de interés que puede abarcar información sobre
            aprobación de legislación reciente nuevos documentos alojados en la plataforma AOT etc. Para recibir dicha
            información será necesario dar el consentimiento en el momento del registro como usuario mediante una
            casilla de
            verificación concreta o bien en un momento posterior dentro de su propia cuenta de usuario. En cualquier
            caso
            para ello será indispensable que la persona usuaria marque la casilla de verificación que indica que se ha
            leído
            y se acepta la política de privacidad de la Plataforma momento en el cual se entenderá que se otorga el
            consentimiento de la persona usuaria que será la base legal del tratamiento. No obstante la persona usuaria
            podrá retirar en cualquier momento el consentimiento sin que dicha actuación afecte a la licitud del
            tratamiento
            basada en el consentimiento previo a su retirada.</p>
    </section>

    <section>
        <h2>DURACIÓN DEL PLAZO PARA LA CONSERVACIÓN DE LOS DATOS DE CARÁCTER PERSONAL</h2>
        <p>El plazo durante el que se guardarán los datos de la persona usuaria será aquel en la que ésta se encuentre
            registrada en la plataforma para atender a la resolución de solicitudes y modelos y cualesquiera otras que
            se
            encuentren amparadas dentro del objeto del programa AOT. No obstante en caso de retirar su consentimiento se
            procederá al bloqueo de los datos de la persona usuaria en cualquier caso la retirada del consentimiento no
            afectará a la licitud del tratamiento basada en el consentimiento previo a su retirada. Idéntico tratamiento
            recibirán aquellas personas que se den de baja como personal técnico en las determinadas corporaciones
            locales
            quienes deberán informar de dicha circunstancia ya que en el momento del registro en la plataforma la
            persona
            usuaria se compromete mediante la aceptación en la casilla de verificación a informarnos si se diera el
            supuesto
            de que va a proceder a darse de baja en el Ayuntamiento del cual consta inscrito en la Plataforma momento en
            el
            cual se procederá a desactivar sus datos.</p>
        <p>Del mismo modo se procederá a desactivar de la Plataforma automáticamente a aquellas personas que no hayan
            tenido
            ningún tipo de actividad en la misma durante el plazo de un año pudiendo reactivarse dicho perfil previa
            solicitud por parte de la persona usuaria.</p>
        <p>Como elemento común para los anteriores supuestos con independencia de que se desactiven los perfiles de las
            personas usuarias en la Plataforma éstos se conservarán de igual manera a efectos de conocer la persona que
            realizó cada una de las solicitudes. En caso de no haber realizado ninguna consulta se procederá a un
            borrado
            íntegro de tales datos. El plazo en el que se almacenarán los datos será el establecido en los artículos
            17.3 y
            89 del RGPD éstos se guardarán por un tiempo ilimitado incluso si la persona usuaria hubiese solicitado su
            derecho a supresión y consiguientemente su bloqueo esto se debe a que el Programa AOT necesita tales datos
            por
            un fin estadístico y este borrado de datos haría imposible y obstaculizaría gravemente el logro de los
            objetivos
            de dicho tratamiento ya que debemos contar con un histórico en donde conste la solicitud de la persona y la
            corporación a la que pertenece por lo que en virtud de las disposiciones anteriores se llevarán a cabo
            medidas
            técnicas y organizativas para garantizar el respecto del principio de minimización de los datos personales
            en
            concreto la seudonimización. Además de almacenar los datos de carácter personal con carácter indefinido ya
            que
            como se ha dicho anteriormente resultan necesarios para elaborar las correspondientes estadísticas sobre el
            número de peticiones formuladas por las entidades locales de Canarias también desde AOT se debe entregar de
            manera anual a la Consejería de la que depende una memoria en donde se indican los datos extraídos de
            distintos
            análisis de las solicitudes realizadas.</p>
        <p>Por otra parte los datos recabados para el envío de la publicidad para difundir noticias urbanísticas de
            interés
            que puede abarcar información sobre aprobación de legislación reciente nuevos documentos alojados en la
            plataforma AOT serán conservados mientras la persona usuaria mantenga el consentimiento que legitimó el
            tratamiento.</p>
    </section>

    <section>
        <h2>COMUNICACIÓN DE DATOS</h2>
        <p>Gesplan se compromete a no tratar los datos de carácter personal y demás información facilitada para
            finalidades
            diferentes a las pactadas así como a no cederlos ni a comunicarlos a terceras personas salvo en los casos en
            que
            exista una obligación legal como por ejemplo a Administraciones y/o Organismos Públicos cuando así lo exija
            la
            normativa fiscal laboral de Seguridad Social o cualquier otra que resulte aplicable.</p>
        <p>Gesplan manifiesta que ha adoptado todas las medidas técnicas posibles y de organización necesarias para
            garantizar la seguridad e integridad de los datos de carácter personal que trate así como para evitar su
            pérdida
            alteración y/o acceso por parte de terceros no autorizados.</p>
    </section>

    <section>
        <h2>DERECHOS DE LAS PERSONAS USUARIAS</h2>
        <p>No obstante estos datos de carácter personal estarán sometidos a los derechos indicados en el Reglamento
            General
            de Protección de Datos (RGPD) y la Ley Orgánica 3/2018 de 5 de diciembre de Protección de Datos Personales y
            garantía de los derechos digitales (LOPDP) que son los siguientes:</p>
        <ul>
            <li>Derecho de acceso</li>
            <li>Derecho de rectificación</li>
            <li>Derecho de supresión</li>
            <li>Derecho a la limitación del tratamiento</li>
            <li>Derecho a la portabilidad</li>
            <li>Derecho de oposición</li>
        </ul>
        <p>La persona usuaria podrá ejercitar en todo momento en los términos establecidos en la legislación vigente
            dichos
            derechos. El ejercicio de éstos puede realizarlo la propia persona usuaria directamente dentro de la propia
            plataforma AOT o dirigiéndose a Gesplan S.A. en la dirección de su sede social indicada en el apartado 3 de
            esta
            Política de Privacidad; mediante el envío de un correo electrónico a la siguiente dirección
            info&#64;territoriocanario.org o a la dirección postal Calle León y Castillo 54 bajo código postal 35003 Las
            Palmas
            de Gran Canaria.</p>
        <p>Para el efectivo ejercicio de estos derechos el usuario deberá acreditar su identidad facilitando su nombre y
            apellidos email petición en la que se concrete la solicitud dirección a efectos de notificaciones y fecha y
            firma de la persona solicitante.</p>
        <p>Asimismo el usuario podrá reclamar ante la Agencia Española de Protección de Datos (Autoridad de Control
            competente en esta materia) especialmente cuando no haya obtenido satisfacción en el ejercicio de sus
            derechos
            mediante escrito dirigido a misma C/Jorge Juan N.º 6 28001 - Madrid o a través de la web:
            https://www.agpd.es
        </p>
    </section>

</div>