import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Provincia } from '../models/provincia';
import { PROVINCIAS } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProvinciaService {


  constructor(private httpClient: HttpClient) { }

  async getProvincia(codigoProvincia?: string): Promise<Provincia[]> {
    try {
      const url = codigoProvincia
        ? PROVINCIAS.URL + codigoProvincia
        : PROVINCIAS.URL;

      const respuesta: Observable<{ data: Provincia[] }> = this.httpClient.get<{ data: Provincia[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return await this.eliminarCProvinciasNoInteresadasParaUsuario(resultado.data)
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  async eliminarCProvinciasNoInteresadasParaUsuario(provincias: Provincia[]): Promise<Provincia[]> {
    return provincias
      .filter(provincias => provincias.provincia !== 'Canarias')
  }

  getNombreProvincias(provincias: Provincia[]): string[] {
    return provincias
      .filter(provincias => provincias.provincia !== 'Canarias')
      .map(provincias => provincias.provincia);
  }
}
