import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollpageService {

  private scrollListener!: () => void;
  private renderer: Renderer2;
  private scrollSubject: Subject<boolean> = new Subject<boolean>();



  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

  }


  creacionFuncionListener(element: Element): void {

    this.scrollListener = this.renderer.listen(element, 'scroll', this.onScroll.bind(this));

  }

  onScroll(event: Event): void {
    const scrollPosition = (event.target as HTMLElement).scrollTop;
    this.scrollSubject.next(scrollPosition === 0);
  }

  getScrollObservable(): Observable<boolean> {
    return this.scrollSubject.asObservable();
  }

  eliminarFuncionListener(): void {
    if (this.scrollListener) {
      this.scrollListener();
    }
  }


  scrollToElement(target: string) {
    const element = document.getElementById(target) as HTMLElement;

    if (!element) return;

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
