<div class="user-request" id="{{forumNumber}}">
    <div class="user-request-left">
        <div class="user-request-left-img">
            <img src="{{forumIMG}}" alt="">
        </div>
        <div class="user-request-left-resume">
            <p class="user-request-left-resume-title">{{forumName}}</p>
            <p>Fecha de finalización: <strong>{{forumDate}}</strong></p>

        </div>
    </div>
    <div class="user-request-right">
        <hr>
        <div class="download">
            <img src="../../../assets/images/descarga.svg" alt=""
                [ngClass]="{'unavailable': forumStatus !== 'finalizada'}">
        </div>
    </div>
</div>