import { Cargo } from "./cargo";
import { Corporacion } from "./corporacion";
import { Municipio } from "./municipio";
import { Profesion } from "./profesion";
import { Provincia } from "./provincia";
import { Seccion } from "./seccion";

export class Usuario {
  constructor(
    public id: string = "",
    public nombre: string = "",
    public apellidos: string = "",
    public email: string = "",
    public telefono: string = '',
    public activation_date: Date = new Date(),
    public movil: string = '',
    public extension: string = '',
    public seccion: Seccion = new Seccion(),
    public cargo: Cargo = new Cargo(),
    public cargo_id: number = -1,
    public corporacion: Corporacion = new Corporacion(),
    public corporacion_id: number = -1,
    public password: string = "",
    public provincia: Provincia = new Provincia(),
    public profesion: Profesion = new Profesion(),
    public profesion_id: number = -1,
    public municipio: Municipio = new Municipio(),
    public municipio_id: number = -1,
    public consentimiento: number = 0,
    public role: string = "",
    public last_login: string = "",
    public active: number = 0,
    public otra_profesion: string = "",
    public otra_seccion: string = "",
    public otro_cargo: string = "",
    public access_token: string = "",
    public subvenciones: boolean = false,
    public newsletters: boolean = false,
    public sentencias: boolean = false
  ) { }
}
