import { Injectable } from '@angular/core';
import { Cargo } from '../models/cargo';
import { CARGOS } from '../helpers/global';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CargoService {

  constructor(private httpClient: HttpClient) { }

  async getCargo(codigoCargo?: number): Promise<Cargo[]> {
    try {
      const url = codigoCargo
        ? CARGOS.URL + codigoCargo
        : CARGOS.URL;

      const respuesta: Observable<{ data: Cargo | Cargo[] }> = this.httpClient.get<{ data: Cargo | Cargo[] }>(url);
      const resultado = await lastValueFrom(respuesta);

      // Si la respuesta es un solo objeto, envuélvelo en un array
      const cargos: Cargo[] = Array.isArray(resultado.data) ? resultado.data : [resultado.data];

      return Promise.resolve(cargos);
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }

  getNombreCargos(cargos: Cargo[]): string[] {
    return cargos
      .filter(cargos => cargos.cargo !== 'Seleccione cargo')
      .map(cargos => cargos.cargo);
  }
}
