import { Provincia } from "./provincia";

export class Isla {
  constructor(
    public id: number = -1,
    public isla: string = '',
    public cod_isla: number = -1,
    public cp: string = '',
    public orden: number = -1,
    public estado: number = -1,
    public provincia_id: number = -1,
    public provincia: Provincia = new Provincia()

  ) { }
}