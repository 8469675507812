<div class="emails">
    <div class="emails-sidebar">
        <app-sidebar-correo [botonAMostrar]="'bandeja_entrada'"></app-sidebar-correo>
    </div>
    <div class="emails-email">
        <div class="email" *ngIf="email">

            <div class="email-asunto">
                <h1>{{this.email.asunto}}</h1>
            </div>

            <div class="email-remitente">
                <img src="../../../../assets/images/usuario.svg" alt="">
                <div class="email-remitente-data">
                    <p>{{this.email.remitente.nombre}} {{this.email.remitente.apellidos}}</p>
                    <div class="email-receptor">
                        Para: <span>{{this.email.receptor.nombre}} {{this.email.receptor.apellidos}}</span>
                    </div>
                </div>

            </div>



            <ckeditor [editor]="Editor" data="" #correo [disabled]="true" [config]="configEditor"
                [(ngModel)]="email.correo">
            </ckeditor>

            <div class="email-footer">
                <button class="secondary-button">Reenviar</button>
                <button class="primary-button" [routerLink]="['/nuevo-email']"
                    [queryParams]="{ emailID: email.id }">Responder</button>
            </div>

        </div>
    </div>
</div>

<!--


<div class="email">
    <div class="main-container">
        <div class="main-container-left">
            <button class="primary-button" [routerLink]="'nuevo-email'">NUEVO CORREO</button>
            <div class="email-filters">
                <div class="email-filters-header">
                    <p class="">Carpetas</p>
                </div>
                <div class="email-filters-list">
                    <div class="email-filter-list-item" (click)="cambiarVista('recibidos')">
                        <a class="email-filter-list-link selected"><span><img src="../../../assets/images/recibidos.svg"
                                    alt="" class="email-filter-list-item-link-icon"></span><span
                                class="email-filter-list-item-link-text">Recibidos</span><span
                                class="email-filter-list-item-link-number"> {{contarCorreosNoLeidos()}}
                            </span></a>
                    </div>
                    <div class="email-filter-list-item" (click)="cambiarVista('enviados')">
                        <a class="email-filter-list-link"><span><img src="../../../assets/images/enviados.svg" alt=""
                                    class="email-filter-list-item-link-icon"></span><span
                                class="email-filter-list-item-link-text">Enviados</span><span></span></a>
                    </div>
                    <div class="email-filter-list-item" (click)="cambiarVista('eliminados')">
                        <a class="email-filter-list-link"><span><img src="../../../assets/images/papelera.svg" alt=""
                                    class="email-filter-list-item-link-icon"></span><span
                                class="email-filter-list-item-link-text">Papelera</span></a>
                    </div>


                </div>
            </div>
        </div>
        <div class="main-container-right">

            <div id="nuevo-email">
                <app-nuevo-email></app-nuevo-email>
            </div>

            <div id="lista-emails" class="mostrar">
                <app-lista-emails></app-lista-emails>
            </div>

        </div>

    </div>
</div>

-->