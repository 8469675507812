import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaApi } from '../models/respuesta-api';
import { lastValueFrom } from 'rxjs';
import { VALIDAR_USUARIO } from '../helpers/global';

@Injectable({
  providedIn: 'root'
})
export class ValidarUsuarioService {

  constructor(private http: HttpClient) { }

  public async verificarUsuarioEnELServidor(token: string): Promise<RespuestaApi> {
    const resp: RespuestaApi = await lastValueFrom(this.http.post(VALIDAR_USUARIO.URL, { 'token': token })) as RespuestaApi;
    return resp
  }
}
