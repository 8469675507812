import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SolicitudesAnuales } from 'src/app/models/solicitudes-anuales';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-aot-content-title',
  templateUrl: './aot-content-title.component.html',
  styleUrls: ['./aot-content-title.component.css']
})
export class AotContentTitleComponent implements AfterViewInit, OnInit {
  solicitudesAnuales: SolicitudesAnuales[] = [];
  anioActual: string[] = [];
  solicitudesActuales: number = 0;
  constructor(private requestService: RequestService) {

  }
  async ngOnInit(): Promise<void> {

    this.solicitudesAnuales = [... await this.requestService.getSolicitudesPorAnio()];

    if (this.solicitudesAnuales && this.solicitudesAnuales.length > 0) {
      this.anioActual = [...this.solicitudesAnuales[this.solicitudesAnuales.length - 1].anio.toString().split('')]
    }

    (await this.requestService.getSolicitudesPorMunicipio()).forEach(data => {
      this.totalReports = this.totalReports + data.recuento;
    });

    const totalReportAux = this.totalReports
    const solicitudesActualesAux = this.solicitudesActuales;

    this.totalReports = 0;
    this.solicitudesActuales = 0;



    for (let i = 0; i <= 150; i++) {
      this.totalReports += 20;
      this.solicitudesActuales = Math.floor(Math.random() * 1000);
      if (i <= 50) {
        this.year1 = Math.floor(Math.random() * 10);
        this.year2 = Math.floor(Math.random() * 10);
        this.year3 = Math.floor(Math.random() * 10);
        this.year4 = Math.floor(Math.random() * 10);
      }
      else if (i <= 75) {
        this.year1 = 2
        this.year2 = Math.floor(Math.random() * 10);
        this.year3 = Math.floor(Math.random() * 10);
        this.year4 = Math.floor(Math.random() * 10);
      }
      else if (i <= 100) {
        this.year1 = 2
        this.year2 = 0
        this.year3 = Math.floor(Math.random() * 10);
        this.year4 = Math.floor(Math.random() * 10);
      }
      else if (i < 125) {
        this.year1 = 2
        this.year2 = 0
        this.year3 = 2
        this.year4 = Math.floor(Math.random() * 10);
      }
      else {
        this.year1 = Number(this.anioActual[0])
        this.year2 = Number(this.anioActual[1])
        this.year3 = Number(this.anioActual[2])
        this.year4 = Number(this.anioActual[3])
      }

      await this.delay();

    }
    this.totalReports = totalReportAux;
    this.solicitudesActuales = this.solicitudesAnuales[this.solicitudesAnuales.length - 1].recuento;
  }
  previousUrl: string = '';



  /** Se puede mejorar */
  async ngAfterViewInit(): Promise<void> {


  }





  async delay() {
    return new Promise(resolve => setTimeout(resolve, 10));
  }


  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() background: string = '';
  @Input() headerInfo: any;
  @Input() presentation: boolean = false;


  year1 = 0;
  year2 = 0;
  year3 = 0;
  year4 = 0;
  totalReports = 0;


  showReportsData(): void {
    document.getElementById('hiddenData')?.classList.add('show');
  }

  hiddeReportsData(): void {
    document.getElementById('hiddenData')?.classList.remove('show');
  }









}
