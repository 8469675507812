<div class="program">
    <div class="program-text">
        <p>El objeto del programa de apoyo a las oficinas técnicas es facilitar a los ayuntamientos de los
            <strong>municipios
                menores de
                100.000 habitantes y los cabildos de las islas no capitalinas</strong>, el apoyo necesario para el
            óptimo desarrollo
            de las
            diferentes actividades que son competencia de las oficinas técnicas enmarcadas en las líneas de actuación
            del
            programa.
        </p>

        <a href="https://www.gesplan.es/content/orden-n%C2%BA-2322023-de-3-mayo-que-modifica-la-orden-n%C2%BA-5062021-de-26-diciembre-programa-de"
            target="_blank"><u>Para más información</u></a>
    </div>
    <div class="program-image">
        <img src="../../../assets/images/program-image.svg" alt="">
        <p>Municipios de Canarias con menos de 100.000 habitantes</p>
    </div>
</div>