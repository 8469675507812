import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailSignalService {

  private bandejaEmailSeleccionadaSubject = new Subject<string>();
  private emailVistoSubject = new Subject<string>();
  private emailNoLeidosSubject = new Subject<number>();


  bandejaSeleccionada$ = this.bandejaEmailSeleccionadaSubject.asObservable();
  emailVisto$ = this.emailVistoSubject.asObservable();
  emailNoLeidos$ = this.emailNoLeidosSubject.asObservable();

  seleccionarBandeja(bandeja: string) {
    this.bandejaEmailSeleccionadaSubject.next(bandeja);
  }

  actualizarVistoEmail() {
    this.emailVistoSubject.next('')
  }

  emailsNoLeidos(numeroEmailsNoLeidos: number) {
    this.emailNoLeidosSubject.next(numeroEmailsNoLeidos)
  }

  constructor() { }
}
