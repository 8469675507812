import { Component } from '@angular/core';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import * as HTMLElementTOOLS from '../../tools/html-elements.tool';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grants',
  templateUrl: './grants.component.html',
  styleUrls: ['./grants.component.css']
})
export class GrantsComponent {

  constructor(private router: Router) { }


  calendarVisible = false;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    locale: 'es',
    firstDay: 1,
    headerToolbar: { start: 'prev today next title', end: '', center: '' },
    buttonText: { today: 'Hoy' },
    height: 650,
    themeSystem: 'default',
    eventDurationEditable: false, // Disable Resize
    eventStartEditable: false, // disable dreage drop
    events: [
      {
        "title": "Evento 1",
        "start": "2023-10-05",
        "end": "2023-10-22"
      },
      {
        "title": "Evento 2",
        "start": "2023-10-05",
        "end": "2023-11-22"
      },
    ]
  };


  /**TODO */
  convocatorias = [
    {
      status: 'finished',
      resume: "Convocatoria de ayuda a las entidades locales para potenciación de la Red Española de Ciudades Saludables RECS y la implementación local de la estrategia de promoción y prevención EPSP. 2022",
      ambito: '',
      plazo: '15/07/2012 - 31/12/2023',
      observaciones: '',
      tema: '',
      url: 'https://google.es'

    }, {
      status: 'available',
      resume: "Convocatoria de ayuda a las entidades locales para potenciación de la Red Española de Ciudades Saludables RECS y la implementación local de la estrategia de promoción y prevención EPSP. 2022",
      ambito: '',
      plazo: '15/07/2012 - 31/12/2023',
      observaciones: '',
      tema: '',
      url: 'https://google.es'

    }, {
      status: 'available',
      resume: "Convocatoria de ayuda a las entidades locales para potenciación de la Red Española de Ciudades Saludables RECS y la implementación local de la estrategia de promoción y prevención EPSP. 2022",
      ambito: '',
      plazo: '15/07/2012 - 31/12/2023',
      observaciones: '',
      tema: '',
      url: 'https://google.es'

    },
    {
      status: 'finished',
      resume: "Convocatoria de ayuda a las entidades locales para potenciación de la Red Española de Ciudades Saludables RECS y la implementación local de la estrategia de promoción y prevención EPSP. 2022",
      ambito: '',
      plazo: '15/07/2012 - 31/12/2023',
      observaciones: '',
      tema: '',
      url: 'https://google.es'

    }
  ]

  filteredConvocatorias = [...this.convocatorias];

  currentGrantsStatusView = {
    finished: false,
    available: false
  };



  async selectedGrant(e: EventTarget): Promise<void> {
    let selectedHTMLElement = e as HTMLElement;

    await HTMLElementTOOLS.removeClassForAllClasName('grant-link', 'selected');

    await HTMLElementTOOLS.addClassToElement('selected', selectedHTMLElement.id);

    await HTMLElementTOOLS.removeClassForAllClasName('grant-section', 'current');

    await HTMLElementTOOLS.addClassToElement('current', `${selectedHTMLElement.id}-section`);


    /** Se puede mejorar */
    if (`${selectedHTMLElement.id}-section` === 'calendar-grant-section') this.calendarVisible = true;
    else this.calendarVisible = false;

  }


  async showHiddeGrantInfo(e: EventTarget): Promise<void> {
    console.log(e)
    let selectedHTMLElement = e as HTMLElement;
    await HTMLElementTOOLS.toogleClassToElement('show', `${selectedHTMLElement.id}-bottom`);


  }



  /** NECESITA REFACTOR */
  filterGrants(event: boolean, checkboxValue: string): void {

    if (event) {
      if (checkboxValue === 'Abierta') {
        this.currentGrantsStatusView.available = true;
      }
      else {
        this.currentGrantsStatusView.finished = true;
      }
    } else {
      if (checkboxValue === 'Abierta') {
        this.currentGrantsStatusView.available = false;
      }
      else {
        this.currentGrantsStatusView.finished = false;
      }
    }


    if (!this.currentGrantsStatusView.available && !this.currentGrantsStatusView.finished) this.filteredConvocatorias = [...this.convocatorias];
    if (this.currentGrantsStatusView.available && this.currentGrantsStatusView.finished) this.filteredConvocatorias = [...this.convocatorias];
    if (!this.currentGrantsStatusView.available && this.currentGrantsStatusView.finished) this.filteredConvocatorias = [...this.convocatorias.filter(convocatoria => {
      return convocatoria.status === 'finished'
    })];
    if (this.currentGrantsStatusView.available && !this.currentGrantsStatusView.finished) this.filteredConvocatorias = [...this.convocatorias.filter(convocatoria => {
      return convocatoria.status === 'available'
    })];
  }



}
