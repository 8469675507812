<div class="request">
    <p class="title">Solicitud de colaboración en el marco del programa de apoyo a las corporaciones locales.</p>
    <p>GESTION Y PLANEAMIENTO TERRITORIAL Y MEDIOAMBIENTAL, S.A., informa que, conforme al Reglamento General Europeo de
        Protección de Datos 679/2016, de 27 de abril (RGPD), y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección
        de Datos
        Personales y Garantía de Derechos Digitales (LOPDGDD), respecto al tratamiento y al deber de confidencialidad,
        la
        información facilitada y, en su caso, los ficheros adjuntos, es de carácter exclusiva y estrictamente
        confidencial,
        dirigida expresamente a la contestación de la solicitud recibida.</p>
    <div class="request-type">
        <div class="request-type-select">
            <app-select [text]="'Tipo de solicitud'" [propiedadDelObjetoQueTieneElNombre]="'tematica'"
                [propiedadDelObjetoQueTieneElValor]="'id'" [objetos]="this.tematicas"
                (selectValueEmitter)="getTipoDeSolicitud($event)" #tipoDeSolicitud
                [textoErrorNoCompletado]="'Este campo es obligatorio'"> </app-select>
        </div>
        <div class="request-type-description">
            <p>{{informacionAdicionalTematica}}</p>
        </div>
    </div>

    <div class="request-input-data">
        <app-input [text]="'Solicita'" [type]="'text'" [placeholder]="'Máximo 100 caracteres'"
            (inputTextValueEmitter)="setSolicitaSolicitud($event)" #solicita [blur]="true"
            [textoErrorNoCompletado]="'Este campo es obligatorio'"> </app-input>
        <app-text-area [textAreaLabel]="'Descripción de la solicitud'" [textAreaValor]="''"
            (textAreaValueEmitter)="setDescripcionSolicitud($event)" #descripcionSolicitud [blur]="true"
            [textoErrorNoCompletado]="'Este campo es obligatorio'">
        </app-text-area>
        <app-input [text]="'Referencia catastral objeto del informe'" [type]="'text'" [placeholder]="''"
            (inputTextValueEmitter)="setCatastroSolicitud($event)"></app-input>
        <app-input [type]="'file'" [text]="'Seleccione un archivo'"
            (fileValueEmitter)="getArchivoAdjuntoSolicitud($event)"></app-input>
        <div class="request-input-send-button">
            <button class="primary-button" (click)="confirmarSolicitud()"
                [disabled]="!comprobarSiLosCamposEstanRellenos()"
                [ngClass]="{disabled_button: !comprobarSiLosCamposEstanRellenos()}">Enviar</button>
        </div>

    </div>
</div>