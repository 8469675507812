<div class="aot-input">
    <label for={{text}} *ngIf="text !== ''">{{text}}</label>
    <input [(ngModel)]="valorPorDefecto" type={{type}} id={{text}} placeholder={{placeholder}} *ngIf="type !== 'file'"
        (keyup)="inputTextValue($event)" #inputText>
    <div *ngIf="mostrarMensaje" style="color: rgb(169, 72, 47)">{{textoErrorNoCompletado}}</div>

    <input type={{type}} id='input-file' placeholder={{placeholder}} *ngIf="type === 'file'"
        (change)="onFileSelected($event)" #fileUpload accept=".doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf,.zip,.txt">

    <span *ngIf="type === 'file'">* Tamaño máximo de archivo 25Mb</span>
    <span *ngIf="type === 'file'">* Sólo se permite enviar un archivo, para enviar varios archivos comprímalos en
        formato
        .zip</span>
</div>