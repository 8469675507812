import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Estado } from '../models/estado';
import { ESTADOS } from '../helpers/global';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {

  constructor(private httpClient: HttpClient) { }

  async getEstado(codigoEstado?: number): Promise<Estado[]> {
    try {
      const url = codigoEstado
        ? ESTADOS.URL + codigoEstado
        : ESTADOS.URL;

      const respuesta: Observable<{ data: Estado[] }> = this.httpClient.get<{ data: Estado[] }>(url);
      const resultado = await lastValueFrom(respuesta);
      return resultado.data;
    } catch (error) {
      console.error('Error en la solicitud HTTP', error);
      return [];
    }
  }


  async getEstadosIncluyendoLaOpcionTodos(): Promise<Estado[]> {
    const estados: Estado[] = await this.getEstado();
    estados.forEach(estado => {
      if (estado.id === 4)
        estado.estado = 'Pendiente de descarga'
      if (estado.id === 5)
        estado.estado = 'Descargadas'
    })

    return Promise.resolve([...estados.filter(estado => estado.id > 0 && estado.id < 6 && estado.id !== 3), new Estado(-1, 'Todos', '')])

  }
}
