import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent {


  @Input() forumTitle: string = 'Título del Foro';
  @Input() forumNumber: string = '';
  @Input() forumDate: string = 'Fecha del foro';
  @Input() forumStatus: string = 'Estado del foro';
  @Input() forumIMG: string = '';
  @Input() forumModality: string = '';
  @Input() forumDuration: string = '';
  @Input() forumDescription: string = '';
  @Input() forumProgram: string = '';
  @Input() forumDigitalTools: string = '';
  @Input() forumTarget: string = '';
  @Input() forumSpeaker: string = '';
  @Input() forumObservations: string = '';
  @Input() forumProgress: number = 0;
  @Input() forumID: string = '';

  @Output() moreInfoEmmiter = new EventEmitter<string>();

  textColorIsGreen: boolean = false;

  constructor(private renderer: Renderer2) {
    console.log(this.forumStatus)
  }

  showMoreInfo(): void {
    this.toggleColorAndImage();

    this.textColorIsGreen = !this.textColorIsGreen;

    this.moreInfoEmmiter.emit(this.forumID);
    this.toggleForumMoreInfoVisibility();
  }

  private toggleColorAndImage(): void {

    const pElement = (this.forumStatus === 'Impartida') ? document.querySelector('.finished .forum-bottom-button p strong') : document.querySelector('.forum-bottom-button p strong');
    const imgElement = (this.forumStatus === 'Impartida') ? document.querySelector('.finished .forum-bottom-button img') : document.querySelector('.forum-bottom-button img');

    console.log(pElement)

    if (this.textColorIsGreen) {
      this.renderer.removeStyle(pElement, 'color');
      imgElement?.setAttribute('src', '../../../assets/images/flecha_abajo.svg');
    } else {
      this.renderer.setStyle(pElement, 'color', 'var(--primary)');
      imgElement?.setAttribute('src', '../../../assets/images/flecha_arriba_verde.svg');
    }
  }

  private toggleForumMoreInfoVisibility(): void {
    const forum = document.getElementById(this.forumTitle) as HTMLElement;
    const forumMoreInfo = forum.querySelector('.forum-moreinfo') as HTMLElement;

    forumMoreInfo.classList.toggle('show');
  }

}
